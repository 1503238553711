unit f_produzione;

{$mode objfpc}

interface

uses
  Classes, SysUtils, strutils, js, web, un_widget, un_tquery, w_table,
  f_produzionedettaglio, DateUtils, f_selezione, w_edit, un_servermodule, w_label,
  un_telemetria, UnArrayDef, DB, un_generator, f_produzionevalori, f_produzionecategorie,
  f_ProduzioneProdotti, f_ProduzioneStati;

type

  TVisibilita = (visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso);
const
  limiteRecord = 300;
  msgLimiteRecord = 'Sono riportati solo gli ultimi 300 prodotti' ;
  msgDateErrate = 'Correggi le date corrette per visualizzare gli prodotti';
  msgNessunRecord = 'Nessun record da visualizzare';
  msgCaricamentoInCorso = 'Caricamento prodotti in corso...';

type
  { TFrmProduzione }

  TFrmProduzione = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
  private
    whereDate :String;
    whereDate2 :String;
    dt1str, dt2str :String;
    function DateOk:Boolean;
    procedure RefreshProduzione; async;
    procedure qryproduzioneOnFilterRecord(DataSet: TDataSet; var Accept: Boolean);
    procedure RefreshFiltro;
    procedure SetLabel(lbl: TWLabel; value: String);
    procedure ImpostaVisibilita(visibilita:TVisibilita);
    procedure SetLabelStampa;
  published
    procedure tab1_rowclick;
    procedure filtro_change;
    procedure filtro_input;
    procedure data1_change;
    procedure data2_change;
    procedure idstato_change;
    procedure stampa1_click(event: TJSEvent); async;
    procedure btnValori_click; async;
    procedure btnCategorie_click; async;
    procedure btnStati_click; async;
    procedure flconclusi_click; async;
    procedure BtnInserisciProdotto_click; async;
    procedure idstato_click; async;
    stampa1 :TJSHTMLButtonElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    idstato: TJSHTMLSelectElement;
    filtro: TWEdit;
    pnltab1: TJSHTMLElement;
    qryproduzione: TQuery;
    qryproduzionetotali: TQuery;
    tab1:TWTable;
    labelTop:TWLabel;
    labelBottom:TWLabel;
    pnlTabella:TJSHTMLElement;
    pnlbottoni:TJSHTMLElement;
    BtnValori : TJSHTMLInputElement;
    flconclusi:TJSHTMLInputElement;
    pnlstampa:TJSHTMLInputElement;

  end;

implementation

{$R *.html}

{ TFrmProduzione }

procedure TFrmProduzione.AfterRender;
var
  dataminfiltro : String;
  datamaxfiltro : String;
begin

// imposto la data iniziale del filtro
  dataminfiltro := DateToStr(date - 1000);
  datamaxfiltro := DateToStr(date + 1000);

  data1.value := Copy(dataminfiltro,7,4) +'-'+
         Copy(dataminfiltro,4,2) +'-'+
         Copy(dataminfiltro,1,2);
  data2.value := Copy(datamaxfiltro,7,4) +'-'+
         Copy(datamaxfiltro,4,2) +'-'+
         Copy(datamaxfiltro,1,2);

  qryproduzione.OnFilterRecord := @qryproduzioneOnFilterRecord;

  filtro.placeholder := 'Ricerca prodotti...';
  filtro.seleziona1.Visible:= false;

  labelTop.header.innerHTML:= '<br><br>';
  labelTop.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 18px; font-weight: bold; border-style: solid;';
  labelTop.footer.innerHTML:= '<br><br>';

  labelBottom.labelbody.innerHTML:= 'bottom';
  labelBottom.header.innerHTML:= '<br>';
  labelBottom.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 22px; font-weight: bold; border-style: solid;';

//  data1.value := window.localStorage[ClassName+'_data1'] ;
//  data2.value := window.localStorage[ClassName+'_data2'] ;

  pnlstampa.Visible:=false;

  flconclusi.checked:=true;

  RefreshProduzione;

end;

procedure TFrmProduzione.AfterDomAppend;
begin

  RefreshProduzione;

end;

procedure TFrmProduzione.RefreshProduzione;
begin

  if not DateOk then
  begin
    ImpostaVisibilita(visDateErrate);
    exit;
  end;

  ImpostaVisibilita(visCaricamentoInCorso);
  qryproduzione.Close;
  qryproduzione.SQL.Text:= ' select p.dtnextscadenza as scadnext, p.dsprodotto as prodotto,   '
                        + ' p.dsmodello as modello, p.dsstato as stato, p.dtscadenza as dtlancio, '
                        + ' p.idproduzione, p.idresponsabile, p.dsresponsabile, p.dsnote, '
                        + ' d.dsnextstep as nextstep, d.dsstep, d.dsnote as note '
                        + ' from pro_produzione p '
                        + ' left outer join pro_produzionedettaglio d on d.idproduzione = p.idproduzione '
                        + ' where ' + whereDate;
  qryproduzione.SQL.Text := qryproduzione.SQL.Text + ' and p.dsstato = d.dsstato and p.dtnextscadenza = d.dtscadenza ';

  if idstato.value <> '' then
  begin
    qryproduzione.SQL.Text := qryproduzione.SQL.Text + ' and p.dsstato = ' + '''' + idstato.value + ''''
  end;
  if flconclusi.checked then
    begin
      qryproduzione.SQL.Text := qryproduzione.SQL.Text + ' and p.dsstato <> ''CONCLUSO'' '
    end;
  qryproduzione.SQL.Text := qryproduzione.SQL.Text + ' order by p.dtnextscadenza ';
  AWait(qryproduzione.Open);
  qryproduzione.FieldByName('idproduzione').Visible:= false;
  qryproduzione.FieldByName('idresponsabile').Visible:= false;
  qryproduzione.FieldByName('dsresponsabile').Visible:= false;
  qryproduzione.FieldByName('dsnote').Visible:= false;
  qryproduzione.FieldByName('dsstep').Visible:= false;

  RefreshFiltro;

end;

procedure TFrmProduzione.BtnInserisciProdotto_click;
var
  instance: TFrmProduzioneProdotti;
begin

  instance := TFrmProduzioneProdotti.Create();
  instance.show;

end;

procedure TFrmProduzione.idstato_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dsstato as stato '
      + ' from pro_stati '
      + ' order by idordine ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      idstato.value := QrySeleziona.FieldByName('stato').AsString;
      RefreshProduzione;
    end;

  end;

end;

procedure TFrmProduzione.qryproduzioneOnFilterRecord(DataSet: TDataSet;
  var Accept: Boolean);
var
  str:String;
  i: Integer;
begin
  Accept:= true;
  str := filtro.value.ToLowerInvariant;
  for i:= 0 to DataSet.FieldCount-1 do
  begin
    if DataSet.Fields[i].Visible and
       DataSet.Fields[i].DisplayText.ToLowerInvariant.Contains(str) then
         exit;
  end;
  //nessun campo matcha la stringa cercata
  Accept := false;
end;



procedure TFrmProduzione.SetLabel(lbl:TWLabel;value:String);
begin
  lbl.Visible :=   value <> '' ;
  lbl.labelbody.innerHTML := value;
end;

procedure TFrmProduzione.SetLabelStampa;
var
  RecordCount: Integer;
begin
  if not qryproduzione.Active then
    exit;

  RecordCount := 0;
  qryproduzione.First;
  while not qryproduzione.EOF do
  begin
    Inc(RecordCount);
    qryproduzione.Next;
  end;
  stampa1.innerHTML:= format('Stampa (%d %s)'
      ,[RecordCount, IfThen(RecordCount = 1,'ordine','record') ]);
end;

procedure TFrmProduzione.ImpostaVisibilita(visibilita: TVisibilita);
begin
  //visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso
  SetLabelStampa;
  SetLabel(labelTop,'');
  SetLabel(labelBottom,'');

  if visibilita = visDateErrate then SetLabel(labelTop,msgDateErrate);
  if visibilita = visNessunRecord then SetLabel(labelBottom,msgNessunRecord);
  if visibilita = visLimiteRecord then SetLabel(labelBottom,msgLimiteRecord);
  if visibilita = visCaricamentoInCorso then SetLabel(labelBottom,msgCaricamentoInCorso);

  filtro.Visible :=  visibilita <> visDateErrate;
//  console.log(format(' filtro.Visible := %s',[BoolToStr(filtro.Visible,true)]));
  pnltab1.Visible:= visibilita in [visLimiteRecord,visCiSonoRecord];
end;

procedure TFrmProduzione.RefreshFiltro;
begin
  qryproduzione.Filtered:= filtro.value.Trim <> '';
  qryproduzione.First;
  tab1.RenderDataSet(qryproduzione);

  if qryproduzione.IsEmpty then
    ImpostaVisibilita(visNessunRecord)
  else if qryproduzione.RecordCount = limiteRecord  then
    ImpostaVisibilita(visLimiteRecord)
  else
    ImpostaVisibilita(visCiSonoRecord);
end;

procedure TFrmProduzione.filtro_change;
begin
  RefreshFiltro;
end;

procedure TFrmProduzione.filtro_input;
begin
  RefreshFiltro;
end;

procedure TFrmProduzione.tab1_rowclick;
var
  instance: TFrmProduzioneDettaglio;
begin

  instance := TFrmProduzioneDettaglio.Create();
  instance.idproduzione := qryproduzione.FieldByName('idproduzione').AsInteger;
  instance.dsprodotto := qryproduzione.FieldByName('prodotto').AsString
    + ' - ' + qryproduzione.FieldByName('modello').AsString;
  instance.show;

end;

procedure TFrmProduzione.data1_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;

  RefreshProduzione;

end;

procedure TFrmProduzione.data2_change;
begin

  window.localStorage[ClassName+'_data2'] := data2.value;

  RefreshProduzione;

end;

procedure TFrmProduzione.idstato_change;
begin
  console.log('TFrmProduzione.idedit_change');
  RefreshProduzione;
end;

procedure TFrmProduzione.stampa1_click(event: TJSEvent);
var
  sql, clausola: String;
begin

  with ServerModule('TWebStampe') do
  begin
    Params['idStampa'] := '108';
    AWait(Call('WebParametri'));
    sql := Response.Strings['SQL'];
  end;

  clausola := format( 'AND P.IDPRODUZIONE IN (%s)',[ ToCommaText(qryproduzione,'IDPRODUZIONE',true) ]);
  sql := SysUtils.StringReplace(sql,'--AND1',clausola,[]);

  with ServerModule('TWebStampe') do
  begin
    Proc := 'WebProcessa';
    Params['idStampa'] := '81';
    Params['sql'] := sql;
    Params['DATADA'] := dt1str;
    Params['DATAA'] :=  dt2str;
    window.open(Url,'_blank');
  end;
  TmRegistraEvento('stampa',format('%s ~ %s|stato=%s|filtro=%s'
  ,[data1.value,data2.value,idstato.value,filtro.Value]));
end;

procedure TFrmProduzione.btnValori_click;
var
  instance: TFrmProduzioneValori;
begin

  instance := TFrmProduzioneValori.Create();
  instance.show;

end;

procedure TFrmProduzione.btnCategorie_click;
var
  instance: TFrmProduzioneCategorie;
begin

  instance := TFrmProduzioneCategorie.Create();
  instance.show;

end;

procedure TFrmProduzione.btnStati_click;
var
  instance: TFrmProduzioneStati;
begin

  instance := TFrmProduzioneStati.Create();
  instance.show;

end;

procedure TFrmProduzione.flconclusi_click;
begin
  Console.Log('parte');
  RefreshProduzione;

end;

function TFrmProduzione.DateOk: Boolean;
var
  dt1, dt2: TDateTime;
begin

  Result :=  false;

  if (data1.value = '') or (data2.value = '')
    or (data1.value > data2.value) then
  begin
    SetLabel(labelTop,msgDateErrate);
    SetLabel(labelBottom,'');
    exit;
  end;

  Result :=  true;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value) ;
  dt2 := IncDay(dt2);

// questo formato date serve per la query
  dt1str := FormatDateTime('dd.MM.yyyy',dt1);
  dt2str := FormatDateTime('dd.MM.yyyy',dt2);

  whereDate := ' p.dtnextscadenza >= ''' + dt1str + ''''
    + ' and p.dtnextscadenza <= ''' + dt2str + '''';

  whereDate2 := ' dtscadenza >= ''' + dt1str + ''''
  + ' and dtscadenza <= ''' + dt2str + '''';

// questo formato di date serve alla stampa
  dt1str := FormatDateTime('dd/MM/yyyy',dt1) + ' 00:00:00';
  dt2str := FormatDateTime('dd/MM/yyyy',dt2) + ' 00:00:00';

end;

initialization
  RegisterWeb(TFrmProduzione);

end.

