unit f_cassa;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, un_formatsettings,
  un_user, f_selezione, un_servermodule, un_generator, DateUtils, DB;

type

  { TFrmCassa }

  TFrmCassa = class(TWidget)
    dt1, dt2: TDateTime;
    procedure AfterRender;
    procedure salva_click(Event: TJSEvent); async;
    function SaldoCassa:Double; async;

  published
    procedure btnTFrmSeleziona_click; async;
    procedure btnCercaMovimentiCassa_click; async;
    procedure btnAnnullaModificaMovimento_click; async;
    procedure btnModificaMovimento_click; async;
    procedure btnAnnullaMovimento_click; async;
    procedure dscausale_click; async;
    procedure idmovimento_click; async;
    procedure btnChiudiCerca_click; async;
    procedure btnVediMovimenti_click; async;
    procedure btnChiudiAnnulla_click; async;
    titolo: TJSHTMLElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    dtdata : TJSHTMLInputElement;
    dscollaboratore : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    idcausale : TJSHTMLInputElement;
    dscausale : TJSHTMLInputElement;
    importo : TJSHTMLInputElement;
    idmovimento : TJSHTMLInputElement;
    idsaldo : TJSHTMLInputElement;
    btnannullamodificamovimento : TJSHTMLInputElement;
    btnannullamovimento : TJSHTMLInputElement;
    btnmodificamovimento : TJSHTMLInputElement;
    btnCercaMovimentiCassa : TJSHTMLInputElement;
    btnVediMovimentiCassa : TJSHTMLInputElement;
    salva:TJSHTMLButtonElement;
    qry1: TQuery;
    taSql: TJSHTMLTextAreaElement;
    QryCollaboratori: TQuery;
    QryCassa: TQuery;
    QrySaldoCassa: TQuery;
    qrycausali: TQuery;
    QryUtenti: TQuery;
    QryDeleteMovimento: TQuery;
    pnlDati:TJSHTMLElement;
    pnlCerca:TJSHTMLElement;
    pnlAnnulla:TJSHTMLElement;
    pnlSalva:TJSHTMLElement;
    pnlSpazio:TJSHTMLElement;
  end;

implementation

{$R *.html}

{ TFrmCassa }

procedure TFrmCassa.AfterRender;
begin

  pnlDati.Visible := true;
  pnlCerca.Visible := false;
  pnlAnnulla.Visible := false;

  SaldoCassa;

//  idsaldo.value:=FormatFloat('#,##0.00', SaldoCassa);
//  idsaldo.value:=FormatFloat('#,##0.00', AWait(SaldoCassa));

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

end;

procedure TFrmCassa.salva_click(Event: TJSEvent);
begin

  Console.log('salva_click x2');

// inserisco il movimento di cassa
  QryCassa.Close;
  QryCassa.SQL.Text:='select first 0 * from ca_movimenticassa ';
  AWait(QryCassa.Open);

  // controllo se i campi sono compilati
      if dtdata.value = '' then ShowMessage('Devi inserire la data');
      if dscausale.value = '' then ShowMessage('Devi inserire la causale');
//      if dsnote.value = '' then ShowMessage('Devi inserire le note');
      if importo.value = '' then ShowMessage('Devi inserire l''importo');

      QryCassa.Append;
      QryCassa.FieldByName('IDCASSA').AsInteger := 3;
      QryCassa.FieldByName('IDMOVIMENTOCASSA').AsInteger := await(GetNextId('MOVIMENTICASSA'));
      QryCassa.FieldByName('IDSOCIETA').AsString := 'MEL001';
      QryCassa.FieldByName('DTREGISTRAZIONE').AsDateTime := date;
      QryCassa.FieldByName('DTCONTABILE').AsDateTime :=
      StrToDate(Copy(dtdata.value,9,2) +'/'+
               Copy(dtdata.value,6,2) +'/'+
               Copy(dtdata.value,1,4));

      // cerco la matricola del collaboratore
      QryCollaboratori.Close;
      QryCollaboratori.SQL.Text := 'select * from collaboratori '
      + ' where idsocieta = ''MEL001'' '
      + ' and idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString;
      AWait(QryCollaboratori.Open);

      QryCassa.FieldByName('DSCAUSALE').AsString := dscausale.value;
    // cerco l'id della causale
      QryCausali.Close;
      QryCausali.SQL.Text:= 'select idcausalecassa, dscausalecassa, dssegno '
      + ' from ca_causalicassa '
      + ' where dscausalecassa = ' + '''' + dscausale.value + '''';
      AWait(QryCausali.Open);

      if qrycausali.RecordCount = 1 then
        begin
          QryCassa.FieldByName('IDCAUSALE').AsInteger := QryCausali.FieldByName('idcausalecassa').AsInteger;
        end;

      QryCassa.FieldByName('DSNOTE').AsString := dsnote.value;
      QryCassa.FieldByName('NMIMPORTO').AsFloat :=
        StrToFloat(stringReplace(importo.value , '.',  ',' ,[rfReplaceAll, rfIgnoreCase]))
        * QryCausali.FieldByName('dssegno').AsInteger;

    // cerco l'utente
      QryUtenti.Close;
        QryUtenti.SQL.Text:= 'select * from utenti '
        + ' where idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString;
        AWait(QryUtenti.Open);

        if qrycausali.RecordCount = 1 then
          begin
            QryCassa.FieldByName('DSUTENTE').AsString := QryUtenti.FieldByName('idutente').AsString;
          end;

  QryCassa.Post;
  QryCassa.ApplyUpdates;

  SaldoCassa;

  ShowMessage('Inserimento eseguito con successo');

end;

procedure TFrmCassa.btnTFrmSeleziona_click;
var
  datamin, datamax :String;
begin
  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  // controllose i campi sono compilati
  if data1.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data1.focus;
    exit;
  end;
  if data2.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data2.focus;
    exit;
  end;

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := False;

      datamin := Copy(data1.value,9,2) +'.'+
        Copy(data1.value,6,2) +'.'+
        Copy(data1.value,1,4);
      datamax := Copy(data2.value,9,2) +'.'+
        Copy(data2.value,6,2) +'.'+
        Copy(data2.value,1,4);

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dtcontabile as data, dscausale as causale, dsnote as note, '
      + ' nmimporto as importo'
      + ' from ca_movimenticassa'
      + ' where idsocieta = ''MEL001'' '
      + ' and  (dsannullato is null or dsannullato = '''') '
      + ' and idcassa = 3 '
      + ' and dtcontabile >= ' + '''' + datamin + ''''
      + ' and dtcontabile <= ' + '''' + datamax + ''''
      + ' order by dtcontabile desc ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
//      self.taSql.value := 'OK! selezionato IDARTICOLO=' + QrySeleziona.FieldByName('IDARTICOLO').AsString
//      + ' DSARTICOLO=' + QrySeleziona.FieldByName('DSARTICOLO').AsString;
    end;
  end;
end;

procedure TFrmCassa.btnModificaMovimento_click;
begin

  // modifico il movimento di cassa
  QryCassa.Close;
  QryCassa.SQL.Text:='select * from ca_movimenticassa ';
  AWait(QryCassa.Open);

  if (idmovimento.value = '') or (not QryCassa.Locate('idmovimentocassa', idmovimento.value, [])) then
    begin
      ShowMessage('Devi inserire un l''id corretto');
      Exit;
    end;

  QryCassa.Edit;
  if dtdata.value <> '' then QryCassa.FieldByName('dtcontabile').AsDateTime :=
  StrToDate(Copy(dtdata.value,9,2) +'/'+
           Copy(dtdata.value,6,2) +'/'+
           Copy(dtdata.value,1,4));
  if dscausale.value <> '' then QryCassa.FieldByName('dscausale').AsString := dscausale.value;
  if dsnote.value <> '' then QryCassa.FieldByName('dsnote').AsString := dsnote.value;
  if importo.value <> '' then QryCassa.FieldByName('nmimporto').AsString := importo.value;
  // cerco l'id della causale
  QryCausali.Close;
  QryCausali.SQL.Text:= 'select idcausalecassa, dscausalecassa, dssegno '
  + ' from ca_causalicassa '
  + ' where dscausalecassa = ' + '''' + dscausale.value + '''';
  AWait(QryCausali.Open);

  if qrycausali.RecordCount = 1 then
    begin
      QryCassa.FieldByName('IDCAUSALE').AsInteger := QryCausali.FieldByName('idcausalecassa').AsInteger;
    end;

  QryCassa.Post;
  QryCassa.ApplyUpdates;

  SaldoCassa;

  idmovimento.value := '';

  ShowMessage('Modifica eseguita con successo');

end;

procedure TFrmCassa.btnAnnullaMovimento_click;
begin

  // annullo il movimento di cassa
  QryCassa.Close;
  QryCassa.SQL.Text:='select * from ca_movimenticassa ';
  AWait(QryCassa.Open);

  if (idmovimento.value = '') or (not QryCassa.Locate('idmovimentocassa', idmovimento.value, [])) then
    begin
      ShowMessage('Devi inserire un l''id corretto');
      Exit;
    end;

  QryCassa.Edit;
  QryCassa.FieldByName('dsannullato').AsString := 'A';

  QryCassa.Post;
  QryCassa.ApplyUpdates;

  idmovimento.value := '';

  SaldoCassa;

  ShowMessage('Annullamento eseguito con successo');

end;

procedure TFrmCassa.btnCercaMovimentiCassa_click;
begin

  pnlCerca.Visible := True;
  pnlDati.Visible := False;
  pnlAnnulla.Visible := False;
  btnannullamodificamovimento.Visible := False;

end;

procedure TFrmCassa.btnAnnullaModificaMovimento_click;
begin

  PnlAnnulla.Visible:=True;
  pnlCerca.Visible := False;
  btncercamovimenticassa.Visible := False;
  PnlSalva.Visible:=False;
  btnannullamodificamovimento.Visible:=False;
  pnlSpazio.Visible:=False;

end;

procedure TFrmCassa.btnChiudiCerca_click;
begin

  pnlDati.Visible := True;
  pnlCerca.Visible := False;
  btnannullamodificamovimento.Visible := True;
  btnCercaMovimentiCassa.innerHTML:='movimenti di cassa';

end;

procedure TFrmCassa.btnVediMovimenti_click;
begin

    btnTFrmSeleziona_click;

end;

procedure TFrmCassa.btnChiudiAnnulla_click;
begin

  PnlAnnulla.Visible:=False;
  pnlDati.Visible:=True;
  btnCercaMovimentiCassa.Visible := True;
  btnannullamodificamovimento.Visible:=True;
  PnlSalva.Visible:=True;
  pnlSpazio.Visible:=True;

end;

function TFrmCassa.SaldoCassa: Double; async;
begin

  QrySaldoCassa.Close;
  QrySaldoCassa.SQL.Text:=' select sum(nmimporto) as saldo from ca_movimenticassa '
  + ' where idsocieta = ''MEL001'' '
  + ' and idcassa = 3 '
  + ' and (dsannullato is null or dsannullato = '''') ';
  AWait(QrySaldoCassa.Open);

  Result := QrySaldoCassa.FieldByName('saldo').AsFloat;
  console.log('leggo il saldo dal campo: ' +  QrySaldoCassa.FieldByName('saldo').AsString );
  console.log('leggo il saldo da Result: ' +  FloatToStr(Result ));
  idsaldo.value := FormatFloat('#,##0.00',QrySaldoCassa.FieldByName('saldo').AsFloat);


end;

procedure TFrmCassa.dscausale_click;
begin
    with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcausalecassa, dscausalecassa '
      + ' from ca_causalicassa '
      + ' where idsocieta = ''MEL001'' ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      dscausale.value := QrySeleziona.FieldByName('dscausalecassa').AsString;
    end;
  end;

end;

procedure TFrmCassa.idmovimento_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idmovimentocassa as id, dtcontabile as data, '
      + ' idcausale, dscausale as causale, dsnote as note, nmimporto as importo '
      + ' from ca_movimenticassa '
      + ' where (dsannullato is null or dsannullato = '''') '
      + ' order by data desc ';
      AWait(QrySeleziona.Open);
      //QrySeleziona.FieldByName('IDMEMORANDUM').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      idmovimento.value := QrySeleziona.FieldByName('id').AsString;
      dtdata.value := Copy(QrySeleziona.FieldByName('data').AsString,7,4) +'-'+
        Copy(QrySeleziona.FieldByName('data').AsString,4,2) +'-'+
        Copy(QrySeleziona.FieldByName('data').AsString,1,2);
      dscausale.value := QrySeleziona.FieldByName('causale').AsString;
      dsnote.value := QrySeleziona.FieldByName('note').AsString;
      importo.value := FloatToStr(QrySeleziona.FieldByName('importo').AsFloat);
    end;
  end;

end;

initialization
  RegisterWeb(TFrmCassa);

end.
