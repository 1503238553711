unit un_generic;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_error;

type

  { TList }

  generic TList<T> = class
  protected
    procedure AtLeastOne;
    function LastIndex: integer; inline;
  public
    Items: array of T;
    function Size: integer;
    function IsEmpty: boolean;
    procedure Add(Value: T);
    function Remove(Value: T): boolean;
    function RemoveAt(index: integer): boolean;
    function IndexOf(Value: T): integer;
    function Last: T;
  end;

  { TSMap }

  generic TSMap<V> = class //la chiave e' una string
    items: TStringList;
    constructor Create;
    function get(n: string): V;
    procedure put(n: string; val: V);
    type
    TSMapListSpec = specialize TList<V>;
    function Values: TSMapListSpec;
    //    property Strings[Index: Integer]: string read Get write Put; default;
    //    Items: array of T;
    //  procedure Add(Value: T);
  end;

implementation

{ TGenList }

procedure TList.AtLeastOne;
begin
  if IsEmpty then
    raise Exception.Create('The list is empty');
end;

function TList.IsEmpty: boolean;
begin
  Result := Size = 0;
end;

procedure TList.Add(Value: T);
begin
  SetLength(Items, Length(Items) + 1);
  Items[Length(Items) - 1] := Value;
end;

function TList.Remove(Value: T): boolean;
begin
  Result := RemoveAt(IndexOf(Value));
end;

function TList.RemoveAt(index: integer): boolean;
var
  c: integer;
begin
  Result := False;
  if (index < 0) or (index >= Size) then
    exit;
  for c := index to LastIndex - 1 do
    Items[c] := Items[c + 1];
  SetLength(Items,Size-1);
end;

function TList.IndexOf(Value: T): integer;
var
  i: integer;
begin
  for i := 0 to LastIndex do
  begin
    if Items[i] = Value then
    begin
      Result := i;
      exit;
    end;
  end;
  Result := -1;
end;

function TList.Size: integer;
begin
  Result := Length(Items);
end;

function TList.LastIndex: integer;
begin
  Result := Length(Items) - 1;
end;

function TList.Last: T;
var
  i: Integer;
  r: T;
begin
  AtLeastOne;
  i := LastIndex;
  r := Items[i];
  Result := r;
end;


{ TSMap }

constructor TSMap.Create;
begin
  items := TStringList.Create;
end;

function TSMap.get(n: string): V;
var
  i: integer;
begin
  Result := nil;
  i := items.IndexOf(n);
  if i >= 0 then
    Result := items.Objects[i] as V
  else
    RaiseException(format('Classe non trovata [%s]'#13'Devi forse fare il RegisterWeb()?', [n]));
end;

procedure TSMap.put(n: string; val: V);
begin
  items.AddObject(n, val);
end;

function TSMap.Values: TSMapListSpec;
var
  i: integer;
  val: V;
begin
  Result := TSMapListSpec.Create;
  for i := 0 to items.Count - 1 do
  begin
    val := get(items.Strings[i]);
    Result.Add(val);
  end;
end;


end.

