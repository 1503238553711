unit f_produzioneprocessi;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, f_ProduzioneInsertProcesso,
  f_ProduzioneModificaProcesso;

type

  { TFrmProduzioneProcessi }

  TFrmProduzioneProcessi = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idstato:Integer;
    dsstato:String;

  private
    procedure RefreshProcessi; async;

  published
    procedure tab1_rowclick;
    procedure BtnInserisciProcesso_click; async;
    procedure BtnChiudi_click; async;
    QryProcesso: TQuery;
    tab1:TWTable;
    dsstatoprocesso : TJSHTMLInputElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneProcessi }

procedure TFrmProduzioneProcessi.AfterRender;
begin

//

end;

procedure TFrmProduzioneProcessi.AfterDomAppend;
begin

  dsstatoprocesso.value:=dsstato;

  RefreshProcessi;

end;

procedure TFrmProduzioneProcessi.RefreshProcessi;
begin

  QryProcesso.Close;
  QryProcesso.SQL.Text:= 'select idprocesso, idsocieta, idstato, idordine as id, '
  + ' dsprocesso as processo, dsnote as note '
  + ' from pro_processi '
  + ' where idstato = ' + IntToStr(idstato);
  QryProcesso.SQL.Text := QryProcesso.SQL.Text + ' order by idordine';
  AWait(QryProcesso.Open);
  QryProcesso.FieldByName('idprocesso').Visible:= false;
  QryProcesso.FieldByName('idsocieta').Visible:= false;
  QryProcesso.FieldByName('idstato').Visible:= false;
  tab1.RenderDataSet(QryProcesso);

end;

procedure TFrmProduzioneProcessi.tab1_rowclick;
var
  instance: TFrmProduzioneModificaProcesso;
begin

  instance := TFrmProduzioneModificaProcesso.Create();
  instance.idprocesso := QryProcesso.FieldByName('idprocesso').AsInteger;
  instance.show;

end;

procedure TFrmProduzioneProcessi.BtnInserisciProcesso_click;
var
  instance: TFrmProduzioneInsertProcesso;
begin

  instance := TFrmProduzioneInsertProcesso.Create();
  instance.idstato := idstato;
  instance.show;

end;

procedure TFrmProduzioneProcessi.BtnChiudi_click;
begin

  Close;

end;

initialization
  RegisterWeb(TFrmProduzioneProcessi);

end.

