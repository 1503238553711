unit f_produzionedettaglio;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, f_produzioneinsert, f_produzioneprodotti,
  f_produzionemodificastep, f_produzioneimportiprodotti;

type

  { TFrmProduzioneDettaglio }

  TFrmProduzioneDettaglio = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idproduzione:Integer;
    dsprodotto:String;

  private
    procedure RefreshProduzioneDettaglio; async;

  published
    procedure tab1_rowclick;
    procedure BtnModificaProdotto_click; async;
    procedure BtnInserisciDettaglio_click; async;
    procedure BtnImporti_click; async;
    procedure BtnChiudi_click; async;
    QryProduzioneDettaglio: TQuery;
    QryProduzione: TQuery;
    tab1:TWTable;
    dsprodottomodello : TJSHTMLInputElement;
    nmquantita: TJSHTMLSelectElement;
    nmprezzoacquisto: TJSHTMLSelectElement;
    nmprezzovendita: TJSHTMLSelectElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneDettaglio }

procedure TFrmProduzioneDettaglio.AfterRender;
begin

//

end;

procedure TFrmProduzioneDettaglio.AfterDomAppend;
begin

  console.log('prodotto = ' + dsprodotto);

  dsprodottomodello.value:=dsprodotto;

  RefreshProduzioneDettaglio;

end;

procedure TFrmProduzioneDettaglio.RefreshProduzioneDettaglio;
begin

  QryProduzioneDettaglio.Close;
  QryProduzioneDettaglio.SQL.Text:= 'select idproduzionedettaglio, idproduzione, dtscadenza as scadenza, dsstato as stato, '
  + ' dsnextstep as nextstep, idresponsabile, dsresponsabile as respons, dsnote as note '
  + ' from pro_produzionedettaglio '
  + ' where idproduzione = ' + IntToStr(idproduzione);
  QryProduzioneDettaglio.SQL.Text := QryProduzioneDettaglio.SQL.Text + ' order by dtscadenza desc';
  AWait(QryProduzioneDettaglio.Open);
  QryProduzioneDettaglio.FieldByName('idresponsabile').Visible:= false;
  QryProduzioneDettaglio.FieldByName('idproduzionedettaglio').Visible:= false;
  QryProduzioneDettaglio.FieldByName('idproduzione').Visible:= false;
  tab1.RenderDataSet(QryProduzioneDettaglio);

  QryProduzione.Close;
  QryProduzione.SQL.Text:= 'select * '
  + ' from pro_produzione '
  + ' where idproduzione = ' + IntToStr(idproduzione);
  AWait(QryProduzione.Open);

end;

procedure TFrmProduzioneDettaglio.tab1_rowclick;
var
  instance: TFrmProduzioneModificaStep;
begin

  instance := TFrmProduzioneModificaStep.Create();
  instance.idproduzione := QryProduzioneDettaglio.FieldByName('idproduzione').AsInteger;
  instance.show;

end;

procedure TFrmProduzioneDettaglio.BtnModificaProdotto_click;
var
  instance: TFrmProduzioneProdotti;
begin

  instance := TFrmProduzioneProdotti.Create();
  instance.idproduzioneprodotto := QryProduzioneDettaglio.FieldByName('idproduzione').AsInteger;
  instance.show;

end;

procedure TFrmProduzioneDettaglio.BtnInserisciDettaglio_click;
var
  instance: TFrmProduzioneInsert;
begin

  instance := TFrmProduzioneInsert.Create();
  instance.idproduzionedettaglio := QryProduzioneDettaglio.FieldByName('idproduzionedettaglio').AsInteger;
  instance.idproduzione := QryProduzioneDettaglio.FieldByName('idproduzione').AsInteger;
  instance.show;

end;

procedure TFrmProduzioneDettaglio.BtnImporti_click;
var
  instance: TFrmProduzioneImportiProdotti;
begin

  console.log('apro la form produzioneimporti');

  instance := TFrmProduzioneImportiProdotti.Create();
  instance.idproduzione := QryProduzioneDettaglio.FieldByName('idproduzione').AsInteger;
  instance.dsprodotto := dsprodottomodello.value;
  instance.show;

end;

procedure TFrmProduzioneDettaglio.BtnChiudi_click;
begin

  Close;

end;

initialization
  RegisterWeb(TFrmProduzioneDettaglio);

end.

