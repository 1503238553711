unit f_produzionecategorie;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione,
  f_produzionemodificacategoria;

type

  { TFrmProduzioneCategorie }

  TFrmProduzioneCategorie = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private

  published
    procedure RefreshCategorie;  async;
    procedure tab1_rowclick;
    procedure btninsert_click(Event: TJSEvent); async;
    procedure btnchiudiinsert_click(Event: TJSEvent); async;
    procedure btnverifica_click(Event: TJSEvent); async;
    procedure btnchiudiverifica_click(Event: TJSEvent); async;
    QryCategorie: TQuery;
    QryVerifica: TQuery;
    tab1:TWTable;
    tab2:TWTable;
//    idcategoria : TJSHTMLInputElement;
    dscategoria : TJSHTMLInputElement;
    pnltab1:TJSHTMLElement;
    pnlinsert:TJSHTMLElement;
    pnlVerifica:TJSHTMLElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneCategorie }

procedure TFrmProduzioneCategorie.AfterRender;
begin

// RefreshCategorie;

end;

procedure TFrmProduzioneCategorie.AfterDomAppend;
begin

  RefreshCategorie;

end;

procedure TFrmProduzioneCategorie.RefreshCategorie;
begin

  pnlinsert.Visible:=false;
  pnlVerifica.Visible:=false;

  QryCategorie.Close;
  QryCategorie.SQL.Text := ' select idcategoria, idsocieta, dscategoria, flproduzione '
   + ' from pre_categorie '
   + ' where flproduzione <> ''NO'' '
   + ' order by dscategoria ';
  AWait(QryCategorie.Open);

  QryCategorie.FieldByName('idcategoria').Visible:= false;
  QryCategorie.FieldByName('idsocieta').Visible:= false;
  QryCategorie.FieldByName('flproduzione').Visible:= false;

  tab1.RenderDataSet(QryCategorie);

end;

procedure TFrmProduzioneCategorie.tab1_rowclick;
var
  instance: TFrmProduzioneModificaCategoria;
begin

  instance := TFrmProduzioneModificaCategoria.Create();
  instance.idcategoria := QryCategorie.FieldByName('idcategoria').AsInteger;
  instance.show;

end;

procedure TFrmProduzioneCategorie.btninsert_click(Event: TJSEvent);
begin

  pnltab1.Visible:=false;
  pnlinsert.Visible:=true;
  pnlVerifica.Visible:=false;

end;

procedure TFrmProduzioneCategorie.btnchiudiinsert_click(Event: TJSEvent);
begin

  pnltab1.Visible:=true;
  pnlverifica.Visible:=false;
  pnlinsert.Visible:=false;

end;

procedure TFrmProduzioneCategorie.btnverifica_click(Event: TJSEvent);
begin

  pnlVerifica.Visible:=true;
  pnltab1.Visible:=false;
  pnlinsert.Visible:=false;

  QryVerifica.Close;
  QryVerifica.SQL.Text := '  select distinct c.dscategoria as incatalogo, p.dsprodotto as inproduzione '
   + ' from pre_categorie c '
   + ' left outer join pro_produzione p on p.dsprodotto = c.dscategoria '
   + ' and p.dsstato <> ''CONCLUSO'' '
   + ' where c.flproduzione <> ''NO'' ';
  AWait(QryVerifica.Open);

  tab2.RenderDataSet(QryVerifica);

end;

procedure TFrmProduzioneCategorie.btnchiudiverifica_click(Event: TJSEvent);
begin

  pnlVerifica.Visible:=false;
  pnlinsert.Visible:=false;
  pnlTab1.Visible:=true;

end;

procedure TFrmProduzioneCategorie.salva_click(Event: TJSEvent);
begin

//   controllo se i campi sono compilati
    if dscategoria.value = '' then
      begin
        ShowMessage('Devi inserire la categoria');
        Exit;
      end;

    QryCategorie.Append;
    QryCategorie.FieldByName('idcategoria').AsInteger := await(GetNextId('PRE_CATEGORIE'));
    QryCategorie.FieldByName('idsocieta').AsString := 'MEL001';
    QryCategorie.FieldByName('dscategoria').AsString := dscategoria.value;
    QryCategorie.FieldByName('flproduzione').AsString := 'SI';
    QryCategorie.Post;
    await(QryCategorie.ApplyUpdates);

    ShowMessage('Inserimento eseguito con successo');

    pnltab1.Visible:=true;
    pnlinsert.Visible:=false;
    pnlVerifica.Visible:=false;

    RefreshCategorie;

end;

procedure TFrmProduzioneCategorie.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

initialization
  RegisterWeb(TFrmProduzioneCategorie);

end.

