unit f_massimo;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, js, web, DateUtils, un_tquery, w_table;

type

  { TFrmMassimo }

  TFrmMassimo = class(TWidget)
  private

  published
    procedure AfterRender; override;
    procedure btnEsegui_click(Event: TJSEvent);
    procedure btnCambia_click(Event: TJSEvent);
    procedure btnCopia_click(Event: TJSEvent);
    procedure btnImporta2021_click(Event: TJSEvent); async;
    procedure btnSimone_click(Event: TJSEvent);
    procedure logga(linea: String);
    div1:TJSHTMLElement;
    div2:TJSHTMLElement;
    div3:TJSHTMLElement;
    link1:TJSHTMLAnchorElement;
    pippo1:TJSHTMLInputElement;
    pippo2:TJSHTMLInputElement;
    data1 : TJSHTMLInputElement;
    QryVecchioMelide: TQuery;
    QryNuovoMelide: TQuery;
    tab1: TWTable;

  end;

implementation

uses un_bindquery;

{$R *.html}

{ TFrmMassimo }

procedure TFrmMassimo.AfterRender;
begin
  data1.value:='2020-09-26';
  logga('massimo (fatto nell AfterRender)');
  div3.innerHTML := 'salve';
end;

procedure TFrmMassimo.btnEsegui_click(Event: TJSEvent);
var
  dt1, dt2: TDateTime;
  dtStr, dtStr2: String;
begin
  logga('data1.value=' + data1.value);
  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dtStr := FormatDateTime('dd.MM.yyyy',dt1) ;
  logga('dtStr=' + dtStr);
  dt2 := IncMonth(dt1,2);
  dtStr2 := FormatDateTime('dd.MM.yyyy',dt2) ;
  logga('dtStr2=' + dtStr2);

end;

procedure TFrmMassimo.btnCambia_click(Event: TJSEvent);
begin
  div2.innerHTML := 'vai al sito www.melide.it';
//  btnCercaMovimenti.innerHTML:='movimenti di cassa';
end;

procedure TFrmMassimo.btnCopia_click(Event: TJSEvent);
begin
  div3.innerHTML := pippo1.value;
  link1.innerHTML := pippo1.value;
  link1.href := pippo2.value;
end;

procedure TFrmMassimo.btnImporta2021_click(Event: TJSEvent); async;
var
  car, ord :Integer;
  data :TDateTime;
begin

  car := 28000;
  ord := 10350;
  data := now;

// seleziono da nuovo Melide
  QryNuovoMelide.Close;
  BindQuery(QryNuovoMelide,'MELIDE');
  QryNuovoMelide.SQL.Text:= ' select po.id_order, po.date_add, po.payment, po.total_paid, pc.lastname, pc.firstname, '
  + ' pc.email, pa.phone, pa.address1, pa.address2, pa.postcode, pa.city, pe.iso_code, pod.product_quantity, '
  + ' ppl.name, pod.total_price_tax_incl, pod.original_product_price'
  + ' from ps_orders po '
  + ' join ps_order_detail pod on pod.id_order = po.id_order '
  + ' join ps_customer pc on pc.id_customer = po.id_customer  '
  + ' join ps_address pa on pa.id_customer = pc.id_customer and pa.id_address = po.id_address_delivery '
  + ' join ps_country pe on pe.id_country = pa.id_country '
  + ' join ps_product_lang ppl on ppl.id_product = pod.product_id and ppl.id_lang = po.id_lang '
  + ' where po.date_add > ''2021/12/06'' '
  + ' and po.date_add < ''2022/01/01'' '
  + ' and po.id_order = 63 '
  + ' order by po.date_add desc ';
  AWait(QryNuovoMelide.Open);
  tab1.RenderDataSet(QryNuovoMelide);

 // QryVecchioMelide.Close;
  QryVecchioMelide.SQL.Text:='Select first 0 *  '
    + ' from ord_ordinimarketing ';
  AWait(QryVecchioMelide.Open);
  tab1.RenderDataSet(QryVecchioMelide);

  QryNuovoMelide.First;
  while not QryNuovoMelide.Eof do
    begin
      exit;
//      QryVecchioMelide.insert;
      QryVecchioMelide.FieldByName('id_carrello').AsInteger:=car;
      QryVecchioMelide.FieldByName('id_ordine').AsInteger:=ord;
      QryVecchioMelide.FieldByName('data').AsDateTime:=QryNuovoMelide.FieldByName('date_add').AsDateTime;
      QryVecchioMelide.FieldByName('numero').AsString:='MEL' + intToStr(ord);
      QryVecchioMelide.FieldByName('pagamento').AsString:=QryNuovoMelide.FieldByName('payment').AsString;

      QryVecchioMelide.FieldByName('cliente').AsString:=QryNuovoMelide.FieldByName('lastname').AsString
      + ' ' + QryNuovoMelide.FieldByName('firstname').AsString ;
      QryVecchioMelide.FieldByName('ragione').AsString:=QryVecchioMelide.FieldByName('cliente').AsString;
      QryVecchioMelide.FieldByName('mail').AsString:=QryNuovoMelide.FieldByName('email').AsString;
      QryVecchioMelide.FieldByName('telefono').AsString:=QryNuovoMelide.FieldByName('phone').AsString;
      QryVecchioMelide.FieldByName('indirizzo').AsString:=QryNuovoMelide.FieldByName('address1').AsString
      + ' ' + QryNuovoMelide.FieldByName('address2').AsString;
      QryVecchioMelide.FieldByName('cap').AsString:=QryNuovoMelide.FieldByName('postcode').AsString;
      QryVecchioMelide.FieldByName('citta').AsString:=QryNuovoMelide.FieldByName('city').AsString;
      QryVecchioMelide.FieldByName('provincia').AsString:=QryNuovoMelide.FieldByName('city').AsString;
      QryVecchioMelide.FieldByName('nazione').AsString:=QryNuovoMelide.FieldByName('iso_code').AsString;
      QryVecchioMelide.FieldByName('nome_spedizione').AsString:=QryVecchioMelide.FieldByName('cliente').AsString;
      QryVecchioMelide.FieldByName('indirizzo_spedizione').AsString:=QryVecchioMelide.FieldByName('indirizzo').AsString;
      QryVecchioMelide.FieldByName('cap_spedizione').AsString:=QryVecchioMelide.FieldByName('cap').AsString;
      QryVecchioMelide.FieldByName('citta_spedizione').AsString:=QryVecchioMelide.FieldByName('citta').AsString;
      QryVecchioMelide.FieldByName('provincia_spedizione').AsString:=QryVecchioMelide.FieldByName('citta').AsString;
      QryVecchioMelide.FieldByName('nazione_spedizione').AsString:=QryVecchioMelide.FieldByName('nazione').AsString;
      QryVecchioMelide.FieldByName('spedizione').AsFloat:=QryNuovoMelide.FieldByName('total_shipping').AsFloat;
      QryVecchioMelide.FieldByName('spesa').AsFloat:=QryNuovoMelide.FieldByName('total_paid').AsFloat;
      QryVecchioMelide.FieldByName('quantita').AsFloat:=QryNuovoMelide.FieldByName('pod.producct_quantity').AsFloat;
//      QryVecchioMelide.FieldByName('sconto').AsString:=QryNuovoMelide.FieldByName('po.payment').AsString;
      QryVecchioMelide.FieldByName('listino').AsFloat:=QryNuovoMelide.FieldByName('pod.original_product_price').AsFloat;
      QryVecchioMelide.FieldByName('nome').AsString:=QryNuovoMelide.FieldByName('pod.profuct_name').AsString;
      QryVecchioMelide.FieldByName('codificaweb').AsString:=QryNuovoMelide.FieldByName('pod.profuct_name').AsString;
      QryVecchioMelide.FieldByName('mel_idocieta').AsString:='MEL001';
      QryVecchioMelide.FieldByName('mel_totalelistino').AsString:=QryNuovoMelide.FieldByName('po.payment').AsString;
      QryVecchioMelide.FieldByName('mel_nmimportosconto').AsString:=QryNuovoMelide.FieldByName('po.payment').AsString;
      QryVecchioMelide.FieldByName('mel_nmricavoscontato').AsString:=QryNuovoMelide.FieldByName('po.payment').AsString;
      QryVecchioMelide.FieldByName('mel_dsstato').AsString:=QryNuovoMelide.FieldByName('po.payment').AsString;
      QryVecchioMelide.FieldByName('mel_dtcreazione').AsDateTime:=data;
      QryVecchioMelide.FieldByName('mel_dtmodifica').AsDateTime:=data;
      QryVecchioMelide.FieldByName('pagamento').AsString:=QryNuovoMelide.FieldByName('po.payment').AsString;



      QryVecchioMelide.post;
      pippo1.value:=IntToStr(car);
      car := car + 1;
      ord := ord + 1;
      QryNuovoMelide.Next;
    end;

end;

procedure TFrmMassimo.btnSimone_click(Event: TJSEvent);
begin
  showmessage('hai cliccato btnSimone');
end;

procedure TFrmMassimo.logga(linea: String);
begin
  div1.innerHTML := div1.innerHTML + '<br>' + linea;
end;

initialization
  RegisterWeb(TFrmMassimo);
end.

