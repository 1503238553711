unit f_menu;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, un_hotkey,
  js, web, DB;

type

  { TFrmMenu }

  TFrmMenu = class(TWidget)
  private
  published
    procedure AfterRender; override;
    procedure start; async;
    procedure tab1_rowclick;
    taSql: TJSHTMLTextAreaElement;
    messaggio:TJSHTMLElement;
    FQuery1: TQuery;
    tab1:TWTable;
  end;

implementation

{$R *.html}

{ TFrmMenu }

procedure TFrmMenu.tab1_rowclick;
var
  meta: TWidgetMeta;
  instance: TWidget;
  dsForm: String;
begin
  console.log(FQuery1.FieldByName('DSTITOLO').AsString);
  dsForm := FQuery1.FieldByName('DSFORM').AsString;
  meta := GetWidgetMeta(dsForm);
  instance := meta.FClass.Create();
  instance.show;
end;

procedure TFrmMenu.AfterRender;
begin
  start;
end;

procedure TFrmMenu.start;
var
  i: Integer;
begin
  FQuery1.Close;
  FQuery1.SQL.Text :=' SELECT * from MENU where flvisibile = ''SI'' order by IDORDINAMENTO';
  AWait(FQuery1.Open);
  for i:=0 to FQuery1.FieldCount-1 do
    Fquery1.Fields[i].Visible:=false;
  FQuery1.FieldByName('DSTITOLO').Visible:=True;
  tab1.Header := false;
  tab1.RenderDataSet(FQuery1);
  messaggio.Visible := false;
end;

initialization
  RegisterWeb(TFrmMenu);
end.
