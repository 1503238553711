unit f_sqleditor;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, un_hotkey, js, web;

type

  { TFrmSqlEditor }

  TFrmSqlEditor = class(TWidget)
  private
    procedure RefreshQuery; async;
  published
    procedure AfterRender; override;
    procedure btnFetch_click(Event: TJSEvent);
    procedure btnApplyUpdates_click(Event: TJSEvent); async;
    taSql: TJSHTMLTextAreaElement;
    writable:TJSHTMLInputElement;
    btnApplyUpdates:TJSHTMLButtonElement;
    divApplyUpdates:TJSHTmlElement;
    btnFetch:TJSHTMLButtonElement;
    FQuery1: TQuery;
    tab1:TWTable;
  end;

implementation

uses un_bindquery;

{$R *.html}

{ TFrmSqlEditor }

procedure TFrmSqlEditor.AfterRender;
begin
  BindQuery(FQuery1,'MELIDE');
  divApplyUpdates.Visible := writable.checked;
  taSql.Value := window.localStorage['LAST_SQL'];
  writable.addEventListener('click',@RefreshQuery);
  THotkey.Create(ElementInstance).Add('CTRL-E',@RefreshQuery);
  tab1.RenderDataSet(nil);
end;

procedure TFrmSqlEditor.btnFetch_click(Event: TJSEvent);
begin
  RefreshQuery;
  Event.preventDefault;
end;

procedure TFrmSqlEditor.btnApplyUpdates_click(Event: TJSEvent);
begin
  btnApplyUpdates.Disabled:=True;
  Await(FQuery1.ApplyUpdates);
  btnApplyUpdates.Disabled:=False;
  Event.preventDefault;
end;

procedure TFrmSqlEditor.RefreshQuery;
begin
  btnFetch.Disabled:= true;
  divApplyUpdates.Visible := writable.checked;
  window.localStorage['LAST_SQL'] := taSql.Value;

  FQuery1.Close;
  FQuery1.SQL.Text := taSql.Value;
  AWait(FQuery1.Open);
  tab1.Writable := writable.checked;
  tab1.RenderDataSet(FQuery1);
  btnFetch.Disabled:= False;
end;

initialization
  RegisterWeb(TFrmSqlEditor);
end.
