unit un_formatsettings;

{$mode objfpc}

interface

uses
  SysUtils,web;


procedure SetupFormatSettings;
procedure LogFormatSettings;

implementation

procedure LogFormatSettings;
begin
  console.log(format('Sysutils.ThousandSeparator=%s',[Sysutils.ThousandSeparator]));
  console.log(format('Sysutils.DecimalSeparator=%s',[Sysutils.DecimalSeparator]));
  //Sysutils.ThousandSeparator:= FormatSettings.ThousandSeparator;
  //Sysutils.DecimalSeparator:= FormatSettings.DecimalSeparator;
  //Sysutils.DateSeparator:= FormatSettings.DateSeparator;
  //Sysutils.TimeSeparator:= FormatSettings.TimeSeparator;
  console.log(format('frm=%s',[FormatFloat('#,##0.00', 26040.5)]))
end;

procedure SetupFormatSettings;
begin
  with FormatSettings do
    begin
      //CurrencyFormat:= 2;  //questa da errore a runtime sul SetCurrencyFormat!
      NegCurrFormat:= 9;
      ThousandSeparator:= '.';
      DecimalSeparator:= ',';
      CurrencyDecimals:= 2;
      DateSeparator:= '/';
      TimeSeparator:= ':';
      //ListSeparator:= ';';  //questa non esiste a compile time
      CurrencyString:= '€';
      ShortDateFormat:= 'dd/MM/yyyy';
      LongDateFormat:= 'dddd d MMMM yyyy';
      TimeAMString:= '';
      TimePMString:= '';
      ShortTimeFormat:= 'hh:nn';
      LongTimeFormat:= 'hh:nn:ss';
      ShortMonthNames:= ['gen','feb','mar','apr','mag','giu',
                        'lug','ago','set','ott','nov','dic'];
      LongMonthNames:= ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno',
                       'Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
      ShortDayNames:= ['dom','lun','mar','mer','gio','ven','sab'];
      LongDayNames:=  ['domenica','luned'#195#172,'marted'#195#172,'mercoled'#195#172,'gioved'#195#172,'venerd'#195#172,'sabato'];
      TwoDigitYearCenturyWindow:= 50;
    end;
end;

end.

