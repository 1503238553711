unit f_ordinimarketing;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, f_selezione,
  DateUtils;

type

  { TFrmOrdiniMarketing }

  TFrmOrdiniMarketing = class(TWidget)
  procedure AfterRender;
  procedure salva_click(Event: TJSEvent); async;

  published
    procedure btnTFrmSeleziona_click; async;
    procedure idcollaboratore_click; async;
    procedure dstipo_click; async;
    salva:TJSHTMLButtonElement;
    idordine : TJSHTMLInputElement;
    dtdata : TJSHTMLInputElement;
    nmorainizio : TJSHTMLInputElement;
    nmorafine : TJSHTMLInputElement;
    idcollaboratore : TJSHTMLInputElement;
    idcodicecommessa : TJSHTMLInputElement;
    dstipo : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    qrymaxordine: TQuery;
    QryDatiOrdine: TQuery;
    QryCollaboratori: TQuery;
  end;

implementation

{$R *.html}

{ TFrmOrdiniMarketing }

procedure TFrmOrdiniMarketing.AfterRender;
begin
  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;
end;

procedure TFrmOrdiniMarketing.salva_click(Event: TJSEvent);
var
  maxid :Integer;
begin

  Console.log('salva_click x2');

   // controllo se i campi sono compilati
  if dtdata.value = '' then ShowMessage('Devi inserire la data');
  if nmorainizio.value = '' then ShowMessage('Devi inserire l''ora di inizio attività');
  if nmorafine.value = '' then ShowMessage('Devi inserire l''ora di fine attività');
  if idcollaboratore.value = '' then ShowMessage('Devi inserire la tua matricola');
  if StrToTime(nmorainizio.value) >= StrToTime(nmorafine.value) then
    ShowMessage('l''ora inizio non puo'' essere maggiore di ora fine');

  // controllo se il collaboratore esiste
  QryCollaboratori.Close;
  QryCollaboratori.SQL.Text := 'select * from collaboratori '
  + ' where idsocieta = ''MEL001'' '
  + ' and idcollaboratore = ' + idcollaboratore.value;
  AWait(QryCollaboratori.Open);

  if QryCollaboratori.RecordCount <> 1 then ShowMessage('La matricola inserita non è corretta');

  // trovo il prossimo numero di commessa
  qrymaxordine.Close;
  qrymaxordine.SQL.Text:='select max(iddatoordinemarketing) as maxid '
  + ' from ord_datiordinimarketing where idsocieta = ''MEL001'' ';
  AWait(qrymaxordine.Open);

  maxid := qrymaxordine.FieldByName('maxid').AsInteger + 1;

// inserisco la commessa
  QryDatiOrdine.Close;
  QryDatiOrdine.SQL.Text:='select first 0 * from ord_datiordinimarketing ';
  AWait(QryDatiOrdine.Open);

  QryDatiOrdine.Append;
  QryDatiOrdine.FieldByName('IDDATOORDINEMARKETING').AsInteger := maxid;
  QryDatiOrdine.FieldByName('IDCARRELLO').AsString := idordine.value;
  QryDatiOrdine.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryDatiOrdine.FieldByName('DSTIPO').AsString := DSTIPO.value;
  QryDatiOrdine.FieldByName('IDCOLLABORATORE').AsString := idcollaboratore.value;
  QryDatiOrdine.FieldByName('DTDATA').AsDateTime:=StrToDate(Copy(dtdata.value,9,2) +'/'+
    Copy(dtdata.value,6,2) +'/'+ Copy(dtdata.value,1,4));
  QryDatiOrdine.FieldByName('NMORAINIZIO').AsString := nmorainizio.value;
  QryDatiOrdine.FieldByName('NMORAFINE').AsString := nmorafine.value;
  QryDatiOrdine.Post;
  QryDatiOrdine.ApplyUpdates;

  ShowMessage('Inserimento eseguito con successo');


end;


procedure TFrmOrdiniMarketing.btnTFrmSeleziona_click;
var
  dt1, dt2 :TDateTime;
  dtStr1, dtStr2 :String;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  // controllo se le date sono compilate
  if data1.value = '' then
  begin
    ShowMessage('Compila la data da');
    data1.focus;
    exit;
  end;

  if data2.value = '' then
  begin
    ShowMessage('Compila la data a');
    data2.focus;
    exit;
  end;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dtStr1 := FormatDateTime('yyyy-MM-dd',dt1) ;
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value);
  dtStr2 := FormatDateTime('yyyy-MM-dd',dt2) ;

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select id_carrello, data, numero as ordine, cliente, codificaweb as codice, nome as prodotto '
      + ' from ord_ordinimarketing '
      + ' where data >= ' + '''' + dtStr1 + ''''
      + ' and data <= ' + '''' + dtStr2 + ''''
      + ' order by id_carrello desc ';
      AWait(QrySeleziona.Open);
      QrySeleziona.FieldByName('ID_CARRELLO').Visible:= false;
    end;

    if AWait(Show) = mrOK then
    begin
      idordine.value := QrySeleziona.FieldByName('ID_CARRELLO').AsString;
    end;
  end;

end;

procedure TFrmOrdiniMarketing.idcollaboratore_click;
begin
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcollaboratore as id, dscognome ||'' ''|| dsnome as collaboratore from collaboratori ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      idcollaboratore.value := QrySeleziona.FieldByName('ID').AsString;
    end;
  end;
end;

procedure TFrmOrdiniMarketing.dstipo_click;
begin
     with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idprodotto as id, dsprodotto from co_prodotti '
       + ' order by dsprodotto ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDDATICOMMESSA').Visible:= false;
//      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;
//      QrySeleziona.FieldByName('FLELABORATA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      dstipo.value := QrySeleziona.FieldByName('dsprodotto').AsString;
    end;
  end;
end;

initialization
  RegisterWeb(TFrmOrdiniMarketing);

end.
