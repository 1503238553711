unit f_notecategorie;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmNoteCategorie }

  TFrmNoteCategorie = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private

  published
    procedure RefreshCategorie;  async;
    procedure btninsert_click(Event: TJSEvent); async;
    procedure btnchiudiinsert_click(Event: TJSEvent); async;
    QryCategorie: TQuery;
    tab1:TWTable;
    idcategoria : TJSHTMLInputElement;
    dscategoria : TJSHTMLInputElement;
    pnltab1:TJSHTMLElement;
    pnlinsert:TJSHTMLElement;

  end;

implementation

{$R *.html}


{ TFrmNoteCategorie }

procedure TFrmNoteCategorie.AfterRender;
begin

//

end;

procedure TFrmNoteCategorie.AfterDomAppend;
begin

  RefreshCategorie;

end;

procedure TFrmNoteCategorie.RefreshCategorie;
begin

  pnlinsert.Visible:=false;

  QryCategorie.Close;
  QryCategorie.SQL.Text := 'select idcategoria, idsocieta, dscategoria '
   + ' from no_categorie '
   + ' order by idcategoria ';
  AWait(QryCategorie.Open);

  QryCategorie.FieldByName('idcategoria').Visible:= false;
  QryCategorie.FieldByName('idsocieta').Visible:= false;

  tab1.RenderDataSet(QryCategorie);

end;

procedure TFrmNoteCategorie.btninsert_click(Event: TJSEvent);
begin

  pnltab1.Visible:=false;
  pnlinsert.Visible:=true;
  dscategoria.value := '';

end;

procedure TFrmNoteCategorie.btnchiudiinsert_click(Event: TJSEvent);
begin

  pnltab1.Visible:=true;
  pnlinsert.Visible:=false;

end;

procedure TFrmNoteCategorie.salva_click(Event: TJSEvent);
begin

//   controllo se i campi sono compilati
    if dscategoria.value = '' then
      begin
        ShowMessage('Devi inserire la categoria');
        Exit;
      end;

    QryCategorie.Append;
    QryCategorie.FieldByName('idcategoria').AsInteger := await(GetNextId('NO_CATEGORIE'));
    QryCategorie.FieldByName('idsocieta').AsString := 'MEL001';
    QryCategorie.FieldByName('dscategoria').AsString := dscategoria.value;
    QryCategorie.Post;
    await(QryCategorie.ApplyUpdates);

    ShowMessage('Inserimento eseguito con successo');

    pnltab1.Visible:=true;
    pnlinsert.Visible:=false;

    RefreshCategorie;

end;

procedure TFrmNoteCategorie.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

initialization
  RegisterWeb(TFrmNoteCategorie);

end.

