unit f_ordini;

{$mode objfpc}

interface

uses
  Classes, SysUtils, strutils, js, web, un_widget, un_tquery, w_table,
  f_ordinedettaglio, DateUtils, f_selezione, w_edit, un_servermodule, w_label,
  un_telemetria, UnArrayDef, DB;

type

  TVisibilita = (visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso);
const
  limiteRecord = 300;
  msgLimiteRecord = 'Sono riportati solo gli ultimi 300 ordini' ;
  msgDateErrate = 'Correggi le date corrette per visualizzare gli ordini';
  msgNessunRecord = 'Nessun record da visualizzare';
  msgCaricamentoInCorso = 'Caricamento ordini in corso...';

type
  { TFrmOrdini }

  TFrmOrdini = class(TWidget)
    procedure AfterRender; override;
  private
    whereDate :String;
    dt1str, dt2str :String;
    function DateOk:Boolean;
    procedure RefreshOrdini; async;
    procedure QryOrdiniOnFilterRecord(DataSet: TDataSet; var Accept: Boolean);
    procedure RefreshFiltro;
    procedure SetLabel(lbl: TWLabel; value: String);
    procedure ImpostaVisibilita(visibilita:TVisibilita);
    procedure SetLabelStampa;
  published
    procedure tab1_rowclick;
    procedure filtro_change;
    procedure filtro_input;
    procedure data1_change;
    procedure data2_change;
    procedure idstato_change;
    procedure stampa1_click(event: TJSEvent); async;
    stampa1 :TJSHTMLButtonElement;
//    procedure link3_click(event: TJSEvent); async;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    idstato: TJSHTMLSelectElement;
    filtro: TWEdit;
    edit2: TJSHTMLInputElement;
    pnltab1: TJSHTMLElement;
//    link3: TJSHTMLAnchorElement;
    QryOrdini: TQuery;
    tab1:TWTable;
    labelTop:TWLabel;
    labelBottom:TWLabel;
  end;

implementation

{$R *.html}

{ TFrmOrdini }

procedure TFrmOrdini.AfterRender;
begin
  QryOrdini.OnFilterRecord := @QryOrdiniOnFilterRecord;

  filtro.placeholder := 'Ricerca ordini...';
  filtro.seleziona1.Visible:= false;

  labelTop.header.innerHTML:= '<br><br>';
  labelTop.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 18px; font-weight: bold; border-style: solid;';
  labelTop.footer.innerHTML:= '<br><br>';


  labelBottom.labelbody.innerHTML:= 'bottom';
  labelBottom.header.innerHTML:= '<br>';
  labelBottom.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 22px; font-weight: bold; border-style: solid;';


  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

  RefreshOrdini;

end;


procedure TFrmOrdini.QryOrdiniOnFilterRecord(DataSet: TDataSet;
  var Accept: Boolean);
var
  str:String;
  i: Integer;
begin
  Accept:= true;
  str := filtro.value.ToLowerInvariant;
  for i:= 0 to DataSet.FieldCount-1 do
  begin
    if DataSet.Fields[i].Visible and
       DataSet.Fields[i].DisplayText.ToLowerInvariant.Contains(str) then
         exit;
  end;
  //nessun campo matcha la stringa cercata
  Accept := false;
end;



procedure TFrmOrdini.SetLabel(lbl:TWLabel;value:String);
begin
  lbl.Visible :=   value <> '' ;
  lbl.labelbody.innerHTML := value;
end;

procedure TFrmOrdini.SetLabelStampa;
var
  RecordCount: Integer;
begin
  if not QryOrdini.Active then
    exit;

  RecordCount := 0;
  QryOrdini.First;
  while not QryOrdini.EOF do
  begin
    Inc(RecordCount);
    QryOrdini.Next;
  end;
  stampa1.innerHTML:= format('Stampa (%d %s)'
      ,[RecordCount, IfThen(RecordCount = 1,'ordine','ordini') ]);
end;

procedure TFrmOrdini.ImpostaVisibilita(visibilita: TVisibilita);
begin
  //visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso
  SetLabelStampa;
  SetLabel(labelTop,'');
  SetLabel(labelBottom,'');

  if visibilita = visDateErrate then SetLabel(labelTop,msgDateErrate);
  if visibilita = visNessunRecord then SetLabel(labelBottom,msgNessunRecord);
  if visibilita = visLimiteRecord then SetLabel(labelBottom,msgLimiteRecord);
  if visibilita = visCaricamentoInCorso then SetLabel(labelBottom,msgCaricamentoInCorso);

  filtro.Visible :=  visibilita <> visDateErrate;
//  console.log(format(' filtro.Visible := %s',[BoolToStr(filtro.Visible,true)]));
  pnltab1.Visible:= visibilita in [visLimiteRecord,visCiSonoRecord];
end;

procedure TFrmOrdini.RefreshOrdini;
begin


  //SetLabel(labelTop,'');
  //messaggio.Visible := true;
  //pnltab1.Visible := false;
  //idempty.Visible:= false;
  //idlink.Visible:= false;
  //msgLimite.Visible := false;

  if not DateOk then
  begin
    ImpostaVisibilita(visDateErrate);
    exit;
  end;

  ImpostaVisibilita(visCaricamentoInCorso);
  QryOrdini.Close;
  QryOrdini.SQL.Text:=  'select first ' + IntToStr(limiteRecord) +' distinct mel_dsstato as stato, cliente, id_ordine, data, numero '
                      + ' from ord_ordinimarketing '
                      + ' where mel_idsocieta = ''MEL001'' '
                      + ' and ' + whereDate;
  if idstato.value <> '' then
  begin
    QryOrdini.SQL.Text := QryOrdini.SQL.Text + ' and mel_dsstato = ' + '''' + idstato.value + ''''
  end;
  QryOrdini.SQL.Text := QryOrdini.SQL.Text + ' order by data desc';
  AWait(QryOrdini.Open);
  QryOrdini.FieldByName('id_ordine').Visible:= false;
  console.log(format('QryOrdini.RecordCount=%d',[QryOrdini.RecordCount]));

  RefreshFiltro;
end;

procedure TFrmOrdini.RefreshFiltro;
begin
  QryOrdini.Filtered:= filtro.value.Trim <> '';
  QryOrdini.First;
  tab1.RenderDataSet(QryOrdini);

  if QryOrdini.IsEmpty then
    ImpostaVisibilita(visNessunRecord)
  else if QryOrdini.RecordCount = limiteRecord  then
    ImpostaVisibilita(visLimiteRecord)
  else
    ImpostaVisibilita(visCiSonoRecord);
end;

procedure TFrmOrdini.filtro_change;
begin
  RefreshFiltro;
end;

procedure TFrmOrdini.filtro_input;
begin
  RefreshFiltro;
end;

procedure TFrmOrdini.tab1_rowclick;
var
  instance: TFrmOrdineDettaglio;
begin

  instance := TFrmOrdineDettaglio.Create();
  instance.idordine := QryOrdini.FieldByName('id_ordine').AsInteger ;
  instance.show;

end;

procedure TFrmOrdini.data1_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;

  RefreshOrdini;

end;

procedure TFrmOrdini.data2_change;
begin

  window.localStorage[ClassName+'_data2'] := data2.value;

  RefreshOrdini;

end;

procedure TFrmOrdini.idstato_change;
begin
  console.log('TFrmOrdini.idedit_change');
  RefreshOrdini;
end;

procedure TFrmOrdini.stampa1_click(event: TJSEvent);
var
  sql, clausola: String;
begin

  with ServerModule('TWebStampe') do
  begin
    Params['idStampa'] := '81';
    AWait(Call('WebParametri'));
    sql := Response.Strings['SQL'];
  end;

  clausola := format( 'AND O.ID_ORDINE IN (%s)',[ ToCommaText(QryOrdini,'ID_ORDINE',true) ]);
  sql := SysUtils.StringReplace(sql,'--AND1',clausola,[]);

  with ServerModule('TWebStampe') do
  begin
    Proc := 'WebProcessa';
    Params['idStampa'] := '81';
    Params['sql'] := sql;
    Params['DATADA'] := dt1str;
    Params['DATAA'] :=  dt2str;
    window.open(Url,'_blank');
  end;
  TmRegistraEvento('stampa_ordini',format('%s ~ %s|stato=%s|filtro=%s'
  ,[data1.value,data2.value,idstato.value,filtro.Value]));
end;

//procedure TFrmOrdini.link3_click(event: TJSEvent);
//begin
//
//  if QryOrdini.RecordCount < 1 then
//  begin
//    ShowMessage('prima di stampare devi eseguire la query...');
//    exit;
//  end;
//
//  with ServerModule('TWebStampe') do
//  begin
//    Proc := 'WebProcessa';
//    Params['idStampa'] := '89';
//    Params['sql'] := ' select * from ord_ordinimarketing '
//     + ' where mel_idsocieta = ''MEL001'' '
//     + ' and ' + whereDate;
//   if idstato.value <> '' then
//   begin
//    Params['sql'] := Params['sql'] + ' and mel_dsstato = ' + '''' + idstato.value + ''''
//   end;
//  Params['sql'] := Params['sql'] + ' order by data ';
//  link3.href := Url;
//  link3.target := '_blank';
//  end;
//
//end;

function TFrmOrdini.DateOk: Boolean;
var
  dt1, dt2: TDateTime;
begin

  Result :=  false;

  if (data1.value = '') or (data2.value = '')
    or (data1.value > data2.value) then
  begin
    SetLabel(labelTop,msgDateErrate);
    SetLabel(labelBottom,'');
    exit;
  end;

  Result :=  true;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value) ;
  dt2 := IncDay(dt2);

// questo formato date serve per la query
  dt1str := FormatDateTime('dd.MM.yyyy',dt1);
  dt2str := FormatDateTime('dd.MM.yyyy',dt2);

  whereDate := ' data >= ''' + dt1str + ''''
    + ' and data < ''' + dt2str + '''';

// questo formato di date serve alla stampa
  dt1str := FormatDateTime('dd/MM/yyyy',dt1) + ' 00:00:00';
  dt2str := FormatDateTime('dd/MM/yyyy',dt2) + ' 00:00:00';

end;

initialization
  RegisterWeb(TFrmOrdini);

end.

