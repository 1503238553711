unit f_produzioneprodotti;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmProduzioneProdotti }

  TFrmProduzioneProdotti = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idproduzioneprodotto:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private
    primaVolta :boolean;
  published
    procedure idresponsabile_click; async;
    procedure dsresponsabile_click; async;
    procedure idprodotto_click; async;
    procedure dsprodotto_click; async;
    procedure CompilaCampi; async;
    procedure ControlloCompilazioneCampi; async;
    procedure idstato_click; async;
    QrySeleziona: TQuery;
    QryProduzioneDettaglio: TQuery;
    qryproduzione: TQuery;
    idprodotto: TJSHTMLSelectElement;
    dsprodotto: TJSHTMLSelectElement;
    dsmodello: TJSHTMLSelectElement;
    idresponsabile: TJSHTMLSelectElement;
    dsresponsabile: TJSHTMLSelectElement;
    dtscadenza: TJSHTMLSelectElement;
    dtnextscadenza: TJSHTMLSelectElement;
    idstato: TJSHTMLSelectElement;
    dsnote: TJSHTMLSelectElement;
    dsnextstep: TJSHTMLSelectElement;
    pnlnextstep:TJSHTMLElement;
    pnlnextscadenza:TJSHTMLElement;

  end;

implementation

{$R *.html}

{ TFrmProduzioneProdotti }

procedure TFrmProduzioneProdotti.AfterRender;
begin

  idprodotto.Visible:=false;
  idresponsabile.Visible:=false;
  primaVolta := true;

end;

procedure TFrmProduzioneProdotti.AfterDomAppend;
begin

  if (idproduzioneprodotto > 0) and primaVolta then
  begin
    Await(CompilaCampi);
  end;

  primaVolta := false;

end;

procedure TFrmProduzioneProdotti.salva_click(Event: TJSEvent);
begin

// inserisco il prodotto in produzione
  ControlloCompilazioneCampi;

  if idproduzioneprodotto <= 0 then
    begin
      console.log('aggiungo prodotto');
      qryproduzione.Close;
      qryproduzione.SQL.Text:= ' SELECT * from pro_produzione  ';
      AWait(qryproduzione.Open);

      qryproduzione.Append;
      qryproduzione.FieldByName('IDPRODUZIONE').AsInteger := await(GetNextId('PRODUZIONE'));
      qryproduzione.FieldByName('IDSOCIETA').AsString := 'MEL001';
      qryproduzione.FieldByName('IDPRODOTTO').AsString := idprodotto.value;
      qryproduzione.FieldByName('DSPRODOTTO').AsString := dsprodotto.value;
      qryproduzione.FieldByName('DSMODELLO').AsString := dsmodello.value;
      qryproduzione.FieldByName('IDRESPONSABILE').AsString := idresponsabile.value;
      qryproduzione.FieldByName('DSRESPONSABILE').AsString := dsresponsabile.value;
      //qryproduzione.FieldByName('DTSCADENZA').AsDateTime :=
      //StrToDate(Copy(dtscadenza.value,9,2) +'/'+
      //         Copy(dtscadenza.value,6,2) +'/'+
      //         Copy(dtscadenza.value,1,4));
      qryproduzione.FieldByName('DTNEXTSCADENZA').AsDateTime :=
      StrToDate(Copy(dtnextscadenza.value,9,2) +'/'+
               Copy(dtnextscadenza.value,6,2) +'/'+
               Copy(dtnextscadenza.value,1,4));
      qryproduzione.FieldByName('DSSTATO').AsString := idstato.value;
      qryproduzione.FieldByName('DSNOTE').AsString := dsnote.value;
      qryproduzione.Post;
      qryproduzione.ApplyUpdates;

      QryProduzioneDettaglio.Close;
      QryProduzioneDettaglio.SQL.Text := ' select first 0 * from pro_produzionedettaglio ';
      AWait(QryProduzioneDettaglio.Open);

    // inserisco il record di dettaglio produzione
      QryProduzioneDettaglio.Append;
      QryProduzioneDettaglio.FieldByName('IDPRODUZIONEDETTAGLIO').AsInteger := await(GetNextId('PRODUZIONEDETTAGLIO'));
      QryProduzioneDettaglio.FieldByName('IDPRODUZIONE').AsInteger := qryproduzione.FieldByName('IDPRODUZIONE').AsInteger;
      QryProduzioneDettaglio.FieldByName('IDSOCIETA').AsString := 'MEL001';
      QryProduzioneDettaglio.FieldByName('DSSTEP').AsString := dsnextstep.value;
      QryProduzioneDettaglio.FieldByName('DSNEXTSTEP').AsString := dsnextstep.value;
      QryProduzioneDettaglio.FieldByName('IDRESPONSABILE').AsString := qryproduzione.FieldByName('IDRESPONSABILE').AsString;
      QryProduzioneDettaglio.FieldByName('DSRESPONSABILE').AsString := qryproduzione.FieldByName('DSRESPONSABILE').AsString;
      QryProduzioneDettaglio.FieldByName('DSNOTE').AsString := dsnote.value;
      QryProduzioneDettaglio.FieldByName('DSSTATO').AsString := qryproduzione.FieldByName('DSSTATO').AsString;
      QryProduzioneDettaglio.FieldByName('DTSCADENZA').AsDateTime := qryproduzione.FieldByName('DTNEXTSCADENZA').AsDateTime;

      QryProduzioneDettaglio.Post;
      await(QryProduzioneDettaglio.ApplyUpdates);
      ShowMessage('Inserimento eseguito con successo');
  end
  else
// modifico un record produzione
    begin
      qryproduzione.edit;
      qryproduzione.FieldByName('IDSOCIETA').AsString := 'MEL001';
      console.log('prima inserito idprodotto');
      qryproduzione.FieldByName('IDPRODOTTO').AsString := idprodotto.value;
      console.log('dopo inserito idprodotto');
      qryproduzione.FieldByName('DSPRODOTTO').AsString := dsprodotto.value;
      qryproduzione.FieldByName('DSMODELLO').AsString := dsmodello.value;
      qryproduzione.FieldByName('IDRESPONSABILE').AsString := idresponsabile.value;
      qryproduzione.FieldByName('DSRESPONSABILE').AsString := dsresponsabile.value;
      //qryproduzione.FieldByName('DTSCADENZA').AsDateTime :=
      //StrToDate(Copy(dtscadenza.value,9,2) +'/'+
      //         Copy(dtscadenza.value,6,2) +'/'+
      //         Copy(dtscadenza.value,1,4));
      qryproduzione.FieldByName('DSSTATO').AsString := idstato.value;
      qryproduzione.FieldByName('DSNOTE').AsString := dsnote.value;
      qryproduzione.Post;
      qryproduzione.ApplyUpdates;
      ShowMessage('Modifica eseguita con successo');
    end;

  pnlnextstep.Visible:=true;
  pnlnextscadenza.Visible:=true;

  Close();

end;

procedure TFrmProduzioneProdotti.CompilaCampi;
var
  scad :String;
begin

// precompilo il dati per la modifica del prodotto
  if idproduzioneprodotto > 1 then
    begin
    pnlnextstep.Visible:=false;
    pnlnextscadenza.Visible:=false;
    end;

  qryproduzione.Close;
  qryproduzione.SQL.Text:= ' SELECT * '
                        + ' from pro_produzione  '
                        + ' where idproduzione = ' + IntToStr(idproduzioneprodotto);
  AWait(qryproduzione.Open);

  scad := qryproduzione.FieldByName('dtscadenza').AsString;
  scad := Copy(scad,7,4) +'-'+ Copy(scad,4,2) +'-'+ Copy(scad,1,2);
  dtscadenza.value := scad;

  idprodotto.value := qryproduzione.FieldByName('idprodotto').AsString;
  dsprodotto.value := qryproduzione.FieldByName('dsprodotto').AsString;
  dsmodello.value := qryproduzione.FieldByName('dsmodello').AsString;
  idstato.value := qryproduzione.FieldByName('dsstato').AsString;
  dsnote.value := qryproduzione.FieldByName('dsnote').AsString;
  idresponsabile.value := qryproduzione.FieldByName('idresponsabile').AsString;
  dsresponsabile.value := qryproduzione.FieldByName('dsresponsabile').AsString;

end;

procedure TFrmProduzioneProdotti.ControlloCompilazioneCampi;
var
  dtscad :String;
begin

  //   controllo se i campi sono compilati
    //dtscad := DateToStr(date);
    //dtscad := Copy(dtscad,7,4) +'-'+ Copy(dtscad,4,2) +'-'+ Copy(dtscad,1,2);
    //if dtscadenza.value <= dtscad then
    //  begin
    //    ShowMessage('La scadenza non può essere inferiore o uguale ad oggi');
    //    Exit;
    //  end;
    //if dtscadenza.value = '' then
    //  begin
    //    ShowMessage('Devi inserire la data di lancio del prodotto');
    //    Exit;
    //  end;
    if idproduzioneprodotto <= 0 then
      begin
        if dtnextscadenza.value = '' then
          begin
          ShowMessage('Devi inserire la data per il next step');
          Exit;
        end;
        if dtnextscadenza.value <= dtscad then
          begin
            ShowMessage('La scadenza dello step non può essere inferiore o uguale ad oggi');
            Exit;
          end;
        if dsnextstep.value = '' then
          begin
          ShowMessage('Devi inserire il next step');
          Exit;
        end;
      end;
    if idstato.value = '' then
      begin
        ShowMessage('Devi inserire lo stato');
        Exit;
      end;
    if idprodotto.value = '' then
      begin
        ShowMessage('Devi inserire prodotto');
        Exit;
      end;
    if dsprodotto.value = '' then
      begin
        ShowMessage('Devi inserire prodotto');
        Exit;
      end;
    if dsmodello.value = '' then
      begin
        ShowMessage('Devi inserire modello');
        Exit;
      end;
    if idresponsabile.value = '' then
      begin
        ShowMessage('Devi inserire l''id del responsabile');
        Exit;
      end;
    if dsresponsabile.value = '' then
      begin
        ShowMessage('Devi inserire il reponsabile');
        Exit;
      end;
    if dsnote.value = '' then
      begin
        ShowMessage('Devi inserire le note');
        Exit;
      end;

end;

procedure TFrmProduzioneProdotti.idstato_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dsstato as stato '
      + ' from pro_stati ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      idstato.value := QrySeleziona.FieldByName('stato').AsString;
    end;

  end;

end;

procedure TFrmProduzioneProdotti.chiudi_click(Event: TJSEvent);
begin

  pnlnextstep.Visible:=true;
  pnlnextscadenza.Visible:=true;

  Close();

end;

procedure TFrmProduzioneProdotti.idresponsabile_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcollaboratore as id, dscognome ||'' ''|| dsnome as dscollaboratore '
      + ' from collaboratori ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idresponsabile.value := QrySeleziona.FieldByName('id').AsString;
      dsresponsabile.value := QrySeleziona.FieldByName('dscollaboratore').AsString;
    end;
  end;

end;

procedure TFrmProduzioneProdotti.dsresponsabile_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcollaboratore as id, dscognome ||'' ''|| dsnome as dscollaboratore '
      + ' from collaboratori ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idresponsabile.value := QrySeleziona.FieldByName('id').AsString;
      dsresponsabile.value := QrySeleziona.FieldByName('dscollaboratore').AsString;
    end;

  end;
end;

procedure TFrmProduzioneProdotti.idprodotto_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= ' SELECT DISTINCT IDCATEGORIA, DSCATEGORIA '
      + ' FROM PRE_CATEGORIE '
      + ' WHERE FLPRODUZIONE <> ''NO'' '
      + ' ORDER BY DSCATEGORIA ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idprodotto.value := QrySeleziona.FieldByName('idcategoria').AsString;
      dsprodotto.value := QrySeleziona.FieldByName('dscategoria').AsString;
    end;
  end;

end;

procedure TFrmProduzioneProdotti.dsprodotto_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= ' SELECT DISTINCT IDCATEGORIA, DSCATEGORIA '
      + ' FROM PRE_CATEGORIE '
      + ' ORDER BY DSCATEGORIA ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idprodotto.value := QrySeleziona.FieldByName('idcategoria').AsString;
      dsprodotto.value := QrySeleziona.FieldByName('dscategoria').AsString;
    end;
  end;

end;


initialization
  RegisterWeb(TFrmProduzioneProdotti);

end.

