unit f_statistiche;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, un_formatsettings,
  f_selezione, un_servermodule, f_upload, DateUtils, DB;

type

  { TFrmStatistiche }

  TFrmStatistiche = class(TWidget)
    whereDate :String;
    procedure AfterRender;
    procedure btnTFrmSeleziona_click; async;
    procedure btnTFrmImporta_click; async;
    procedure pulisci_click;
    function DateOk:Boolean;
    function TotaleFatturato(CampiFiltro:String):Double; async;
    function TotaleImponibile(CampiFiltro:String):Double; async;
    function DataAggiornamento:String; async;
    function SqlCerca(CampiFiltro:String):String;
    procedure QryGridAddSqlCercaSe(CampiFiltro:String); async;
  published
    procedure btn1_click; async;
    procedure btn2_click; async;
    procedure btn3_click; async;
    procedure btn4_click; async;
    procedure btn5_click; async;
    procedure btn6_click; async;
    procedure idstatistica_change;
    procedure cerca_change;
    procedure data1_change;
    procedure data2_change;
    btn1: TJSHTMLButtonElement;
    titolo: TJSHTMLElement;
    btn2: TJSHTMLButtonElement;
    btn3: TJSHTMLButtonElement;
    btn4: TJSHTMLButtonElement;
    btn5: TJSHTMLButtonElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    cerca: TJSHTMLInputElement;
    tab1: TWTable;
    idfatturato : TJSHTMLInputElement;
    idimponibile : TJSHTMLInputElement;
    idaggiornamento : TJSHTMLInputElement;
    idstatistica:  TJSHTMLSelectElement;
    QryGrid: TQuery;
    QryMaxDataAggiornamento: TQuery;
    QryTotale: TQuery;
    QryTotaleImponibile: TQuery;
    pnl1:TJSHTMLElement;
  end;

implementation

{$R *.html}

{ TFrmStatistiche }

procedure TFrmStatistiche.AfterRender;
begin
  pnl1.Visible := false ;
  tab1.RenderDataSet(nil);

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

  idstatistica_change;

end;

procedure TFrmStatistiche.btnTFrmSeleziona_click;
  var
  datamin, datamax :String;
begin

  // controllo se i campi sono compilati
  if data1.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data1.focus;
    exit;
  end;
  if data2.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data2.focus;
    exit;
  end;

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := False;

      datamin := Copy(data1.value,9,2) +'.'+
        Copy(data1.value,6,2) +'.'+
        Copy(data1.value,1,4);
      datamax := Copy(data2.value,9,2) +'.'+
        Copy(data2.value,6,2) +'.'+
        Copy(data2.value,1,4);

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select * from ord_OrdiniMarketing '
      + ' where ' + whereDate
      + ' order by data desc ';
      AWait(QrySeleziona.Open);
      QrySeleziona.FieldByName('ID_CARRELLO').Visible:= false;
      QrySeleziona.FieldByName('ID_ORDINE').Visible:= false;
      QrySeleziona.FieldByName('MEL_IDSOCIETA').Visible:= false;
      QrySeleziona.FieldByName('NUMERO').Visible:= false;
      QrySeleziona.FieldByName('PAGAMENTO').Visible:= false;
      QrySeleziona.FieldByName('NOTE').Visible:= false;
      QrySeleziona.FieldByName('MEL_DTCREAZIONE').Visible:= false;
      QrySeleziona.FieldByName('MEL_DTMODIFICA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
//      self.taSql.value := 'OK! selezionato IDARTICOLO=' + QrySeleziona.FieldByName('IDARTICOLO').AsString
//      + ' DSARTICOLO=' + QrySeleziona.FieldByName('DSARTICOLO').AsString;
    end;
  end;
end;

procedure TFrmStatistiche.btnTFrmImporta_click;
var
  path:String;
begin

  with TFrmUpload.Create do
  begin
    RestartUpload := True;
    CloseOnComplete:= true;
    Title := 'Upload file csv' ;
    Message := 'seleziona il file';
    if await(Show) <> mrOK then
      exit;
    path := ServerFilename
  end;

  console.log(format('Import ordini marketing file=[%s]',[path]));

  with ServerModule('TWebImportOrdiniMarketing') do
  begin
    //'C:\Melide\Ordini marketing\ordine-marketing_04-08-2020-10-41.csv';
    Params['filename'] := path;
    await(Call('Processa'));
    ShowMessage(Response.Text);
  end;
end;

function TFrmStatistiche.SqlCerca(CampiFiltro: String): String;
begin
  result := ' and ('+ CampiFiltro + ' like ''%' + cerca.value.ToUpperInvariant + '%'' ) ';
end;

procedure TFrmStatistiche.QryGridAddSqlCercaSe(CampiFiltro: String);
begin
  idfatturato.value:=FormatFloat('#,##0,00', AWait(TotaleFatturato(CampiFiltro)));
  idimponibile.value:=FormatFloat('#,##0,00', AWait(TotaleImponibile(CampiFiltro)));

  if cerca.value <> '' then QryGrid.Sql.Add(SqlCerca(CampiFiltro));
end;

// statistica per provincia
procedure TFrmStatistiche.btn1_click;
begin
  console.log('btn1_click x2');
//  ShowMessage('ciao');
  if not DateOk then
    exit;

  QryGrid.Close;
  QryGrid.SQL.Text:='Select nazione || ''_'' || provincia as dove, SUM(QUANTITA) AS NR, SUM(MEL_NMRICAVOSCONTATO) AS IMPORTO, '
    + ' (SUM(MEL_NMRICAVOSCONTATO) / SUM(QUANTITA)) AS MEDIASPESA '
    + ' from ord_ordinimarketing where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate;
  Await(QryGridAddSqlCercaSe('upper(coalesce(provincia,'''') || ''/'' || coalesce(nazione,'''') )'));
  QryGrid.SQL.Add( ' group by 1 ');
  QryGrid.SQL.Add( ' order by 1, SUM(MEL_NMRICAVOSCONTATO) desc ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('IMPORTO')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('MEDIASPESA')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idaggiornamento.value:=AWait(DataAggiornamento);

//  titolo.innerHTML := QryGrid.SQL.Text ;
//  idfatturato.value := FloatToStr(AWait(TotaleFatturato));
//  ShowMessage(FloatToStr(AWait(TotaleFatturato)));

end;

// statistica per prodotto
procedure TFrmStatistiche.btn2_click;
begin
  console.log('btn2_click');

  if not DateOk then
    exit;

  QryGrid.Close;
  QryGrid.SQL.Text:='Select NOME AS PRODOTTO, SUM(QUANTITA) AS NUMERO, SUM(MEL_NMRICAVOSCONTATO) AS IMPORTO, '
    + ' SUM(MEL_NMRICAVOSCONTATO) / SUM(QUANTITA) AS MEDIASPESA '
    + ' from ord_ordinimarketing where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate;
  Await(QryGridAddSqlCercaSe('upper(nome)'));
  QryGrid.SQL.Add(' group by nome ');
  QryGrid.SQL.Add(' order by SUM(QUANTITA) desc, SUM(MEL_NMRICAVOSCONTATO) desc ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('IMPORTO')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('MEDIASPESA')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idaggiornamento.value:=AWait(DataAggiornamento);

end;

// statistica per maglietta/ricamo
procedure TFrmStatistiche.btn3_click;
begin
  console.log('btn3_click');

  if not DateOk then
    exit;

  QryGrid.Close;
  QryGrid.SQL.Text:='Select NOME AS PRODOTTO, CODIFICAWEB, SUM(QUANTITA) AS NUMERO, '
    + ' SUM(MEL_NMRICAVOSCONTATO) AS IMPORTO '
    + ' from ord_ordinimarketing where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate
    + ' AND NOME LIKE ''%shirt%'' ';
  Await(QryGridAddSqlCercaSe('upper(coalesce(nome,'''') || ''/'' || coalesce(codificaweb,'''') )'));
  QryGrid.SQL.Add(' group by NOME, CODIFICAWEB ');
  QryGrid.SQL.Add(' order by SUM(QUANTITA) desc, SUM(MEL_NMRICAVOSCONTATO) desc ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('IMPORTO')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idaggiornamento.value:=AWait(DataAggiornamento);

end;

// statistica per prodotto/modello
procedure TFrmStatistiche.btn4_click;
begin
  console.log('btn4_click');

  if not DateOk then
    exit;

  QryGrid.Close;
  QryGrid.SQL.Text:='Select NOME AS PRODOTTO, CODIFICAWEB, SUM(QUANTITA) AS NUMERO, '
    + ' SUM(MEL_NMRICAVOSCONTATO) AS IMPORTO '
    + ' from ord_ordinimarketing where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate;
  Await(QryGridAddSqlCercaSe('upper(coalesce(nome,'''') || ''/'' || coalesce(codificaweb,'''') )'));
  QryGrid.SQL.Add(' group by NOME, CODIFICAWEB ');
  QryGrid.SQL.Add(' order by SUM(QUANTITA) desc, SUM(MEL_NMRICAVOSCONTATO) desc ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('IMPORTO')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idaggiornamento.value:=AWait(DataAggiornamento);

end;

// statica per mese
procedure TFrmStatistiche.btn5_click;
begin

  console.log('btn5_click x2');
//  ShowMessage('ciao');
  if not DateOk then
    exit;

  QryGrid.Close;
  QryGrid.SQL.Text:='SELECT EXTRACT(YEAR FROM DATA) AS ANNO, EXTRACT(MONTH FROM DATA) AS MESE, '
    + ' COUNT(*) AS NUMERO, SUM(MEL_NMRICAVOSCONTATO) AS RICAVO '
    + ' FROM ORD_ORDINIMARKETING where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate
    + ' GROUP BY EXTRACT(YEAR FROM DATA), EXTRACT(MONTH FROM DATA) '
    + ' ORDER BY EXTRACT(YEAR FROM DATA) DESC, EXTRACT(MONTH FROM DATA) DESC ';
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('NUMERO')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('RICAVO')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idfatturato.value:=FormatFloat('#,##0.00', AWait(TotaleFatturato('')));
  idimponibile.value:=FormatFloat('#,##0.00', AWait(TotaleImponibile('')));
  idaggiornamento.value:=AWait(DataAggiornamento);

end;

// statica per cliente
procedure TFrmStatistiche.btn6_click;
begin

  console.log('btn6_click x2');
  if not DateOk then
    exit;

  QryGrid.Close;
  QryGrid.SQL.Text:='SELECT CLIENTE, '
    + ' COUNT(*) AS NUMERO, SUM(MEL_NMRICAVOSCONTATO) AS RICAVO '
    + ' FROM ORD_ORDINIMARKETING where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate
    + ' GROUP BY CLIENTE '
    + ' ORDER BY SUM(MEL_NMRICAVOSCONTATO) desc ';
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('NUMERO')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('RICAVO')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idfatturato.value:=FormatFloat('#,##0.00', AWait(TotaleFatturato('')));
  idimponibile.value:=FormatFloat('#,##0.00', AWait(TotaleImponibile('')));
  idaggiornamento.value:=AWait(DataAggiornamento);

end;

procedure TFrmStatistiche.idstatistica_change;
begin
  pnl1.Visible := True ;

  if idstatistica.selectedIndex = 1 then
     btn1_click
  else if idstatistica.selectedIndex = 2 then
     btn2_click
  else if idstatistica.selectedIndex = 3 then
     btn3_click
  else if idstatistica.selectedIndex = 4 then
     btn4_click
  else if idstatistica.selectedIndex = 5 then
     btn5_click
  else if idstatistica.selectedIndex = 6 then
     btn6_click;
end;

procedure TFrmStatistiche.cerca_change;
begin
  idstatistica_change;
end;

procedure TFrmStatistiche.data1_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  idstatistica_change;

end;

procedure TFrmStatistiche.data2_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  idstatistica_change;

end;

procedure TFrmStatistiche.pulisci_click;
begin
  cerca.value := '';
  cerca_change;
end;

function TFrmStatistiche.DateOk: Boolean;
var
  dt1, dt2: TDateTime;
  dt1str, dt2str :String;
begin
  Result :=  False;
  if data1.value = '' then
  begin
    ShowMessage('Compila la data da');
    data1.focus;
    exit;
  end;

  if data2.value = '' then
  begin
    ShowMessage('Compila la data a');
    data2.focus;
    exit;
  end;

//  window.localStorage[ClassName+'_data1'] := data1.value;
//  window.localStorage[ClassName+'_data2'] := data2.value;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value) ;
  dt2 := IncDay(dt2);
  dt1str := FormatDateTime('dd.MM.yyyy',dt1);
  dt2str := FormatDateTime('dd.MM.yyyy',dt2);

  whereDate := ' data >= ''' + dt1str + ''''
    + ' and data < ''' + dt2str + '''';


  Result := True;
end;


function TFrmStatistiche.TotaleFatturato(CampiFiltro: String): Double; async;
begin
  QryTotale.Close;
  QryTotale.SQL.Text:='Select (SUM(MEL_NMRICAVOSCONTATO) + SUM(SPEDIZIONE)) AS TOTALE '
    + ' from ord_ordinimarketing where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate;
  if (cerca.value <> '') and (CampiFiltro <> '') then QryTotale.Sql.add(SqlCerca(CampiFiltro));
  AWait(QryTotale.Open);

  Result := QryTotale.FieldByName('TOTALE').AsFloat;
end;

function TFrmStatistiche.TotaleImponibile(CampiFiltro: String): Double; async;
begin
  QryTotaleImponibile.Close;
  QryTotaleImponibile.SQL.Text:='Select (SUM(MEL_NMRICAVOSCONTATO) + SUM(SPEDIZIONE)) AS TOTALE '
    + ' from ord_ordinimarketing where mel_idsocieta = ''MEL001'''
    + ' and ' + whereDate;
  if (cerca.value <> '') and (CampiFiltro <> '') then QryTotaleImponibile.Sql.add(SqlCerca(CampiFiltro));
  AWait(QryTotaleImponibile.Open);

  Result := QryTotaleImponibile.FieldByName('TOTALE').AsFloat / 122 * 100;
end;

function TFrmStatistiche.DataAggiornamento: String; async;
begin

  QryMaxDataAggiornamento.Close;
  QryMaxDataAggiornamento.SQL.Text:='Select MAX(MEL_DTMODIFICA) AS DATAAGGIORNAMENTO from ord_ordinimarketing where mel_idsocieta = ''MEL001''';
  AWait(QryMaxDataAggiornamento.Open);

  Result := FormatDateTime('dd.mm.yyyy hh:nn:ss',QryMaxDataAggiornamento.FieldByName('DATAAGGIORNAMENTO').AsDateTime);

end;

initialization
  RegisterWeb(TFrmStatistiche);

end.
