unit f_produzioneinsertimporti;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmProduzioneInsertImporti }

  TFrmProduzioneInsertImporti = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idproduzione:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private

  published
    procedure ModificaDtVendita; async;
    QryProduzioneInsertImporti: TQuery;
    QryDtVendita: TQuery;
    QryControlloDataDuplicata: TQuery;
    QryAggiornaProduzione: TQuery;
    dtvendita : TJSHTMLInputElement;
    nmquantita: TJSHTMLSelectElement;
    nmprezzoacquisto: TJSHTMLSelectElement;
    nmprezzovendita: TJSHTMLSelectElement;
    idvalido: TJSHTMLSelectElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneInsertImporti }

procedure TFrmProduzioneInsertImporti.AfterRender;
var
  vend :String;
begin

  vend := DateToStr(date);
  vend := Copy(vend,7,4) +'-'+ Copy(vend,4,2) +'-'+ Copy(vend,1,2);
  console.log('data = ' + vend);
//  dtvendita.value := vend;

end;

procedure TFrmProduzioneInsertImporti.AfterDomAppend;
begin

//

end;

procedure TFrmProduzioneInsertImporti.salva_click(Event: TJSEvent);
var
  vend :String;
begin

  QryProduzioneInsertImporti.Close;
  QryProduzioneInsertImporti.SQL.Text:= 'select first 0 * from pro_valori ';
  AWait(QryProduzioneInsertImporti.Open);

//   controllo se i campi sono compilati
  vend := DateToStr(date);
  vend := Copy(vend,7,4) +'-'+ Copy(vend,4,2) +'-'+ Copy(vend,1,2);

  if dtvendita.value <= vend then
  begin
    ShowMessage('La scadenza non può essere inferiore o uguale ad oggi');
    Exit;
  end;

  if dtvendita.value = '' then
  begin
    ShowMessage('Devi inserire la data di vendita');
    Exit;
  end;
  if nmquantita.value = '' then
    begin
      ShowMessage('Devi inserire la quantità');
      Exit;
    end;
  if nmprezzoacquisto.value = '' then
    begin
      ShowMessage('Devi inserire il prezzo di acquisto');
      Exit;
    end;
  if nmprezzovendita.value = '' then
    begin
      ShowMessage('Devi inserire il prezzo di vendita');
      Exit;
    end;

// inserisco lo i valori
  QryProduzioneInsertImporti.Append;
  QryProduzioneInsertImporti.FieldByName('IDVALORE').AsInteger := await(GetNextId('PRODUZIONEVALORI'));
  QryProduzioneInsertImporti.FieldByName('IDPRODUZIONE').AsInteger := idproduzione;
  QryProduzioneInsertImporti.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryProduzioneInsertImporti.FieldByName('DTVENDITA').AsDateTime := StrToDate(Copy(dtvendita.value,9,2) +'/'+ Copy(dtvendita.value,6,2) +'/'+ Copy(dtvendita.value,1,4));
  QryProduzioneInsertImporti.FieldByName('NMQUANTITA').AsString := nmquantita.value;
  QryProduzioneInsertImporti.FieldByName('NMPREZZOACQUISTO').AsString := nmprezzoacquisto.value;
  QryProduzioneInsertImporti.FieldByName('NMPREZZOVENDITA').AsString := nmprezzovendita.value;
  if ((nmquantita.value <> '') and (nmprezzoacquisto.value <> '')
     and (nmprezzovendita.value <> '')) then
    begin
      QryProduzioneInsertImporti.FieldByName('nmtotalecosto').AsString := FloatToStr(StrToFloat(nmquantita.value)
         * StrToFloat(nmprezzoacquisto.value));
      QryProduzioneInsertImporti.FieldByName('nmtotalericavo').AsString := FloatToStr(StrToFloat(nmquantita.value)
        * StrToFloat(nmprezzovendita.value));
      QryProduzioneInsertImporti.FieldByName('nmtotaleutile').AsString := FloatToStr(QryProduzioneInsertImporti.FieldByName('nmtotalericavo').AsFloat -
        QryProduzioneInsertImporti.FieldByName('nmtotalecosto').AsFloat);
    end;
  QryProduzioneInsertImporti.FieldByName('flvalido').AsString := idvalido.value;

// controllo se il giorno è già stato inserito
  if idvalido.value = 'SI' then
    begin
      QryControlloDataDuplicata.Close;
      QryControlloDataDuplicata.SQL.Text:=' select * from pro_valori '
      + ' WHERE idproduzione = ' + IntToStr(idproduzione)
      + ' and dtvendita = ''' + dtvendita.value + '''';
      AWait(QryControlloDataDuplicata.Open);

      if QryControlloDataDuplicata.RecordCount > 0 then
        begin
          ShowMessage('I prezzi per questo giorno sono già stati inseriti...');
          exit;
       end;
    end;

  QryProduzioneInsertImporti.Post;
  await(QryProduzioneInsertImporti.ApplyUpdates);

  ModificaDtVendita;

  ShowMessage('Inserimento eseguito con successo');

  Close();

end;

procedure TFrmProduzioneInsertImporti.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

procedure TFrmProduzioneInsertImporti.ModificaDtVendita;
begin

  // cerco la data di vendita più vicina netta tabella prezzi
  QryDtVendita.Close;
  QryDtVendita.SQL.Text:= ' select min(dtvendita) as dtvendita from pro_valori '
  + ' WHERE idproduzione = ' + IntToStr(idproduzione)
  + ' AND flvalido = ''SI'' ';
  AWait(QryDtVendita.Open);

// aggiorno la data di vendita nella tabella produzione
  QryAggiornaProduzione.Close;
  QryAggiornaProduzione.SQL.Text:= ' select * from pro_produzione '
  + ' WHERE idproduzione = ' + IntToStr(idproduzione);
  AWait(QryAggiornaProduzione.Open);

  QryAggiornaProduzione.Edit;
  QryAggiornaProduzione.FieldByName('dtscadenza').AsString:=QryDtVendita.FieldByName('dtvendita').AsString;
  QryAggiornaProduzione.Post;
  await(QryAggiornaProduzione.ApplyUpdates);

end;

initialization
  RegisterWeb(TFrmProduzioneInsertImporti);

end.

