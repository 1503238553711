unit f_produzioneinsertprocesso;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmProduzioneInsertProcesso }

  TFrmProduzioneInsertProcesso = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idstato:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private

  published
    QryProcessi: TQuery;
    dsprocesso : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    idordine : TJSHTMLInputElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneInsertProcesso }

procedure TFrmProduzioneInsertProcesso.AfterRender;
begin

//

end;

procedure TFrmProduzioneInsertProcesso.AfterDomAppend;
begin

//

end;

procedure TFrmProduzioneInsertProcesso.salva_click(Event: TJSEvent);
begin

  QryProcessi.Close;
  QryProcessi.SQL.Text:= 'select * from pro_processi ';
  AWait(QryProcessi.Open);

// controllo se i campi sono compilati
  if idordine.value = '' then
    begin
      ShowMessage('Devi inserire l''ordine');
      Exit;
    end;
  if dsprocesso.value = '' then
    begin
      ShowMessage('Devi inserire il processo');
      Exit;
    end;
  if dsnote.value = '' then
    begin
      ShowMessage('Devi inserire le note');
      Exit;
    end;

// inserisco lo step
  QryProcessi.Append;
  QryProcessi.FieldByName('IDPROCESSO').AsInteger := await(GetNextId('PRODUZIONEPROCESSI'));
  console.log('idstato max = ' + IntToStr(idstato));
  QryProcessi.FieldByName('IDSTATO').AsString := IntToStr(idstato);
  QryProcessi.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryProcessi.FieldByName('IDORDINE').AsString := idordine.value;
  QryProcessi.FieldByName('DSPROCESSO').AsString := dsprocesso.value;
  QryProcessi.FieldByName('DSNOTE').AsString := dsnote.value;
  QryProcessi.Post;
  await(QryProcessi.ApplyUpdates);

  ShowMessage('Inserimento eseguito con successo');

  Close();

end;

procedure TFrmProduzioneInsertProcesso.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

initialization
  RegisterWeb(TFrmProduzioneInsertProcesso);

end.

