unit un_bindquery;

{$mode objfpc}

interface

uses
  SysUtils, Classes, un_tquery, com_url, sqldbrestdataset;

function NewTQuery(aOwner: TComponent): TQuery;
procedure BindQuery(ds: TSQLDBRestDataset; connectionName:String = '');

implementation

uses un_user;

procedure BindQuery(ds: TSQLDBRestDataset; connectionName:String = '');
var
  FConn: TSQLDBRestConnection;
begin
  if ds.Connection = nil then
    ds.Connection := TSQLDBRestConnection.Create(ds);

  FConn := ds.Connection;
  FConn.Name := 'FConn';
  if connectionName <> '' then
     connectionName:= '?DB=' + connectionName;
  FConn.CustomViewResourceName := 'customView' + connectionName;
  FConn.BaseURL := ComUrl.Services + 'db_rest_dispatcher';
  FConn.UserName := user.username;
  FConn.Password := user.password;

end;


var
  queryCount: integer;

function NewTQuery(aOwner: TComponent): TQuery;
begin
  Result := TQuery.Create(aOwner);
  Inc(queryCount);
  Result.Name := format('Query%d', [queryCount]);
  BindQuery(Result);
end;

end.


