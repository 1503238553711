unit f_sqlgenerica;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, un_hotkey, js, web, f_selezione;

type

  { TFrmSqlGenerica }

  TFrmSqlGenerica = class(TWidget)
  private
    procedure RefreshQuery; async;
  published
    procedure AfterRender; override;
    procedure btnFetch_click(Event: TJSEvent);
    procedure btnApplyUpdates_click(Event: TJSEvent); async;
    procedure idsqlgenerica_click; async;
    taSql: TJSHTMLTextAreaElement;
    idAlias: TJSHTMLInputElement;
    writable:TJSHTMLInputElement;
    btnApplyUpdates:TJSHTMLButtonElement;
    divApplyUpdates:TJSHTmlElement;
    btnFetch:TJSHTMLButtonElement;
    FQuery1: TQuery;
    tab1:TWTable;
  end;

implementation

uses un_bindquery;

{$R *.html}

{ TFrmSqlGenerica }

procedure TFrmSqlGenerica.AfterRender;
begin
  divApplyUpdates.Visible := writable.checked;
  writable.addEventListener('click',@RefreshQuery);
  THotkey.Create(ElementInstance).Add('CTRL-E',@RefreshQuery);
  tab1.RenderDataSet(nil);
end;

procedure TFrmSqlGenerica.btnFetch_click(Event: TJSEvent);
begin
  RefreshQuery;
  Event.preventDefault;
end;

procedure TFrmSqlGenerica.btnApplyUpdates_click(Event: TJSEvent);
begin
  btnApplyUpdates.Disabled:=True;
  Await(FQuery1.ApplyUpdates);
  btnApplyUpdates.Disabled:=False;
  Event.preventDefault;
end;

procedure TFrmSqlGenerica.idsqlgenerica_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select * from sqlgenerica ';
      AWait(QrySeleziona.Open);
      QrySeleziona.FieldByName('IDSQLGENERICA').Visible:= false;
      QrySeleziona.FieldByName('DTSQLGENERICA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      taSql.value := '';
      taSql.value := QrySeleziona.FieldByName('dssqlgenerica').AsString;
      idAlias.value:= QrySeleziona.FieldByName('DSALIAS').AsString;
    end;
  end;

end;

procedure TFrmSqlGenerica.RefreshQuery;
begin
//  btnFetch.Disabled:= true;
  divApplyUpdates.Visible := writable.checked;

  FQuery1.Close;
  BindQuery(FQuery1,idAlias.value);
  FQuery1.SQL.Text := taSql.Value;
  AWait(FQuery1.Open);
  tab1.Writable := writable.checked;
  tab1.RenderDataSet(FQuery1);
  btnFetch.Disabled:= False;
end;

initialization
  RegisterWeb(TFrmSqlGenerica);
end.
