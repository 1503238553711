unit un_hotkey;

{$mode objfpc}

interface

uses
  Classes, SysUtils, JS, Web;

type

  { THotkey }
  type TReferenceProc = reference to procedure;

  THotkey = class(TComponent)
  protected
    handlers: TJSMap;
    procedure DetectHotKey(ke: TJSEvent);
  public
    EnableLog:Boolean;
    constructor Create(element: TJSEventTarget);
    function AddRef(keys:String; callback:TReferenceProc):THotkey; overload;
    function Add(keys:String; callback:JSValue):THotkey; overload;
  end;

var
   HotkeyWindow : THotkey;

implementation

{ THotkey }

procedure THotkey.DetectHotKey(ke: TJSEvent);
var
  key: String;
  handle: JSValue;
  keh :TJSKeyboardEvent;
  kehKey :String;
begin
  if not (ke is TJSKeyboardEvent) then
    exit;
  keh := ke as TJSKeyboardEvent;
  key := '';
  if keh.ctrlKey then Key += 'CTRL-';
  if keh.shiftKey then Key += 'SHIFT-';
  if keh.altKey then Key += 'ALT-';
  if keh.metaKey then Key += 'META-';
  kehKey := keh.key;
  if kehKey.Length = 1 then
    kehKey := UpperCase(kehKey);
  Key += kehKey;

  if EnableLog then
    console.log(Key);

  handle := handlers.get(Key);
  if not Assigned(handle) then
    exit;

  TJSRawEventHandler(handle)(ke);
  ke.preventDefault;
  ke.stopPropagation;
end;

constructor THotkey.Create(element: TJSEventTarget);
begin
  inherited Create(nil);
  handlers := TJSMap.new;
  element.addEventListener('keydown', @DetectHotKey {,false});
end;

function THotkey.AddRef(keys: String; callback: TReferenceProc): THotkey;
begin
  handlers.&set(keys,callback);
  Result := self;
end;

function THotkey.Add(keys: String; callback: JSValue): THotkey;
begin
  handlers.&set(keys,callback);
  Result := self;
end;

initialization
  HotkeyWindow := THotkey.Create(TJSEventTarget(window));
end.
