unit w_label;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget;

type

  { TWLabel }

  TWLabel = class(TWidget)
  private
  published
    header: TJSHTMLElement;
    footer: TJSHTMLElement;
    labelbody: TJSHTMLLabelElement;
  end;

implementation

{$R *.html}


{ TWLabel }

initialization
  RegisterWeb(TWLabel);

end.

