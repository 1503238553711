unit un_servermodule;

{$mode objfpc}

interface

uses
  Classes, SysUtils, Web, Js, un_httprequest, com_url;

type

  { TJSObjectHelper }

  TJSObjectHelper = class helper for TJSObject
  private
    function GetStrings(AName : String): String;
    function GetText: String;
    procedure SetStrings(AName : String; AValue: String);
  public
  Property Strings[AName : String] : String Read GetStrings Write SetStrings;
  Property Text:String read GetText;

  end;

  { TServerModule }

  TServerModule = class(TComponent)
  protected
    FClazz: string;
    FParams: TStringList;
    FResponse: TJSObject;
    FBody: JSValue;
    FMethod: String;
    FProc: String;
    function GetUrl: String; virtual;
    function GetValue(const Name: string): string;
    function ParamsToUri: String;
    procedure SetBody(AValue: JSValue);
    procedure SetMethod(AValue: String);
    procedure SetProc(AValue: String);
    procedure SetValue(const Name: string; AValue: string);
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Response: TJSObject;

    function SetParamsArray(params: array of string): TServerModule;
    procedure Call(proc: string); async;
    property Params[const Name: string]: string read GetValue write SetValue;
    property Body:JSValue read FBody write SetBody;
    property Method:String read FMethod write SetMethod;
    property Proc:String read FProc write SetProc;
    property Url:String read GetUrl;
  end;


function ServerModule(clazz: string): TServerModule;

implementation

function ServerModule(clazz: string): TServerModule;
begin
  Result := TServerModule.Create(nil);
  Result.FClazz := clazz;
end;

{ TJSObjectHelper }

function TJSObjectHelper.GetStrings(AName : String): String;
begin
  Result := JS.toString( Properties[Aname] );
end;

function TJSObjectHelper.GetText: String;
begin
   Result := TJSJSON.stringify(self);
end;

procedure TJSObjectHelper.SetStrings(AName : String; AValue: String);
begin
  Properties[AName] := AValue;
end;

{ TServerModule }

constructor TServerModule.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
  FProc := 'Processa';
  FParams := TStringList.Create;
end;
function TServerModule.Response: TJSObject;
begin
  Result := FResponse;
end;

function TServerModule.SetParamsArray(params: array of string): TServerModule;
var
  i:Integer;
  name, value: String;
begin
  assert((length(params) mod 2) = 0, 'Il numero di valori deve essere pari');

  i := 0;

  while i < length(params) do begin
    name := params[i];
    value := params[i+1];
    FParams.Values[name] := value;
    i += 2;
  end;
  Result := Self;
end;

function TServerModule.GetValue(const Name: string): string;
begin
  Result := FParams.Values[Name];
end;

function TServerModule.GetUrl: String;
begin
  Result := format(ComUrl.Services + 'server_modules?class=%s&proc=%s', [FClazz, FProc]);
  Result += ParamsToUri;
end;

function TServerModule.ParamsToUri:String;
var
  FEnc: TStringList;
  i: Integer;
  aname, value: String;
begin
  Result := '';
  if FParams.Count=0 then
    exit;

  FEnc := TStringList.Create;
  FEnc.Delimiter:='&';
  FEnc.StrictDelimiter:=True;
  for i := 0 to FParams.Count-1 do
  begin
    aname := encodeURIComponent(FParams.Names[i]);
    value := encodeURIComponent(FParams.ValueFromIndex[i]);
    FEnc.Values[aname] := value;
  end;

  Result := '&' + FEnc.DelimitedText;
end;

procedure TServerModule.SetBody(AValue: JSValue);
begin
  if FBody=AValue then Exit;
  FBody:=AValue;
end;

procedure TServerModule.SetMethod(AValue: String);
begin
  if FMethod=AValue then Exit;
  FMethod:=AValue;
end;

procedure TServerModule.SetProc(AValue: String);
begin
  if FProc=AValue then Exit;
  FProc:=AValue;
end;

procedure TServerModule.SetValue(const Name: string; AValue: string);
begin
  FParams.Values[Name] := AValue ;
end;

procedure TServerModule.Call(proc: string); async;
var
  resp: string;
begin
  if proc<> '' then
    SetProc(proc);
  if Method = 'POST' then
    resp := AWait(fetch(Url,Body))
  else
    resp := AWait(fetch(Url));
  FResponse := toObject( TJSJSON.parse(resp) );
end;

end.
