unit f_menu2;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_servermodule, un_tquery, w_table,
  un_user;

type

  { TFrmMenu2 }

  TFrmMenu2 = class(TWidget)

  private
  published
    procedure AfterRender; override;
    procedure start; async;
    procedure tab1_rowclick;
    utente: TJSHTMLInputElement;
    societa: TJSHTMLInputElement;
    edrisposta: TJSHTMLInputElement;
    messaggio:TJSHTMLElement;
    tab1: TWTable;
    Fquery1: TQuery;

  end;

implementation

{$R *.html}


{ TFrmMenu2 }

procedure TFrmMenu2.AfterRender;
begin

//  utente.value := 'FDELBONO';
//  societa.value := 'MEL001';

  start;

end;

procedure TFrmMenu2.tab1_rowclick;
var
  meta: TWidgetMeta;
  instance: TWidget;
  dsForm: String;
begin
  console.log(FQuery1.FieldByName('DSTITOLO').AsString);
  dsForm := FQuery1.FieldByName('DSFORM').AsString;
  meta := GetWidgetMeta(dsForm);
  instance := meta.FClass.Create();
  instance.show;
end;

procedure TFrmMenu2.start;
var
  i: Integer;
begin

  with ServerModule('TWebMenu') do
  begin
    Params['utente'] := user.IdUtente;
    Params['societa'] := 'MEL001';
    await(Call('WebProcessa'));

    FQuery1.Close;
    FQuery1.SQL.Text:= Response.Strings['risposta'] ;
    AWait(FQuery1.Open);
    for i:=0 to FQuery1.FieldCount-1 do
      Fquery1.Fields[i].Visible:=false;
    FQuery1.FieldByName('DSTITOLO').Visible:=True;
    tab1.Header := false;
    tab1.RenderDataSet(FQuery1);
    messaggio.Visible := false;

  end;

end;

initialization
  RegisterWeb(TFrmMenu2);

end.

