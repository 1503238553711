unit f_simone;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, f_sqleditor, un_hotkey,
  un_httprequest, un_servermodule, f_upload, w_qrcode, w_edit, f_selezione, js,
  web;

type

  { TFrmSimone }

  TFrmSimone = class(TWidget)
    procedure AfterRender; override;
    sql: TFrmSqlEditor;
  private
  published
    procedure btnServerModule_click; async;
    procedure btnTFrmSeleziona_click; async;
    procedure taSql_click; async;
    procedure btnImportOrdiniMarketing_click; async;
    procedure btnUploadTest_click; async;
    procedure btn2_click; async;
    taSql: TJSHTMLTextAreaElement;
    FQuery1: TQuery;
    FQuery2: TQuery;
    tab1:TWTable;
    qrcode1:TWQRCode;
    link1:TJSHTMLAnchorElement;
    link2:TJSHTMLAnchorElement;
    link3:TJSHTMLAnchorElement;
//    edit1:TWEdit;
    procedure edit1_seleziona(event:TJSEvent);
    procedure edit1_elimina(event:TJSEvent);
    procedure edit1_click(event:TJSEvent);
    procedure edit2_seleziona(event: TJSEvent);
    procedure edit1_input(event: TJSEvent);
  end;

implementation

uses un_bindquery, un_executesql;

{$R *.html}

{ TFrmSimone }
procedure TFrmSimone.btnTFrmSeleziona_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;
      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select * from MA_ARTICOLI';
      AWait(QrySeleziona.Open);
      QrySeleziona.FieldByName('IDARTICOLO').Visible:= false;
      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;
    end;
    console.log('TFrmSimone.btnTFrmSeleziona_click before show');
    if AWait(Show) = mrOK then
    begin
      console.log('TFrmSimone.btnTFrmSeleziona_click mrOk');
      taSql.value := 'OK! selezionato IDARTICOLO=' + QrySeleziona.FieldByName('IDARTICOLO').AsString
      + ' DSARTICOLO=' + QrySeleziona.FieldByName('DSARTICOLO').AsString;
    end;


  end;
end;

procedure testHttpError;  async;
var
  res:String;
begin
  //res := AWait(fetch('https://x.jako.pro/ciccio'));
    res := AWait(fetch('/cicicco'));
//     res := AWait(fetch2('/services/server_modules?class=TWebTelemetria&proc=NonEsiste'));
//      res := AWait(fetch('https://httpbin.org/anything'));
  console.log('-----RES=' +res);

  with ServerModule('TWebTest') do
  begin
    Params['nome'] := format('n-%f',[Random]);
    await(Call('Processa2'));
    console.log( Response.Text );
  end;
end;


procedure TFrmSimone.AfterRender;
begin
  tab1.RenderDataSet(nil);
  HotkeyWindow.Add('F1',@btnServerModule_click);
  qrcode1.OnQRCodeAvailable := procedure
  begin
    console.log('ok, finalmente' + qrcode1.Data);
    qrcode1.StopScanning;
  end;

  with ServerModule('TWebTestDownload') do
  begin
    link1.innerHTML := 'Test download (nome dal server)';
    Params['nomeDownload'] := 'nome-dal-server1.pdf';
    link1.href:= Url;
    link1.target:= '_blank';
  end;

  with ServerModule('TWebTestDownload') do
  begin
    link2.innerHTML := 'Test download (nome dal server e forza download)';
    Params['nomeDownload'] := 'nome-dal-server2.pdf';
    Params['forzaDownload'] := 'true';
    link2.href:= Url;
  end;

  with ServerModule('TWebTestDownload') do
  begin
    link3.innerHTML := 'Test download (nome dal client)';
    link3.download :=  'client-test.pdf';
    link3.href:= Url;
  end;

end;
procedure TFrmSimone.btnServerModule_click;
begin
  with ServerModule('TWebTest') do
  begin
    Params['nome'] := format('n-%f',[Random]);
    await(Call('Processa'));
    taSql.value:= Response.Text;
  end;
end;

procedure TFrmSimone.btnImportOrdiniMarketing_click; async;
begin
  with ServerModule('TWebImportOrdiniMarketing') do
  begin
    Params['filename'] := 'C:\Melide\Ordini marketing\ordine-marketing_04-08-2020-10-41.csv';
    await(Call('Processa'));
    taSql.value:= Response.Text;
  end;
end;

procedure TFrmSimone.btnUploadTest_click;
begin
  console.log('---------before');
  with TFrmUpload.Create do
  begin
    console.log('---------after');
    RestartUpload := True;
    CloseOnComplete:= true;
    Title := 'Test da TFrmSimone' ;
    Message := 'Carica un file qualsiasi per fare il test';
    if await(Show) = mrOK then
      taSql.value:= format('Upload success!'#13'serverfilename=%s',[ServerFilename])
    else
      taSql.value := format('Upload failed',[])
      ;
  end;
end;

procedure TFrmSimone.btn2_click;
begin
  FQuery1.Close;
  FQuery1.SQL.Text:= 'select max(idmemorandum) from test4';
  await(FQuery1.Open);
  FQuery1.Tag:= FQuery1.Fields[0].AsInteger;
  FQuery1.Close;
  FQuery1.sql.Text := 'DELETE FROM TEST4 WHERE IDMEMORANDUM = ' + IntToStr(FQuery1.Tag);
  FQuery1.ExecuteSql;
  FQuery1.SQL.Text:= 'select * from test4 order by idmemorandum desc';
  await(FQuery1.Open);
  tab1.RenderDataSet(FQuery1);
end;

procedure TFrmSimone.edit1_seleziona(event: TJSEvent);
begin
  console.log(event);
end;

procedure TFrmSimone.edit1_elimina(event: TJSEvent);
begin
  console.log('');
end;

procedure TFrmSimone.edit1_click(event: TJSEvent);
begin
  console.log('TFrmSimone.edit1_click');
end;
procedure TFrmSimone.edit1_input(event: TJSEvent);
begin
  console.log('TFrmSimone.edit1_input');
end;

procedure TFrmSimone.edit2_seleziona(event: TJSEvent);
begin
  console.log(event);
end;

procedure TFrmSimone.taSql_click;
begin
  console.log('test count');
  FQuery1.Close;
  FQuery1.SQL.CommaText:= 'select max(idmemorandum) from test4';
  await(FQuery1.Open);
  FQuery1.Tag:= FQuery1.Fields[0].AsInteger;
  FQuery1.Close;
  FQuery1.SQL.Text:= 'select * from test4 where 1 = 0 ';
  await(FQuery1.Open);
  FQuery1.Append;
  FQuery1.FieldByName('IDMEMORANDUM').AsInteger:= FQuery1.Tag + 1;
  FQuery1.FieldByName('IDSOCIETA').AsString:= 'MEL001';
  FQuery1.Post;
  await(FQuery1.ApplyUpdates);
  tab1.RenderDataSet(FQuery1);
end;

initialization
  RegisterWeb(TFrmSimone);
end.
