unit f_produzionestati;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_produzioneprocessi;

type

  { TFrmProduzioneStati }

  TFrmProduzioneStati = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private

  published
    procedure tab1_rowclick;
    procedure RefreshStati;  async;
    procedure btninsert_click(Event: TJSEvent); async;
    procedure btnchiudiinsert_click(Event: TJSEvent); async;
    QryStati: TQuery;
    tab1:TWTable;
    idordine : TJSHTMLInputElement;
    dsstato : TJSHTMLInputElement;
    pnltab1:TJSHTMLElement;
    pnlinsert:TJSHTMLElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneStati }

procedure TFrmProduzioneStati.AfterRender;
begin

//

end;

procedure TFrmProduzioneStati.AfterDomAppend;
begin

  RefreshStati;

end;

procedure TFrmProduzioneStati.RefreshStati;
begin

  pnlinsert.Visible:=false;

  QryStati.Close;
  QryStati.SQL.Text := 'select * '
   + ' from pro_stati '
   + ' where flannullato = ''NO'' '
   + ' order by idordine ';
  AWait(QryStati.Open);

  QryStati.FieldByName('idstato').Visible:= false;
  QryStati.FieldByName('idsocieta').Visible:= false;
  QryStati.FieldByName('flannullato').Visible:= false;

  tab1.RenderDataSet(QryStati);

end;

procedure TFrmProduzioneStati.btninsert_click(Event: TJSEvent);
begin

  pnltab1.Visible:=false;
  pnlinsert.Visible:=true;

end;

procedure TFrmProduzioneStati.btnchiudiinsert_click(Event: TJSEvent);
begin

  pnltab1.Visible:=true;
  pnlinsert.Visible:=false;

end;

procedure TFrmProduzioneStati.salva_click(Event: TJSEvent);
begin

//   controllo se i campi sono compilati
    if dsstato.value = '' then
      begin
        ShowMessage('Devi inserire lo stato');
        Exit;
      end;

    QryStati.Append;
    QryStati.FieldByName('idstato').AsInteger := await(GetNextId('PRO_STATI'));
    QryStati.FieldByName('idsocieta').AsString := 'MEL001';
    QryStati.FieldByName('idordine').AsString := idordine.value;
    QryStati.FieldByName('dsstato').AsString := dsstato.value;
    QryStati.FieldByName('flannullato').AsString := 'NO';
    QryStati.Post;
    await(QryStati.ApplyUpdates);

    ShowMessage('Inserimento eseguito con successo');

    pnltab1.Visible:=true;
    pnlinsert.Visible:=false;

    RefreshStati;

end;

procedure TFrmProduzioneStati.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

procedure TFrmProduzioneStati.tab1_rowclick;
var
  instance: TFrmProduzioneProcessi;
begin

  instance := TFrmProduzioneProcessi.Create();
  instance.idstato := qrystati.FieldByName('idstato').AsInteger;
  instance.dsstato := qrystati.FieldByName('dsstato').AsString;
  instance.show;

end;

initialization
  RegisterWeb(TFrmProduzioneStati);

end.

