unit un_executesql;

{$mode objfpc}

interface

uses
  Classes, SysUtils;

function ExecuteSql(sql: string;database:String=''):Integer; async;

implementation

uses un_servermodule;

function ExecuteSql(sql: string;database:String=''):Integer; async;
begin
  with ServerModule('TWebExecuteSql') do
  begin
    Params['DATABASE'] := database;
    Params['SQL'] := sql;
    Await(Call('WebProcessa'));
    Result := StrToInt( Response.Strings['RECORD_COUNT'] );
  end;

end;

end.

