unit f_test1;

{$mode objfpc}

interface

uses
  JS, Classes, SysUtils, Web,
  un_tquery, DateUtils, un_bindquery,
  rtti, typinfo, un_widget, f_editbutton, w_table, f_sqleditor,db;

type
  { TFrmTest1 }
  TFrmTest1 = class(TWidget)
  private
    procedure query1Afterscroll(DataSet: TDataSet);
  published
    Datasource:TDataSource;
    FQuery1:TQuery;
    tab1:TWTable;
    procedure btn2_click; async;
    procedure AfterRender; override;
    procedure AfterRenderAsync; async;
  end;


type

{ THttpRequest }


implementation

{$R *.html}

procedure TFrmTest1.AfterRender;
begin
  AfterRenderAsync;
end;

procedure TFrmTest1.AfterRenderAsync;
  procedure refresh; async;
  begin
    FQuery1.Close;
    FQuery1.SQL.Text:= 'SELECT * FROM TEST3';
    AWait(FQuery1.Open);
  end;

begin
  await(Refresh);
  FQuery1.Append;
  FQuery1.FieldByName('NAME').AsString:= DateTimeToStr(now);
  FQuery1.Post;
  await(FQuery1.ApplyUpdates);
  await(Refresh);
exit;
  FQuery1.AfterScroll:=@query1Afterscroll;
  FQuery1.DisableControls;
  await( refresh );
  FQuery1.Locate('ID',7,[]);
  FQuery1.Edit;
  FQuery1.FieldByName('NAME').AsString:= DateTimeToStr(now);
  FQuery1.Post;
  AWait(FQuery1.ApplyUpdates);
  await( refresh );
  tab1.Writable:=true;
  tab1.RenderDataSet(FQuery1);
end;

procedure TFrmTest1.query1Afterscroll(DataSet: TDataSet);
begin
  console.log(format('afterscroll %d',[DataSet.RecNo]));
end;

procedure TFrmTest1.btn2_click;
begin
  AWait(FQuery1.ApplyUpdates);
end;

initialization
  RegisterWeb(TFrmTest1);

end.


