unit un_httprequest;

{$mode objfpc}

interface


uses
  Classes, SysUtils, Web;

type THttpRequest = class
   constructor Create;
   FXHR: TJSXMLHttpRequest;
   FSuccess:Boolean;
   FData:String;
   procedure LoadUrl(url:String);
  function onLoad(Event: TEventListenerEvent): boolean;
 end;

  function fetch(url: String;body:JSValue=nil): string; async;

implementation


function fetch(url: String;body:JSValue): string; async; assembler;
asm
  const settings = {
         method: 'GET',
         //,headers: {  Accept: 'application/json','Content-Type': 'application/json',}
    };
  if (body != null){
    settings.method = 'POST';
    settings.body = body;
  }
  let response = await fetch(url,settings).catch(function(error) {
    let msg = 'Errore nel contatto col server: ' ;
    console.log(msg);
    console.log(url);
    console.log(error);
    window.alert(msg + '\n\n' + 'url=[[' + url +']]\n' + error);
    throw new Error(msg + error);
  });
  let data = await response.text()
  if (!response.ok) {
    let msg = 'Il server ha restituito un errore:\n\n'
        + 'url=[[' + url +']]\n'
        + 'response.status=' +  response.status + '\n'
        + data;
    window.alert(msg);
    throw new Error(msg);
  }
  return data;
end;


constructor THttpRequest.Create;
begin
    FXHR:=TJSXMLHttpRequest.New;
    FXHR.AddEventListener('load',@onLoad);
end;

function THttpRequest.onLoad(Event{%H-}: TEventListenerEvent): boolean;
begin
  if (FXHR.Status=200) then
  begin
    FSuccess:=True;
    FData := FXHR.responseText;
    console.log('received data');
    console.log(Fdata);
  end  else    begin
    FSuccess:=False;
    //FErrorMsg:=FXHR.StatusText;
  end;
//  DoAfterRequest;
  Result:=True;
end;

procedure THttpRequest.LoadUrl(url: String);
begin
 FXHR.open('GET',url,true);
 FXHR.send;
end;

end.

