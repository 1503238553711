unit f_twedit;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_servermodule;

type

  { TFrmTWEdit }

  TFrmTWEdit = class(TWidget)
  private
    procedure logEvento(nome: string);
  published
    spanLog: TJSHTMLElement;
    procedure edit1_seleziona;
    procedure edit1_elimina;
    procedure edit1_change;
    procedure edit1_input;
    procedure edit1_focusin;
  end;

implementation

{$R *.html}


{ TFrmTWEdit }

procedure TFrmTWEdit.logEvento(nome: string);
begin
  console.log(nome);
  spanLog.innerHTML += '<br>' + nome;
end;

procedure TFrmTWEdit.edit1_seleziona;
begin
  logEvento('edit1_seleziona');
end;

procedure TFrmTWEdit.edit1_elimina;
begin
  logEvento('edit1_elimina');
end;

procedure TFrmTWEdit.edit1_change;
begin
  logEvento('edit1_change');
end;

procedure TFrmTWEdit.edit1_input;
begin
  logEvento('edit1_input');
end;

procedure TFrmTWEdit.edit1_focusin;
begin
  logEvento('edit1_focusin');
end;

initialization
  RegisterWeb(TFrmTWEdit);

end.

