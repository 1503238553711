unit f_produzioneanalisidettaglio;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table;

type

  { TFrmProduzioneAnalisiDettaglio }

  TFrmProduzioneAnalisiDettaglio = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    parametro1:String;
    parametro2:String;
    parametro3:String;
    idstato :String;

  private
    procedure RefreshAnalisiDettaglio; async;

  published
    procedure BtnChiudi_click; async;
    QryAnalisiDettaglio: TQuery;
    tab1:TWTable;

  end;

implementation

{$R *.html}


{ TFrmProduzioneAnalisiDettaglio }

procedure TFrmProduzioneAnalisiDettaglio.AfterRender;
begin

//

end;

procedure TFrmProduzioneAnalisiDettaglio.AfterDomAppend;
begin

  RefreshAnalisiDettaglio;

end;

procedure TFrmProduzioneAnalisiDettaglio.RefreshAnalisiDettaglio;
begin

  console.log('parte la query');
  QryAnalisiDettaglio.Close;
  QryAnalisiDettaglio.SQL.Text:= ' select val.dtvendita as scadenza, '
  + ' pro.dsprodotto ||''-''||pro.dsmodello as prodotto, pro.dsstato as stato, '
  + ' val.nmquantita as qta, val.nmprezzoacquisto as prezzoacq, '
  + ' val.nmprezzovendita as prezzovend, val.nmtotalecosto as costo, '
  + ' val.nmtotalericavo as ricavo, val.nmtotaleutile as utile '
  + ' from pro_produzione pro'
  + ' join pro_valori val on val.idproduzione = pro.idproduzione '
  + ' where 1 = 1 '
  + ' and dsstato <> ''CONCLUSO'' '
  + ' and val.flvalido = ''SI'' ';
  if idstato <> '' then
  begin
    QryAnalisiDettaglio.SQL.Text := QryAnalisiDettaglio.SQL.Text + ' and dsstato = ' + '''' + idstato + ''''
  end;
  if parametro2 = 'meseanno' then
    begin
      QryAnalisiDettaglio.SQL.Add(' and extract(month from val.dtvendita)||''/''|| extract(year from val.dtvendita) = ''' + parametro1 + '''');
    end;
  if parametro2 = 'stato' then
    begin
      QryAnalisiDettaglio.SQL.Add(' and dsstato = ''' + parametro1 + '''');
      QryAnalisiDettaglio.SQL.Add(' and extract(month from dtscadenza)||''/''|| extract(year from dtscadenza) = ''' + parametro3 + '''');
    end;
  AWait(QryAnalisiDettaglio.Open);
  tab1.RenderDataSet(QryAnalisiDettaglio);

end;

procedure TFrmProduzioneAnalisiDettaglio.BtnChiudi_click;
begin

  Close;

end;

initialization
  RegisterWeb(TFrmProduzioneAnalisiDettaglio);

end.

