unit f_avanzaordine;

{$mode objfpc}

interface

uses
  Classes, SysUtils, w_qrcode, un_widget, un_tquery, js, web;

type

  { TFrmAvanzaOrdine }

  TFrmAvanzaOrdine = class(TWidget)
//    FCounter:Integer;
    StatoAttuale :String;
    StatoSuccessivo :String;
    StatoPrecedente :String;
  public
    procedure AfterRender; override;
    procedure AfterClose; override;
    procedure OnQRCode;
    procedure AfterDomAppend; async; override;
    procedure CambiaStato; async;
  published
    qrcode1:TWQRCode;
    divLog:TJSHTMLElement;
    btnStartScanning:TJSHTMLButtonElement;
    btnStopScanning:TJSHTMLButtonElement;
    btnAvanzaStato:TJSHTMLButtonElement;
    btnRetrocediStato:TJSHTMLButtonElement;
    autoStart:TJSHTMLInputElement;
    autoStop:TJSHTMLInputElement;
    pnlstato:TJSHTMLElement;
    idstatoattuale : TJSHTMLInputElement;
    idstatosuccessivo : TJSHTMLInputElement;
    procedure btnStartScanning_click;
    procedure btnStopScanning_click;
    procedure btnAvanzaStato_click; async;
    procedure btnRetrocediStato_click; async;
    QryOrdine: TQuery;
    QryStati: TQuery;
  end;

implementation

{$R *.html}


{ TFrmAvanzaOrdine }

procedure TFrmAvanzaOrdine.AfterRender;
begin
  qrcode1.OnQRCodeAvailable := @OnQRCode;
  pnlstato.Visible := false;
end;

procedure TFrmAvanzaOrdine.AfterClose;
begin
  qrcode1.StopScanning;
end;

procedure TFrmAvanzaOrdine.OnQRCode;
begin
//  Inc(FCounter);
//  divLog.innerHTML:= format('Lettura %d; qrcode=[%s]',[FCounter, qrcode1.Data]);
  if autoStop.checked then
      btnStopScanning_click;

  CambiaStato;

end;

procedure TFrmAvanzaOrdine.AfterDomAppend;
begin
  if autoStart.checked then
    btnStartScanning_click;
end;

procedure TFrmAvanzaOrdine.CambiaStato;
begin

  pnlstato.Visible := true;

  QryOrdine.Close;
  QryOrdine.SQL.Text:= 'select * from ord_OrdiniMarketing '
  + ' where id_ordine = ' + qrcode1.Data;
  AWait(QryOrdine.Open);

  idstatoattuale.value := QryOrdine.FieldByName('mel_dsstato').AsString;
  StatoAttuale := idstatoattuale.value;

  QryStati.Close;
  QryStati.SQL.Text:= ' select * from ord_stati '
  + ' order by nmordine ';
  AWait(QryStati.Open);

  QryStati.Locate('dsstato', idstatoattuale.value, []);
  QryStati.Next;
  idstatosuccessivo.value := QryStati.FieldByName('dsstato').AsString;
  StatoSuccessivo:=idstatosuccessivo.value;

  QryStati.Prior;
  QryStati.Prior;
  StatoPrecedente:= QryStati.FieldByName('dsstato').AsString;

end;

procedure TFrmAvanzaOrdine.btnStartScanning_click;
begin
//  console.log('btnStartScanning_click');
//  FCounter := 0;
  qrcode1.StartScanning;
  btnStartScanning.hidden := true;
  btnStopScanning.hidden := false;

end;

procedure TFrmAvanzaOrdine.btnStopScanning_click;
begin
  console.log('btnStopScanning_click');
  qrcode1.StopScanning;
  btnStartScanning.hidden := false;
  btnStopScanning.hidden := true;
end;

procedure TFrmAvanzaOrdine.btnAvanzaStato_click;
begin

  QryOrdine.Edit;
  QryOrdine.FieldByName('mel_dsstato').AsString:=StatoSuccessivo;
  QryOrdine.Post;
  QryOrdine.ApplyUpdates;
  StatoAttuale:='';
  idstatoattuale.value := '';
  StatoSuccessivo:='';
  idstatosuccessivo.value := '';

  pnlstato.Visible := false;
  qrcode1.StartScanning;

end;

procedure TFrmAvanzaOrdine.btnRetrocediStato_click;
begin

  QryOrdine.Edit;
  QryOrdine.FieldByName('mel_dsstato').AsString:=StatoPrecedente;
  QryOrdine.Post;
  QryOrdine.ApplyUpdates;
  StatoAttuale:='';
  idstatoattuale.value := '';
  StatoSuccessivo:='';
  idstatosuccessivo.value := '';

  pnlstato.Visible := false;
  qrcode1.StartScanning;

end;

initialization
  RegisterWeb(TFrmAvanzaOrdine);
end.
