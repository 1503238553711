unit f_comp1;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery,
  DateUtils;

type

  { TFrmComp1 }

  TFrmComp1 = class(TWidget)
    procedure AfterRender;
    procedure btn1_click; async;
  published
    span1:TJSHTMLElement;
    input1:TJSHTMLInputElement;
    btn1:TJSHTMLButtonElement;
  end;

implementation

{$R *.html}

{ TFrmComp1 }

procedure TFrmComp1.AfterRender;
begin
  bind('btn1','click',@btn1_click);
end;

procedure TFrmComp1.btn1_click;
begin
  input1.value:= '';
end;

initialization
  RegisterWeb(TFrmComp1);

end.
