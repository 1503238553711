unit un_web;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  Classes, web,JS;

function atob(Data: string): string;
function sleep(ms:Integer): TJSPromise;

type TJSHTMLObjectElement = Class external name 'HTMLObjectElement' (TJSHTMLElement)
  private
  FContentDocument: TJSDocument; external name 'contentDocument';
  FContentWindow: TJSWindow; external name 'contentWindow';
  Public
  height : string;
  src : string;
  srcdoc : string;
  width : string;
  property contentWindow : TJSWindow Read FContentWindow;
  property contentDocument : TJSDocument Read FContentDocument;
  end;


implementation

function atob(Data: string): string; assembler;
asm
  return  atob(Data);
end;

function sleep(ms: Integer): TJSPromise; assembler;
asm
  return new Promise(resolve => setTimeout(resolve, ms));
end;

end.
