unit f_produzionemodificastep;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmProduzioneModificaStep }

  TFrmProduzioneModificaStep = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idproduzione:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private
    primaVolta :boolean;
  published
    procedure CompilaCampi; async;
    procedure dsresponsabile_click; async;
    procedure idstato_click; async;
    QrySeleziona: TQuery;
    QryProduzioneDettaglio: TQuery;
    QryProduzione: TQuery;
    QryControlloDataDuplicata: TQuery;
    dsstep : TJSHTMLInputElement;
    dsnextstep : TJSHTMLInputElement;
    dtscadenza : TJSHTMLInputElement;
    idresponsabile : TJSHTMLInputElement;
    dsresponsabile : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    idstato : TJSHTMLInputElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneModificaStep }

procedure TFrmProduzioneModificaStep.AfterRender;
begin

  idresponsabile.Visible:=false;
  primaVolta := true;

end;

procedure TFrmProduzioneModificaStep.AfterDomAppend;
begin

  if (idproduzione > 0) and primaVolta then
  begin
    Await(CompilaCampi);
  end;

  primaVolta := false;

end;

procedure TFrmProduzioneModificaStep.CompilaCampi;
begin

// precompilo il dati per la modifica dello step
  qryproduzionedettaglio.Close;
  qryproduzionedettaglio.SQL.Text:= ' select first 1 * from pro_produzionedettaglio '
                        + ' where idproduzione = ' + IntTostr(idproduzione )
                        + ' order by dtscadenza desc  ';
  AWait(qryproduzionedettaglio.Open);

  dtscadenza.value := Copy(qryproduzionedettaglio.FieldByName('dtscadenza').AsString,7,4) +'-'+
   Copy(qryproduzionedettaglio.FieldByName('dtscadenza').AsString,4,2) +'-'+
   Copy(qryproduzionedettaglio.FieldByName('dtscadenza').AsString,1,2);
  dsstep.value := qryproduzionedettaglio.FieldByName('dsnextstep').AsString;
  dsnextstep.value := qryproduzionedettaglio.FieldByName('dsnextstep').AsString;
  idstato.value := qryproduzionedettaglio.FieldByName('dsstato').AsString;
  idresponsabile.value := qryproduzionedettaglio.FieldByName('idresponsabile').AsString;
  dsresponsabile.value := qryproduzionedettaglio.FieldByName('dsresponsabile').AsString;
  dsnote.value := qryproduzionedettaglio.FieldByName('dsnote').AsString;

end;

procedure TFrmProduzioneModificaStep.salva_click(Event: TJSEvent);
var
  scad :String;
begin

//   controllo se i campi sono compilati
    scad := DateToStr(date);
    scad := Copy(scad,7,4) +'-'+ Copy(scad,4,2) +'-'+ Copy(scad,1,2);
    if dtscadenza.value <= scad then
      begin
        ShowMessage('La scadenza non può essere inferiore o uguale ad oggi');
        Exit;
      end;
    if dtscadenza.value = '' then
      begin
        ShowMessage('Devi inserire la scadenza');
        Exit;
      end;
    if idstato.value = '' then
      begin
        ShowMessage('Devi inserire lo stato');
        Exit;
      end;
    if dsstep.value = '' then
      begin
        ShowMessage('Devi inserire lo step');
        Exit;
      end;
    if dsnextstep.value = '' then
      begin
        ShowMessage('Devi inserire il next step');
        Exit;
      end;
    if idresponsabile.value = '' then
      begin
        ShowMessage('Devi inserire l''id del responsabile');
        Exit;
      end;
    if dsresponsabile.value = '' then
      begin
        ShowMessage('Devi inserire il reponsabile');
        Exit;
      end;
    if dsnote.value = '' then
      begin
        ShowMessage('Devi inserire le note');
        Exit;
      end;

    // controllo se il giorno è già stato inserito
      QryControlloDataDuplicata.Close;
      QryControlloDataDuplicata.SQL.Text:='select * from pro_produzionedettaglio '
      + ' where idsocieta = ''MEL001'' '
      + ' and idproduzione =  ' + QryProduzioneDettaglio.FieldByName('idproduzione').AsString
      + ' and dtscadenza = ''' + dtscadenza.value + '''';
      AWait(QryControlloDataDuplicata.Open);

      if QryControlloDataDuplicata.RecordCount > 0 then
        begin
          ShowMessage('Lo step per questo giorno è già stato inserito...');
          exit;
       end;

// modifico lo step
    QryProduzioneDettaglio.edit;
    QryProduzioneDettaglio.FieldByName('DSSTEP').AsString := dsstep.value;
    QryProduzioneDettaglio.FieldByName('DSNEXTSTEP').AsString := dsnextstep.value;
    QryProduzioneDettaglio.FieldByName('IDRESPONSABILE').AsString := idresponsabile.value;
    QryProduzioneDettaglio.FieldByName('DSRESPONSABILE').AsString := dsresponsabile.value;
    QryProduzioneDettaglio.FieldByName('DSNOTE').AsString := dsnote.value;
    QryProduzioneDettaglio.FieldByName('DSSTATO').AsString := idstato.value;
    QryProduzioneDettaglio.FieldByName('DTSCADENZA').AsDateTime := StrToDate(Copy(dtscadenza.value,9,2) +'/'+
      Copy(dtscadenza.value,6,2) +'/'+ Copy(dtscadenza.value,1,4));
    QryProduzioneDettaglio.Post;
    await(QryProduzioneDettaglio.ApplyUpdates);

    // aggiorno il campo stato nella tabella produzione
    QryProduzione.Close;
    QryProduzione.SQL.Text := 'select * from pro_produzione '
    + ' where idproduzione = ' +  QryProduzioneDettaglio.FieldByName('idproduzione').AsString;
    AWait(QryProduzione.Open);

    QryProduzione.Edit;
    QryProduzione.FieldByName('dsstato').AsString:=idstato.value;
    QryProduzione.FieldByName('dtnextscadenza').AsString:=QryProduzioneDettaglio.FieldByName('dtscadenza').AsString;
    QryProduzione.Post;
    await(QryProduzione.ApplyUpdates);

    ShowMessage('Inserimento eseguito con successo');

    Close();

end;

procedure TFrmProduzioneModificaStep.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

procedure TFrmProduzioneModificaStep.dsresponsabile_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcollaboratore as id, dscognome ||'' ''|| dsnome as dscollaboratore '
      + ' from collaboratori ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idresponsabile.value := QrySeleziona.FieldByName('id').AsString;
      dsresponsabile.value := QrySeleziona.FieldByName('dscollaboratore').AsString;
    end;

  end;
end;

procedure TFrmProduzioneModificaStep.idstato_click;
begin


  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dsstato as stato '
      + ' from pro_stati ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      idstato.value := QrySeleziona.FieldByName('stato').AsString;
    end;

  end;

end;

initialization
  RegisterWeb(TFrmProduzioneModificaStep);

end.

