program melindaweb;

{$mode objfpc}

uses
  Web, un_formatsettings, un_generic, un_start, un_commandline,
  un_commandlineinvoke, un_manager, un_hotkey, un_widget, un_error, un_web,
  un_servermodule, un_user, w_table, f_widgets, f_test1, f_editbutton, f_menu,
  f_statistiche, f_comp1, f_hotkey, f_simone, f_login, f_header, f_sqleditor,
  f_commesse, f_ore, f_selezione, f_memorandum, f_upload, f_qrcode, w_qrcode,
  com_url, UnArrayDef, f_massimo, f_gestioneordine, f_stampe, w_edit,
  un_telemetria, w_max1, f_test2, f_testsaluto, f_utiliti, f_ordinimarketing,
  f_menu2, f_ordini, f_ordinedettaglio, f_twedit, un_generator, w_label,
  f_avanzaordine, f_rimborsi, f_cassa, f_sqlgenerica, f_previsione,
  f_previsionedettaglio, f_produzione, f_produzionedettaglio,
  f_produzioneinsert, f_produzioneprodotti, f_produzionevalori,
  f_produzionecategorie, f_note, f_noteinserisci, un_dataset, f_notemodifica,
  f_notecategorie, f_produzionemodificastep, f_produzionestati,
  f_produzioneprocessi, f_produzioneinsertprocesso,
  f_produzionemodificaprocesso, f_produzioneanalisidettaglio, f_statistiche2,
  f_produzioneimportiprodotti, f_produzioneinsertimporti,
  f_produzionemodificaimporti, un_executesql, f_produzionemodificacategoria,
  sqldbrestdataset;

begin
  console.log('web-v1.8.1 compileTime=' + {$I %DATE%} + ' ' + {$I %TIME%} );
  un_start.start;
end.
