unit f_statistiche2;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, un_formatsettings,
  f_selezione, un_servermodule, f_upload, DateUtils, DB;

type

  { TFrmStatistiche2 }

  TFrmStatistiche2 = class(TWidget)
    whereDate :String;
    procedure AfterRender;
    procedure btnTFrmSeleziona_click; async;
    procedure pulisci_click;
    function DateOk:Boolean;
    function TotaleFatturato(CampiFiltro:String):Double; async;
    function TotaleImponibile(CampiFiltro:String):Double; async;
    function SqlCerca(CampiFiltro:String):String;
    procedure QryGridAddSqlCercaSe(CampiFiltro:String); async;
  published
    procedure btn1_click; async;
    procedure btn2_click; async;
    procedure btn3_click; async;
    procedure btn4_click; async;
    procedure btn5_click; async;
    procedure idstatistica_change;
    procedure cerca_change;
    procedure data1_change;
    procedure data2_change;
    btn1: TJSHTMLButtonElement;
    titolo: TJSHTMLElement;
    btn2: TJSHTMLButtonElement;
    btn3: TJSHTMLButtonElement;
    btn4: TJSHTMLButtonElement;
    btn5: TJSHTMLButtonElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    cerca: TJSHTMLInputElement;
    tab1: TWTable;
    idfatturato : TJSHTMLInputElement;
    idimponibile : TJSHTMLInputElement;
    idstatistica:  TJSHTMLSelectElement;
    QryGrid: TQuery;
    QryMaxDataAggiornamento: TQuery;
    QryTotale: TQuery;
    QryTotaleImponibile: TQuery;
    pnl1:TJSHTMLElement;
  end;

implementation

uses un_bindquery;

{$R *.html}

{ TFrmStatistiche2 }

procedure TFrmStatistiche2.AfterRender;
begin
  pnl1.Visible := false ;
  tab1.RenderDataSet(nil);

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

  idstatistica_change;

end;

procedure TFrmStatistiche2.btnTFrmSeleziona_click;
  var
  datamin, datamax :String;
begin

  // controllo se i campi sono compilati
  if data1.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data1.focus;
    exit;
  end;
  if data2.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data2.focus;
    exit;
  end;

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := False;

      QrySeleziona.Close;
      BindQuery(QrySeleziona,'MELIDE');
      QrySeleziona.SQL.Text:= ' select distinct po.id_order as id, po.date_add as data,'
      + ' CONCAT(pc.lastname, '' '', pc.firstname) as cliente, '
      + ' pod.product_name as prodotto ,pod.total_price_tax_excl as imponibile, '
      + ' pa.city as localita '
      + ' from ps_orders po '
      + ' join ps_order_detail pod on pod.id_order = po.id_order '
      + ' join ps_customer pc on pc.id_customer = po.id_customer  '
      + ' join ps_address pa on pa.id_customer = pc.id_customer and pa.id_address = po.id_address_delivery '
      + ' join ps_product_lang ppl on ppl.id_product = pod.product_id and ppl.id_lang = po.id_lang '
      + ' where ' + whereDate
      + ' order by po.date_add desc ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin

    end;
  end;
end;

function TFrmStatistiche2.SqlCerca(CampiFiltro: String): String;
begin
  result := ' and ('+ CampiFiltro + ' like ''%' + cerca.value.ToUpperInvariant + '%'' ) ';
end;

procedure TFrmStatistiche2.QryGridAddSqlCercaSe(CampiFiltro: String);
begin

  idfatturato.value:=FormatFloat('#,##0,00', AWait(TotaleFatturato(CampiFiltro)));
  idimponibile.value:=FormatFloat('#,##0,00', AWait(TotaleImponibile(CampiFiltro)));

  if cerca.value <> '' then QryGrid.Sql.Add(SqlCerca(CampiFiltro));

end;

// statistica per provincia
procedure TFrmStatistiche2.btn1_click;
begin

  console.log('btn1_click: rilancio BTN1');
  if not DateOk then
    exit;

  QryGrid.Close;
  BindQuery(QryGrid,'MELIDE');
  QryGrid.SQL.Text:='select CONCAT(pe.iso_code, ''_'', pa.city) as dove, '
    + ' count(id_order) as nr, '
    + ' sum(po.total_paid) as incass, '
    + ' sum(po.total_products) as impon, '
    + ' sum(po.total_paid_tax_excl) as impontrasp, '
    + ' (sum(po.total_products) / count(id_order)) as prezzomedio '
    + ' from ps_orders po '
    + ' join ps_address pa on pa.id_customer = po.id_customer  and pa.id_address = po.id_address_delivery '
    + ' join ps_country pe on pe.id_country = pa.id_country '
    + ' where 1 = 1 '
    + ' and ' + whereDate;
  Await(QryGridAddSqlCercaSe('upper(CONCAT(coalesce(pe.iso_code,''''), ''_'',coalesce(pa.city,'''')) )'));
  QryGrid.SQL.Add( ' group by CONCAT(pe.iso_code, ''_'', pa.city) ');
  QryGrid.SQL.Add( ' order by CONCAT(pe.iso_code, ''_'', pa.city) ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('incass')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impon')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impontrasp')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('prezzomedio')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idfatturato.value:=FormatFloat('#,##0.00', AWait(TotaleFatturato('')));
  idimponibile.value:=FormatFloat('#,##0.00', AWait(TotaleImponibile('')));

end;

// statistica per prodotto
procedure TFrmStatistiche2.btn2_click;
begin

  if not DateOk then
    exit;

  QryGrid.Close;
  BindQuery(QryGrid,'MELIDE');
  QryGrid.SQL.Text:=' select ppl.meta_title as prodotto, sum(pod.product_quantity) as qta, '
    + ' sum(pod.total_price_tax_incl) as imponeiva , sum(pod.total_price_tax_excl) as impon, '
    + ' (sum(pod.total_price_tax_excl) / sum(pod.product_quantity)) as prezzomedio '
    + ' from ps_order_detail pod '
    + ' join ps_orders po on po.id_order = pod.id_order '
    + ' join ps_product_lang ppl on ppl.id_product = pod.product_id  and ppl.id_lang = po.id_lang '
    + ' and ' + whereDate;
  Await(QryGridAddSqlCercaSe('upper(ppl.name)'));
  QryGrid.SQL.Add(' group by 1 ');
  QryGrid.SQL.Add(' order by sum(pod.product_quantity) desc ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('qta')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('imponeiva')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impon')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('prezzomedio')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idfatturato.value:=FormatFloat('#,##0.00', AWait(TotaleFatturato('')));
  idimponibile.value:=FormatFloat('#,##0.00', AWait(TotaleImponibile('')));

end;

// statistica per maglietta/ricamo
procedure TFrmStatistiche2.btn3_click;
begin
  if not DateOk then
    exit;

  QryGrid.Close;
  BindQuery(QryGrid,'MELIDE');
  QryGrid.SQL.Text:=' select ppl.meta_title as prodotto, sum(pod.product_quantity) as qta, '
    + ' sum(pod.total_price_tax_incl) as imponeiva , sum(pod.total_price_tax_excl) as impon, '
    + ' (sum(pod.total_price_tax_excl) / sum(pod.product_quantity)) as prezzomedio '
    + ' from ps_order_detail pod '
    + ' join ps_orders po on po.id_order = pod.id_order '
    + ' join ps_product_lang ppl on ppl.id_product = pod.product_id  and ppl.id_lang = po.id_lang '
    + ' and ' + whereDate
    + ' and ppl.meta_title like ''%T-shirt%'' ';
  Await(QryGridAddSqlCercaSe('upper(ppl.meta_title)'));
  QryGrid.SQL.Add(' group by 1 ');
  QryGrid.SQL.Add(' order by sum(pod.product_quantity) desc ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('qta')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('imponeiva')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impon')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('prezzomedio')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idfatturato.value:=FormatFloat('#,##0.00', AWait(TotaleFatturato('')));
  idimponibile.value:=FormatFloat('#,##0.00', AWait(TotaleImponibile('')));

end;

// statica per mese
procedure TFrmStatistiche2.btn4_click;
begin

// statistica per anno mese
  if not DateOk then
    exit;

  QryGrid.Close;
  BindQuery(QryGrid,'MELIDE');
  QryGrid.SQL.Text:=' select EXTRACT(YEAR FROM po.date_add) AS ANNO, EXTRACT(MONTH FROM po.date_add) AS MESE, '
    + ' sum(po.total_paid) as incass, '
    + ' sum(po.total_products) as impon, '
    + ' sum(po.total_paid_tax_excl) as impontrasp '
    + ' from ps_orders po '
    + ' where 1 = 1 '
    + ' and ' + whereDate
    + ' GROUP BY 1,2 '
    + ' ORDER BY 1,2 ';
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('incass')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impon')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impontrasp')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idfatturato.value:=FormatFloat('#,##0.00', AWait(TotaleFatturato('')));
  idimponibile.value:=FormatFloat('#,##0.00', AWait(TotaleImponibile('')));

end;

// statica per cliente
procedure TFrmStatistiche2.btn5_click;
begin

  console.log('btn5_click x2');
  if not DateOk then
    exit;

  QryGrid.Close;
  BindQuery(QryGrid,'MELIDE');
  QryGrid.SQL.Text:=' select CONCAT(pc.lastname, '' '', pc.firstname) as cliente, '
    + ' count(po.id_order) as nr, '
    + ' sum(po.total_paid) as incass, '
    + ' sum(po.total_products) as impon, '
    + ' sum(po.total_paid_tax_excl) as impontrasp,  '
    + ' (sum(po.total_products) / count(po.id_order)) as prezzomedio '
    + ' from ps_orders po '
    + ' join ps_customer pc on pc.id_customer = po.id_customer '
    + ' where 1 = 1 '
    + ' and ' + whereDate;
  Await(QryGridAddSqlCercaSe('upper(CONCAT(pc.lastname, '' '', pc.firstname))'));
  QryGrid.SQL.Add(' group by CONCAT(pc.lastname, '' '', pc.firstname) ');
  QryGrid.SQL.Add(' order by sum(po.total_paid) desc ');
  AWait(QryGrid.Open);
  TNumericField(QryGrid.FieldByName('incass')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impon')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('impontrasp')).DisplayFormat:='#,##0.00';
  TNumericField(QryGrid.FieldByName('prezzomedio')).DisplayFormat:='#,##0.00';
  tab1.RenderDataSet(QryGrid);

  idfatturato.value:=FormatFloat('#,##0.00', AWait(TotaleFatturato('')));
  idimponibile.value:=FormatFloat('#,##0.00', AWait(TotaleImponibile('')));

end;

procedure TFrmStatistiche2.idstatistica_change;
begin

  pnl1.Visible := True ;

  if idstatistica.selectedIndex = 1 then
     btn1_click
  else if idstatistica.selectedIndex = 2 then
     btn2_click
  else if idstatistica.selectedIndex = 3 then
     btn3_click
  else if idstatistica.selectedIndex = 4 then
     btn4_click
  else if idstatistica.selectedIndex = 5 then
     btn5_click

end;

procedure TFrmStatistiche2.cerca_change;
begin
  idstatistica_change;
end;

procedure TFrmStatistiche2.data1_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  idstatistica_change;

end;

procedure TFrmStatistiche2.data2_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  idstatistica_change;

end;

procedure TFrmStatistiche2.pulisci_click;
begin
  cerca.value := '';
  cerca_change;
end;

function TFrmStatistiche2.DateOk: Boolean;
var
  dt1, dt2: TDateTime;
  dt1str, dt2str :String;
begin
  Result :=  False;
  if data1.value = '' then
  begin
    ShowMessage('Compila la data da');
    data1.focus;
    exit;
  end;

  if data2.value = '' then
  begin
    ShowMessage('Compila la data a');
    data2.focus;
    exit;
  end;

//  window.localStorage[ClassName+'_data1'] := data1.value;
//  window.localStorage[ClassName+'_data2'] := data2.value;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value) ;
  dt2 := IncDay(dt2);
//  dt1str := FormatDateTime('dd.MM.yyyy',dt1);
//  dt2str := FormatDateTime('dd.MM.yyyy',dt2);
  dt1str := FormatDateTime('yyyy.MM.dd',dt1);
  dt2str := FormatDateTime('yyyy.MM.dd',dt2);

  whereDate := ' po.date_add >= ''' + dt1str + ''''
    + ' and po.date_add < ''' + dt2str + '''';

  Result := True;

end;


function TFrmStatistiche2.TotaleFatturato(CampiFiltro: String): Double; async;
begin

  console.log('Totale fatturato: rilancio TOTALE FATTURATO');

  QryTotale.Close;
  BindQuery(QryTotale,'MELIDE');
  QryTotale.SQL.Text:=' select sum(po.total_paid) as incass '
    + ' from ps_orders po '
    + ' where 1 = 1 '
    + ' and ' + whereDate;
//  if (cerca.value <> '') and (CampiFiltro <> '') then QryTotale.Sql.add(SqlCerca(CampiFiltro));
  AWait(QryTotale.Open);

  Result := QryTotale.FieldByName('incass').AsFloat;

end;

function TFrmStatistiche2.TotaleImponibile(CampiFiltro: String): Double; async;
begin

  QryTotale.Close;
  BindQuery(QryTotale,'MELIDE');
  QryTotale.SQL.Text:=' select sum(po.total_paid_tax_excl) as impontrasp '
    + ' from ps_orders po '
    + ' where 1 = 1 '
    + ' and ' + whereDate;
//  if (cerca.value <> '') and (CampiFiltro <> '') then QryTotale.Sql.add(SqlCerca(CampiFiltro));
  AWait(QryTotale.Open);

  Result := QryTotale.FieldByName('impontrasp').AsFloat;

end;

initialization
  RegisterWeb(TFrmStatistiche2);

end.
