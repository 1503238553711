unit f_note;

{$mode objfpc}

interface

uses
  Classes, SysUtils, strutils, js, web, un_widget, un_tquery, w_table,
  f_noteinserisci, f_notemodifica, DateUtils, f_selezione, w_edit, un_servermodule, w_label,
  un_telemetria, UnArrayDef, DB, un_generator, f_notecategorie, un_user;

type

  TVisibilita = (visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso);
const
  limiteRecord = 300;
  msgLimiteRecord = 'Sono riportati solo gli ultimi 300 prodotti' ;
  msgDateErrate = 'Correggi le date corrette per visualizzare gli prodotti';
  msgNessunRecord = 'Nessun record da visualizzare';
  msgCaricamentoInCorso = 'Caricamento prodotti in corso...';

type
  { TFrmNote }

  TFrmNote = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
  private
    whereDate :String;
    dt1str, dt2str :String;
    function DateOk:Boolean;
    procedure ShowNote; async;
    procedure qrynoteOnFilterRecord(DataSet: TDataSet; var Accept: Boolean);
    procedure RefreshFiltro;
    procedure SetLabel(lbl: TWLabel; value: String);
    procedure ImpostaVisibilita(visibilita:TVisibilita);
    procedure SetLabelStampa;
  published
    procedure tab1_rowclick; async;
    procedure filtro_change;
    procedure filtro_input;
    procedure data1_change;
    procedure data2_change;
    procedure dscategoria_change;
    procedure stampa1_click(event: TJSEvent); async;
    procedure flchiusa_click; async;
    procedure dscategoria_click; async;
    procedure lblHeader_click; async;
    procedure nuovanota_click; async;
    procedure btnCategorie_click; async;
    stampa1 :TJSHTMLButtonElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    dscategoria: TJSHTMLSelectElement;
    filtro: TWEdit;
    pnltab1: TJSHTMLElement;
    qrynote: TQuery;
    tab1:TWTable;
    labelTop:TWLabel;
    labelBottom:TWLabel;
    pnlTabella:TJSHTMLElement;
    pnlstampa:TJSHTMLElement;
    flchiusa:TJSHTMLInputElement;
  end;

implementation

{$R *.html}

{ TFrmNote }

procedure TFrmNote.AfterRender;
var
  dataminfiltro : String;
  datamaxfiltro : String;
begin

  // imposto la data iniziale del filtro
  dataminfiltro := DateToStr(date - 1000);
  datamaxfiltro := DateToStr(date + 1000);

  data1.value := Copy(dataminfiltro,7,4) +'-'+
         Copy(dataminfiltro,4,2) +'-'+
         Copy(dataminfiltro,1,2);
  data2.value := Copy(datamaxfiltro,7,4) +'-'+
         Copy(datamaxfiltro,4,2) +'-'+
         Copy(datamaxfiltro,1,2);

  pnlstampa.Visible:=false;

  qrynote.OnFilterRecord := @qrynoteOnFilterRecord;

  filtro.placeholder := 'Ricerca note...';
  filtro.seleziona1.Visible:= false;

  labelTop.header.innerHTML:= '<br><br>';
  labelTop.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 18px; font-weight: bold; border-style: solid;';
  labelTop.footer.innerHTML:= '<br><br>';

  labelBottom.labelbody.innerHTML:= 'bottom';
  labelBottom.header.innerHTML:= '<br>';
  labelBottom.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 22px; font-weight: bold; border-style: solid;';

//  data1.value := window.localStorage[ClassName+'_data1'] ;
//  data2.value := window.localStorage[ClassName+'_data2'] ;

  flchiusa.checked:=true;

  ShowNote;

end;

procedure TFrmNote.AfterDomAppend;
begin

//

end;

procedure TFrmNote.ShowNote; async;
begin

  if not DateOk then
  begin
    ImpostaVisibilita(visDateErrate);
    exit;
  end;

  ImpostaVisibilita(visCaricamentoInCorso);

  QryNote.Close;
  QryNote.SQL.Text:= ' select * from no_note  '
                     + ' where ' + whereDate
                     + ' and idutente = ' + '''' + user.QryUtenti.FieldByName('idutente').AsString + '''';

  if dscategoria.value <> 'TUTTE LE CATEGORIE' then
    begin
      QryNote.SQL.Text := QryNote.SQL.Text + ' and dscategoria = ' + '''' + dscategoria.value + ''''
    end;

  if flchiusa.checked then
    begin
      QryNote.SQL.Text := QryNote.SQL.Text + ' and flstato <> ''CHIUSA'' '
    end;

  QryNote.SQL.Text := QryNote.SQL.Text + ' Order by dtdata ';
  AWait(QryNote.Open);

  QryNote.FieldByName('idnota').Visible:= false;
  QryNote.FieldByName('idsocieta').Visible:= false;
  QryNote.FieldByName('dtdata').Visible:= false;
  QryNote.FieldByName('dscategoria').Visible:= false;
  QryNote.FieldByName('flstato').Visible:= false;

  RefreshFiltro;

end;

procedure TFrmNote.qrynoteOnFilterRecord(DataSet: TDataSet;
  var Accept: Boolean);
var
  str:String;
  i: Integer;
begin
  Accept:= true;
  str := filtro.value.ToLowerInvariant;
  for i:= 0 to DataSet.FieldCount-1 do
  begin
    if DataSet.Fields[i].Visible and
       DataSet.Fields[i].DisplayText.ToLowerInvariant.Contains(str) then
         exit;
  end;
  //nessun campo matcha la stringa cercata
  Accept := false;
end;

procedure TFrmNote.SetLabel(lbl:TWLabel;value:String);
begin

  lbl.Visible :=   value <> '' ;
  lbl.labelbody.innerHTML := value;

end;

procedure TFrmNote.SetLabelStampa;
var
  RecordCount: Integer;
begin

  if not qrynote.Active then
    exit;

  RecordCount := 0;
  qrynote.First;
  while not qrynote.EOF do
  begin
    Inc(RecordCount);
    qrynote.Next;
  end;
  stampa1.innerHTML:= format('Stampa (%d %s)'
      ,[RecordCount, IfThen(RecordCount = 1,'ordine','ordini') ]);

end;

procedure TFrmNote.tab1_rowclick;
var
  instance: TFrmNoteModifica;
begin

  instance := TFrmNoteModifica.Create();
  instance.idnota := qrynote.FieldByName('idnota').AsInteger;
  Await(instance.show);
  ShowNote;

end;

procedure TFrmNote.nuovanota_click;
var
  instance: TFrmNoteInserisci;
begin

  instance := TFrmNoteInserisci.Create();
  Await(instance.show);
  ShowNote;

end;

procedure TFrmNote.btnCategorie_click;
var
  instance: TFrmNoteCategorie;
begin

  instance := TFrmNoteCategorie.Create();
  instance.show;

end;

procedure TFrmNote.ImpostaVisibilita(visibilita: TVisibilita);
begin

  //visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso
  SetLabelStampa;
  SetLabel(labelTop,'');
  SetLabel(labelBottom,'');

  if visibilita = visDateErrate then SetLabel(labelTop,msgDateErrate);
  if visibilita = visNessunRecord then SetLabel(labelBottom,msgNessunRecord);
  if visibilita = visLimiteRecord then SetLabel(labelBottom,msgLimiteRecord);
  if visibilita = visCaricamentoInCorso then SetLabel(labelBottom,msgCaricamentoInCorso);

  filtro.Visible :=  visibilita <> visDateErrate;
//  console.log(format(' filtro.Visible := %s',[BoolToStr(filtro.Visible,true)]));
  pnltab1.Visible:= visibilita in [visLimiteRecord,visCiSonoRecord];

end;

procedure TFrmNote.RefreshFiltro;
begin

  qrynote.Filtered:= filtro.value.Trim <> '';
  qrynote.First;
  tab1.RenderDataSet(qrynote);

  if qrynote.IsEmpty then
    ImpostaVisibilita(visNessunRecord)
  else if qrynote.RecordCount = limiteRecord  then
    ImpostaVisibilita(visLimiteRecord)
  else
    ImpostaVisibilita(visCiSonoRecord);

end;

procedure TFrmNote.filtro_change;
begin

  RefreshFiltro;

end;

procedure TFrmNote.filtro_input;
begin

  RefreshFiltro;

end;

procedure TFrmNote.data1_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;

  ShowNote;

end;

procedure TFrmNote.data2_change;
begin

  window.localStorage[ClassName+'_data2'] := data2.value;

  ShowNote;

end;

procedure TFrmNote.dscategoria_change;
begin

  ShowNote;

end;

procedure TFrmNote.stampa1_click(event: TJSEvent);
var
  sql, clausola: String;
begin

  with ServerModule('TWebStampe') do
  begin
    Params['idStampa'] := '81';
    AWait(Call('WebParametri'));
    sql := Response.Strings['SQL'];
  end;

  clausola := format( 'AND O.ID_ORDINE IN (%s)',[ ToCommaText(qrynote,'ID_ORDINE',true) ]);
  sql := SysUtils.StringReplace(sql,'--AND1',clausola,[]);

  with ServerModule('TWebStampe') do
  begin
    Proc := 'WebProcessa';
    Params['idStampa'] := '81';
    Params['sql'] := sql;
    Params['DATADA'] := dt1str;
    Params['DATAA'] :=  dt2str;
    window.open(Url,'_blank');
  end;
  TmRegistraEvento('stampa_ordini',format('%s ~ %s|stato=%s|filtro=%s'
  ,[data1.value,data2.value,dscategoria.value,filtro.Value]));
end;

procedure TFrmNote.flchiusa_click;
begin

  ShowNote;

end;

procedure TFrmNote.dscategoria_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dscategoria as categoria '
      + ' from no_categorie ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      dscategoria.value := QrySeleziona.FieldByName('categoria').AsString;
      Await(ShowNote);
    end;

  end;

end;

procedure TFrmNote.lblHeader_click;
begin

  ShowNote;

end;

function TFrmNote.DateOk: Boolean;
var
  dt1, dt2: TDateTime;
begin

  Result :=  false;

  if (data1.value = '') or (data2.value = '')
    or (data1.value > data2.value) then
  begin
    SetLabel(labelTop,msgDateErrate);
    SetLabel(labelBottom,'');
    exit;
  end;

  Result :=  true;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value) ;
  dt2 := IncDay(dt2);

// questo formato date serve per la query note
  dt1str := FormatDateTime('dd.MM.yyyy',dt1);
  dt2str := FormatDateTime('dd.MM.yyyy',dt2);

  whereDate := ' dtdata >= ''' + dt1str + ''''
    + ' and dtdata <= ''' + dt2str + '''';

// questo formato di date serve alla stampa
  dt1str := FormatDateTime('dd/MM/yyyy',dt1) + ' 00:00:00';
  dt2str := FormatDateTime('dd/MM/yyyy',dt2) + ' 00:00:00';

end;

initialization
  RegisterWeb(TFrmNote);

end.

