unit un_manager;

{$mode objfpc}

interface

uses
  Classes, SysUtils, Web, un_widget, un_generic, un_telemetria, un_user;

type

  { THolderBase }

  THolderBase = class(TWidget)
  protected
    widgets: TWidgetList;
    procedure closeCurrent; async; virtual;
  public
    constructor Create; reintroduce;
    NotifyNewPushState: TRefProc;
  end;

type

  { TWidgetManager }

  TWidgetManager = class(THolderBase)
     procedure Show(widget: TWidget); async; override;
     procedure Close(widget: TWidget); async; override;
     procedure closeCurrent; async; override;
  end;

var
  manager:TWidgetManager;

  {
  open class WidgetManager : HolderBase() {

    val L by logger()

    override fun show(widget: ResourceWidget) {
        if (widgets.size > 0 && widgets.last() != widget)
            notifyNewPushState()

        widgets.remove(widget)
        widgets.add(widget)
        elementInstance.children.asList().forEach { it.remove() }
        widget.callBeforeShow()
        elementInstance.append(widget.elementInstance)
        widget.callAfterShow()
    }

    override fun close(widget: ResourceWidget) {
        if (widgets.size == 0) return
        if (widgets.last() != widget) {
            L.debug("${widget.instanceName} is not entitled to close()")
            return //throw Exception("$instanceName wtf!?");
        }

        widgets.removeAt(widgets.size - 1)
        if (widgets.size > 0) show(widgets.last())
    }

    override fun closeCurrent() {
        if (widgets.size == 1) return
        widgets.last().close()
    }

    fun remove(widget: KClass<out ResourceWidget>) {
        val matches = widgets.filter { it::class == widget }
        matches.forEach { widgets.remove(it) }
    }

}

}

implementation

{$R *.html}

{ TWidgetManager }

procedure TWidgetManager.Show(widget: TWidget);
var
  list: TJSHTMLElementArray;
  ele: TJSHTMLElement;
begin
  if not widgets.IsEmpty and (widgets.Last <> widget) then
    notifyNewPushState();
  widgets.Remove(widget);
  widgets.Add(widget);
  list := ElementInstance.children.AsList;
  for ele in list do
  begin
     asm
     ele.remove();
     end;
  end;
  if user.AuthOk then
    TmRegistraEvento('un_manager.show',widget.ClassName);
//  widget.callBeforeShow()
  elementInstance.append(widget.elementInstance) ;
  AWait(widget.AfterDomAppendInternal);
  //widget.callAfterShow()
end;

procedure TWidgetManager.Close(widget: TWidget);
begin
  if widgets.Size = 0 then exit;
  if widgets.Last() <> widget then
  begin
      console.log(format('%s is not entitled to close()',[widget.Name]));
      exit; //throw Exception("$instanceName wtf!?");
  end;

  widgets.removeAt(widgets.size - 1);
  if widgets.size > 0 then Await( Show(widgets.last()) )
end;

procedure TWidgetManager.closeCurrent;
begin
  if (widgets.size = 1) then
    exit;
  Await(widgets.last().close());
end;

{ THolderBase }

constructor THolderBase.Create;
begin
  inherited Create;
  widgets := TWidgetList.Create;
  notifyNewPushState:= procedure begin end;
end;

procedure THolderBase.closeCurrent;
begin

end;

initialization
  RegisterWeb(TWidgetManager);

end.
