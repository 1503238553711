unit f_testsaluto;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_servermodule;

type

  { TFrmTestSaluto }

  TFrmTestSaluto = class(TWidget)
    procedure AfterRender; override;
    procedure btnInvia_click; async;
  private

  published
    btnInvia: TJSHTMLButtonElement;
    ednome: TJSHTMLInputElement;
    edrisposta: TJSHTMLInputElement;

  end;

implementation

{$R *.html}


{ TFrmTestSaluto }

procedure TFrmTestSaluto.AfterRender;
begin
  //
end;

procedure TFrmTestSaluto.btnInvia_click;
begin

  with ServerModule('TWebTestSaluto') do
  begin
    Params['persona'] := ednome.value;
    await(Call('Processa'));
    edrisposta.value := Response.Strings['risposta '];
  end;

end;

initialization
  RegisterWeb(TFrmTestSaluto);

end.

