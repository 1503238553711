unit f_memorandum;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, un_formatsettings,
  un_user, f_selezione, DateUtils, DB, un_servermodule, un_generator;

type

  { TFrmMemorandum }

  TFrmMemorandum = class(TWidget)
    dt1, dt2: TDateTime;
    procedure salva_click(Event: TJSEvent); async;

  published
    procedure btnTFrmSeleziona_click; async;
    procedure dsemail_click; async;
    procedure idmemorandum_click; async;
    dtscadenza : TJSHTMLInputElement;
    dsemail : TJSHTMLInputElement;
    dsdescrizione : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    idmemorandum : TJSHTMLInputElement;
    dsstato : TJSHTMLInputElement;
    salva:TJSHTMLButtonElement;
    taSql: TJSHTMLTextAreaElement;
    qryMemorandum: TQuery;
  end;

implementation

{$R *.html}

{ TFrmMemorandum }

procedure TFrmMemorandum.salva_click(Event: TJSEvent);
begin

  Console.log('salva_click x2');

  QryMemorandum.Close;
  QryMemorandum.SQL.Text := 'select * from memorandum';
  AWait(QryMemorandum.Open);

   if idmemorandum.value <> '' then
    begin
      if not QryMemorandum.Locate('idmemorandum', idmemorandum.value, []) then
        begin
          ShowMessage('Devi inserire un id memorandum corretto');
        end;
      QryMemorandum.Edit;
      if dsstato.value <> '' then QryMemorandum.FieldByName('DSSTATO').AsString := dsstato.value;
      if dsdescrizione.value <> '' then QryMemorandum.FieldByName('DSDESCRIZIONE').AsString := dsdescrizione.value;
      if dsnote.value <> '' then QryMemorandum.FieldByName('DSNOTE').AsString := dsnote.value;
      if dsemail.value <> '' then QryMemorandum.FieldByName('DSEMAIL').AsString := dsemail.value;
      if dtscadenza.value <> '' then
        begin
          QryMemorandum.FieldByName('DTSCADENZA').AsDateTime := StrToDate(Copy(dtscadenza.value,9,2) +'/'+
           Copy(dtscadenza.value,6,2) +'/'+ Copy(dtscadenza.value,1,4));
        end;
    end
  else
    begin
        // controllo se i campi sono compilati
      if dtscadenza.value = '' then
        ShowMessage('Devi inserire la scadenza');
      if dsemail.value = '' then
        ShowMessage('Devi inserire l''email');
      if dsdescrizione.value = '' then
        ShowMessage('Devi inserire la descrizione');

      QryMemorandum.Append;
      QryMemorandum.FieldByName('IDMEMORANDUM').AsInteger := await(GetNextId('MEMORANDUM'));
      console.log('leggo il campo id: ' +  QryMemorandum.FieldByName('IDMEMORANDUM').AsString );
      QryMemorandum.FieldByName('DSSTATO').AsString := dsstato.value;
      QryMemorandum.FieldByName('DSDESCRIZIONE').AsString := dsdescrizione.value;
      QryMemorandum.FieldByName('DSNOTE').AsString := dsnote.value;
      QryMemorandum.FieldByName('DSEMAIL').AsString := dsemail.value;

      QryMemorandum.FieldByName('DTSCADENZA').AsDateTime := StrToDate(Copy(dtscadenza.value,9,2) +'/'+
        Copy(dtscadenza.value,6,2) +'/'+ Copy(dtscadenza.value,1,4));

      QryMemorandum.FieldByName('IDSOCIETA').AsString := 'MEL001';
      QryMemorandum.FieldByName('DTCREAZIONE').AsDateTime := date;
    end;
    QryMemorandum.Post;
    await(QryMemorandum.ApplyUpdates);

    ShowMessage('Inserimento eseguito con successo');

end;

procedure TFrmMemorandum.btnTFrmSeleziona_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := False;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dtscadenza as scadenza, dsdescrizione as descrizione, '
      + ' dsnote as note, dsemail as email '
      + ' from memorandum '
      + ' where dsstato = ''APERTO'' '
      + ' order by dtscadenza ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDMEMORANDUM').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
//
    end;
  end;
end;

procedure TFrmMemorandum.dsemail_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dscognome ||'' ''|| dsnome as dscollaboratore, '
      + ' emailpersonale as email '
      + ' from collaboratori ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      dsemail.value := QrySeleziona.FieldByName('email').AsString;
    end;
  end;

end;

procedure TFrmMemorandum.idmemorandum_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select * '
      + ' from memorandum '
      + ' where dsstato = ''APERTO'' '
      + ' order by dtscadenza ';
      AWait(QrySeleziona.Open);
      QrySeleziona.FieldByName('IDMEMORANDUM').Visible:= false;
      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;
      QrySeleziona.FieldByName('DTCREAZIONE').Visible:= false;
      QrySeleziona.FieldByName('DTINVIO').Visible:= false;
      QrySeleziona.FieldByName('DSNOTE').Visible:= false;
      QrySeleziona.FieldByName('DSSTATO').Visible:= false;
      QrySeleziona.FieldByName('DSEMAIL').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      idmemorandum.value := QrySeleziona.FieldByName('idmemorandum').AsString;
      dtscadenza.value := Copy(QrySeleziona.FieldByName('dtscadenza').AsString,7,4) +'-'+
        Copy(QrySeleziona.FieldByName('dtscadenza').AsString,4,2) +'-'+
        Copy(QrySeleziona.FieldByName('dtscadenza').AsString,1,2);
      dsdescrizione.value := QrySeleziona.FieldByName('dsdescrizione').AsString;
      dsnote.value := QrySeleziona.FieldByName('dsnote').AsString;
      dsemail.value := QrySeleziona.FieldByName('dsemail').AsString;
    end;
  end;

end;

initialization
  RegisterWeb(TFrmMemorandum);

end.
