unit w_edit;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget;

type

  { TWEdit }

  TWEdit = class(TWidget)
  private
    function Getplaceholder: string;
    function Getvalue: string;
    procedure Setplaceholder(AValue: string);
    procedure Setvalue(AValue: string);
  published
    link1: TJSHTMLAnchorElement;
    edit: TJSHTMLInputElement;
    seleziona1: TJSHTMLElement;
    id1: TJSHTMLElement;
    property Value: string read Getvalue write Setvalue;
    property placeholder:string read Getplaceholder write Setplaceholder;
    procedure cancella1_click(event: TJSEvent);
    procedure seleziona1_click(event: TJSEvent);
  end;

implementation

{$R *.html}


{ TWEdit }

function TWEdit.Getplaceholder: string;
begin
  Result := edit.placeholder;
end;

function TWEdit.Getvalue: string;
begin
  Result := edit.Value;
end;

procedure TWEdit.Setplaceholder(AValue: string);
begin
  edit.placeholder:=Avalue;
end;

procedure TWEdit.Setvalue(AValue: string);
begin
  edit.Value := AValue;
end;

procedure TWEdit.cancella1_click(event: TJSEvent);
var
  oldValue: String;
begin
  if ElementInstance.dispatchEvent(TJSEvent.new('elimina')) then
  begin
    oldValue := edit.Value;
    edit.Value := '';
    if oldValue <> edit.value then
      ElementInstance.dispatchEvent(TJSEvent.new('change'));

  end;
end;

procedure TWEdit.seleziona1_click(event: TJSEvent);
begin
  ElementInstance.dispatchEvent(TJSEvent.new('seleziona'));
end;

initialization
  RegisterWeb(TWEdit);

end.

