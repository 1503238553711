unit f_commesse;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, f_selezione,
  DateUtils, un_servermodule, un_generator;

type

  { TFrmCommesse }

  TFrmCommesse = class(TWidget)
  procedure AfterRender;
  procedure salva_click(Event: TJSEvent); async;

  published
    procedure btnTFrmSeleziona_click; async;
    procedure btnCercaCommesse_click; async;
    procedure btnChiudi_click; async;
    procedure idcollaboratore_click; async;
    procedure dstipo_click; async;
    salva:TJSHTMLButtonElement;
    numero: TJSHTMLInputElement;
    dtdata : TJSHTMLInputElement;
    nmorainizio : TJSHTMLInputElement;
    nmorafine : TJSHTMLInputElement;
    idcollaboratore : TJSHTMLInputElement;
    idcodicecommessa : TJSHTMLInputElement;
    dstipo : TJSHTMLInputElement;
    dscommessa : TJSHTMLInputElement;
    nmquantita : TJSHTMLInputElement;
    nmricavo : TJSHTMLInputElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    qrynumero: TQuery;
    qrymovimenti: TQuery;
    qrymaxcommessa: TQuery;
    QryDatiCommesse: TQuery;
    QryCollaboratori: TQuery;
    QryCommesse: TQuery;
    pnl1:TJSHTMLElement;
  end;

implementation

{$R *.html}

{ TFrmCommesse }

procedure TFrmCommesse.AfterRender;
begin

  pnl1.Visible := false;

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

end;

procedure TFrmCommesse.salva_click(Event: TJSEvent);
var
//  maxid :Integer;
begin

  Console.log('salva_click x2');

   // controllo se i campi sono compilati
  if dtdata.value = '' then ShowMessage('Devi inserire la data');
  if nmorainizio.value = '' then ShowMessage('Devi inserire l''ora di inizio attività');
  if nmorafine.value = '' then ShowMessage('Devi inserire l''ora di fine attività');
  if idcollaboratore.value = '' then ShowMessage('Devi inserire la tua matricola');
  if dscommessa.value = '' then ShowMessage('Devi inserire la descrizione della commessa');
  if nmricavo.value = '' then ShowMessage('Devi inserire il ricavo');
  if StrToTime(nmorainizio.value) >= StrToTime(nmorafine.value) then
    ShowMessage('l''ora inizio non puo'' essere maggiore di ora fine');

  // controllo se il collaboratore esiste
  QryCollaboratori.Close;
  QryCollaboratori.SQL.Text := 'select * from collaboratori '
  + ' where idsocieta = ''MEL001'' '
  + ' and idcollaboratore = ' + idcollaboratore.value;
  AWait(QryCollaboratori.Open);

  if QryCollaboratori.RecordCount <> 1 then ShowMessage('La matricola inserita non è corretta');

//// trovo il prossimo numero di commessa
//  qrymaxcommessa.Close;
//  qrymaxcommessa.SQL.Text:='select max(cast(idcodicecommessa as integer)) as maxid '
//  + ' from co_daticommesse where idsocieta = ''MEL001'' ';
//  AWait(qrymaxcommessa.Open);
//
//  maxid := qrymaxcommessa.FieldByName('maxid').AsInteger + 1;

// inserisco la commessa
  QryDatiCommesse.Close;
  QryDatiCommesse.SQL.Text:='select first 0 * from co_daticommesse ';
  AWait(QryDatiCommesse.Open);

  QryDatiCommesse.Append;
  QryDatiCommesse.FieldByName('IDDATICOMMESSA').AsInteger := await(GetNextId('CO_DATICOMMESSE'));
  QryDatiCommesse.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryDatiCommesse.FieldByName('IDCODICECOMMESSA').AsInteger := await(GetNextId('CO_COMMESSE'));
  QryDatiCommesse.FieldByName('DSCOMMESSA').AsString := dscommessa.value;
  QryDatiCommesse.FieldByName('DSTIPO').AsString := DSTIPO.value;
  QryDatiCommesse.FieldByName('IDCOLLABORATORE').AsString := idcollaboratore.value;
  QryDatiCommesse.FieldByName('DTDATA').AsDateTime:=StrToDate(Copy(dtdata.value,9,2) +'/'+
    Copy(dtdata.value,6,2) +'/'+ Copy(dtdata.value,1,4));
//  QryDatiCommesse.FieldByName('DTDATA').AsDateTime:=StrToDate(dtdata.value);

  QryDatiCommesse.FieldByName('NMORAINIZIO').AsString := nmorainizio.value;
  QryDatiCommesse.FieldByName('NMORAFINE').AsString := nmorafine.value;
  QryDatiCommesse.FieldByName('NMQUANTITA').AsFloat := StrToFloat(stringReplace(nmquantita.value, '.', ',', [rfReplaceAll, rfIgnoreCase]));
  QryDatiCommesse.FieldByName('NMRICAVO').AsFloat := StrToFloat(stringReplace(nmricavo.value, '.', ',', [rfReplaceAll, rfIgnoreCase]));
  QryDatiCommesse.Post;
  QryDatiCommesse.ApplyUpdates;

  ShowMessage('Inserimento eseguito con successo');

end;

procedure TFrmCommesse.btnTFrmSeleziona_click;
var
  dt1, dt2 :TDateTime;
  dtStr1, dtStr2 :String;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  // controllo se le date sono compilate
  if data1.value = '' then
  begin
    ShowMessage('Compila la data da');
    data1.focus;
    exit;
  end;

  if data2.value = '' then
  begin
    ShowMessage('Compila la data a');
    data2.focus;
    exit;
  end;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dtStr1 := FormatDateTime('yyyy-MM-dd',dt1) ;
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value);
  dtStr2 := FormatDateTime('yyyy-MM-dd',dt2) ;

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := False;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select * from co_daticommesse '
      + ' where dtdata >= ' + '''' + dtStr1 + ''''
      + ' and dtdata <= ' + '''' + dtStr2 + ''''
      + ' order by iddaticommessa desc ';
      AWait(QrySeleziona.Open);
      QrySeleziona.FieldByName('IDDATICOMMESSA').Visible:= false;
      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;
      QrySeleziona.FieldByName('FLELABORATA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
//      self.taSql.value := 'OK! selezionato IDARTICOLO=' + QrySeleziona.FieldByName('IDARTICOLO').AsString
//      + ' DSARTICOLO=' + QrySeleziona.FieldByName('DSARTICOLO').AsString;
    end;
  end;

end;

procedure TFrmCommesse.btnCercaCommesse_click;
begin

  pnl1.Visible := True;

end;

procedure TFrmCommesse.btnChiudi_click;
begin

  pnl1.Visible := False;

end;

procedure TFrmCommesse.idcollaboratore_click;
begin
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcollaboratore as id, dscognome ||'' ''|| dsnome as collaboratore from collaboratori ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      idcollaboratore.value := QrySeleziona.FieldByName('ID').AsString;
    end;
  end;
end;

procedure TFrmCommesse.dstipo_click;
begin
     with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idprodotto as id, dsprodotto from co_prodotti '
       + ' order by dsprodotto ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDDATICOMMESSA').Visible:= false;
//      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;
//      QrySeleziona.FieldByName('FLELABORATA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      dstipo.value := QrySeleziona.FieldByName('dsprodotto').AsString;
    end;
  end;
end;

initialization
  RegisterWeb(TFrmCommesse);

end.
