unit f_upload;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_servermodule, un_web, un_user, js, web, Math;

const
  chunkSize = 262144;

type

  { TFrmUpload }

  TFrmUpload = class(TWidget)
  private
    FRestartUpload: boolean;
    FServerFilename: String;
    function GetMessage: String;
    function GetSending: boolean;
    function GetTitle: String;
    procedure NextChunkCall(AFile: TJSHTMLFile; sm: TServerModule); async;
    procedure SetMessage(AValue: String);
    procedure SetRestartUpload(AValue: boolean);
    procedure SetSending(AValue: boolean);
    procedure SetServerFilename(AValue: String);
    procedure SetTitle(AValue: String);
  public
    procedure AfterRender; override;
    procedure SetProgress(Value: integer);
    property Sending: boolean read GetSending write SetSending;
    procedure ReadBlob; async;
    procedure LogClear;
    procedure LogLine(line: string);
    function CalcChunkCount(fileSize: integer): integer;
    procedure NextChunk(AFile:TJSHTMLFile;res:TJSObject );
    procedure UploadCompleted(AFile:TJSHTMLFile); async;
    property RestartUpload:boolean read FRestartUpload write SetRestartUpload;
    property ServerFilename:String read FServerFilename write SetServerFilename;
    property Title:String read GetTitle write SetTitle;
    property Message:String read GetMessage write SetMessage;
    CloseOnComplete:Boolean;
  published
    divBar: TJSHTMLElement;
    htitle: TJSHTMLElement;
    pmessage: TJSHTMLElement;
    divBarContainer: TJSHTMLElement;
    btnUpload: TJSHTMLButtonElement;
    btnAbort: TJSHTMLButtonElement;
    _fileInput: TJSHTMLInputElement;
    explain: TJSHTMLElement;
    procedure btnUpload_click;
    procedure btnAbort_click;
  end;

implementation

{$R *.html}

{ TFrmUpload }

function TFrmUpload.GetSending: boolean;
begin
  Result := not explain.Visible;
end;

function TFrmUpload.GetMessage: String;
begin
  Result := pmessage.innerHTML;
end;

function TFrmUpload.GetTitle: String;
begin
  Result := htitle.innerHTML;
end;

procedure TFrmUpload.SetSending(AValue: boolean);
begin
  if GetSending = AValue then
    Exit;
  if not AValue then
    _fileInput.Value := '';
  explain.Visible := not AValue;
  divBarContainer.Visible := AValue;
end;

procedure TFrmUpload.SetServerFilename(AValue: String);
begin
  if FServerFilename=AValue then Exit;
  FServerFilename:=AValue;
end;

procedure TFrmUpload.SetTitle(AValue: String);
begin
  htitle.innerHTML:= AValue;
end;

procedure TFrmUpload.AfterRender;
begin
  Sending := False;
  RestartUpload := false;
  CloseOnComplete := false;
  Title := 'Carica file';
end;

procedure TFrmUpload.SetProgress(Value: integer);
var
  perc: String;
begin
  divBar.setAttribute('aria-valuenow', IntToStr(Value));
  perc := IntToStr(Value) + '%';
  divBar.innerHTML := perc;
  divBar.style.setProperty('width', perc);
end;

procedure TFrmUpload.ReadBlob;
var
  AFile: TJSHTMLFile;
begin
  LogClear;
  if assigned(_fileInput.files) and (_fileInput.files.length = 0) then
  begin
    ShowMessage('Devi selezionare un file');
    exit;
  end;
  SetProgress(0);
  Sending := True;
  AFile := _fileInput.files[0];
  LogLine(format('Start upload of %s',[AFile.name]));
  with ServerModule('TWebUpload') do
  begin
    Params['idUtente'] := user.idUtente;
    Params['filename'] := AFile.name;
    Params['offset'] := '0';
    Params['stato'] := 'START';
    Params['restartUpload'] := BoolToStr(FRestartUpload,true);
    await(Call('Processa'));
    NextChunk(AFile,Response);
  end;

end;

procedure TFrmUpload.LogClear;
begin
end;

procedure TFrmUpload.LogLine(line: string);
begin
  console.log(line);
end;

function TFrmUpload.CalcChunkCount(fileSize: integer): integer;
var
  fs: double;
begin
  fs := fileSize;
  Result := Math.Ceil(fs / chunkSize);
end;

procedure TFrmUpload.NextChunkCall(AFile: TJSHTMLFile; sm: TServerModule); async;
begin
  await(sm.Call('Processa'));
  NextChunk(AFile,sm.Response);
end;

procedure TFrmUpload.SetMessage(AValue: String);
begin
  pmessage.innerHTML:= AValue;
end;

procedure TFrmUpload.SetRestartUpload(AValue: boolean);
begin
  console.log(format('---------------Setting RestartUpload=%s',[BoolToStr(AValue,true)]));
  if FRestartUpload=AValue then Exit;
  FRestartUpload:=AValue;
end;

procedure TFrmUpload.NextChunk(AFile: TJSHTMLFile; res: TJSObject);
var
  offset: NativeInt;
  progress: Integer;
  reader: TJSFileReader;
  sm: TServerModule;
  AEnd: NativeLargeInt;
  slice: TJSBlob;
begin
  if not Sending then Exit;
  //LogLine(res.CommaText);
  ServerFilename := res.Strings['serverFilename'];
  offset := StrToInt(res.Strings['serverFileLength']);
  if offset >= AFile.size then
  begin
    UploadCompleted(AFile);
    Exit;
  end;
  progress := 0;
  if offset <> 0 then
     progress := Round((offset.ToDouble / AFile.size.ToDouble) * 100);
  SetProgress(progress);

  reader := TJSFileReader.new();
  reader.onload:=
    function(Event: TEventListenerEvent): boolean
    begin
      sm := ServerModule('TWebUpload');
      with sm do
      begin
        Params['idUtente'] := user.idUtente;
        Params['filename'] := AFile.name;
        Params['offset'] := IntToStr(offset);
        Params['stato'] := 'SEND';
        Params['comments'] := '';
      end;
      sm.body := reader.Result;
      sm.Method:='POST';
      NextChunkCall(AFile,sm); //workaround because I don't know how to make this hanlder async
    end;
    AEnd := Math.Min(offset+chunkSize ,AFile.size);
    slice := AFile.slice(offset,AEnd);
    //LogLine(Format('Sending %s %d/%d %d',[AFile.name,offset,AFile.size,progress]));
    reader.readAsArrayBuffer(slice);
end;

procedure TFrmUpload.UploadCompleted(AFile: TJSHTMLFile);
begin
  LogLine(format('Upload of [%s] completed. serverFilename=%s',[AFile.name,ServerFilename]));
  SetProgress(100);
  await(Sleep(700));
  with ServerModule('TWebUpload') do
  begin
    Params['idUtente'] := user.idUtente;
    Params['filename'] := AFile.name;
    Params['offset'] := IntToStr(AFile.size);
    Params['stato'] := 'DONE';
    Params['comments'] := '';
    await(Call('Processa'));
  end;
  if CloseOnComplete then
    Close(mrOK)
  else
  begin
    ShowMessage('Upload completato');
    await(Sleep(1000));
    sending := false;
    _fileInput.value := '';
  end;
end;

procedure TFrmUpload.btnUpload_click;
begin
  ReadBlob;
end;

procedure TFrmUpload.btnAbort_click;
begin
{btnAbort.onclickExt {
    app.confirm("Conferma", "Sei sicuro di voler annullare?")
            .apply {
                btnSave.innerHTML = "Si, annulla"
                btnClose.innerHTML = "No, continua"
                onSave = {
                    sending = false
                }
            }.show()
}
}
  if window.confirm('Sei sicuro di voler annullare?') then
    Sending := False;

end;

initialization
  RegisterWeb(TFrmUpload);

end.
{

package widgets.app

import api.UploadUtente
import app
import fragment.*
import kotlinx.coroutines.experimental.async
import kotlinx.coroutines.experimental.await
import kotlinx.coroutines.experimental.delay
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import starter2.Api
import kotlin.math.*

private val init = ResourceManager.reg { UploadWidget() }

class UploadWidget : ResourceWidget() {

    val L by logger()

    val _fileInput: HTMLInputElement            by docu
    val comments: HTMLInputElement              by docu
    val btnUpload: HTMLButtonElement            by docu
    val btnAbort: HTMLButtonElement             by docu
    val _content: HTMLElement                   by docu
    val divBarContainer: HTMLElement                    by docu
    val divBar: HTMLElement                 by docu
    val explain: HTMLElement                 by docu

    var sending: Boolean
        get() = !explain.visible
        set(value) {
            if (sending == value) return
            if (!value) _fileInput.value = ""
            explain.visible = !value
            divBarContainer.visible = value
        }


    fun setProgress(value: Int) {
        divBar.setAttribute("aria-valuenow", value.toString())
        val perc = value.toString() + "%"
        divBar.innerHTML = perc
        divBar.style.width = perc
    }

    init {
        afterRender {
            sending = false
            btnUpload.onclickExt { _readBlob() }
            _fileInput.onchange = { }
            btnAbort.onclickExt {
                app.confirm("Conferma", "Sei sicuro di voler annullare?")
                        .apply {
                            btnSave.innerHTML = "Si, annulla"
                            btnClose.innerHTML = "No, continua"
                            onSave = {
                                sending = false
                            }
                        }.show()
            }
            comments.onkeydown = {
                comments.size = if (comments.value.length > 18) comments.value.length + 2 else 20
                0
            }
        }
    }


    val chunkSize = 2.0.pow(18).toInt()

    fun calcChunkCount(fileSize: Int): Int {
        return ceil(fileSize.toDouble() / chunkSize).toInt()
    }

    fun _readBlob() {
        logClear()

        val files = _fileInput.files
        if (files == null || files.length == 0) {
            app.alert("Upload", "Devi selezionare un file")
            return
        }


        setProgress(0)
        sending = true

        var file = files.get(0)!!

        log("Start upload of ${file.name}")

        Api.apiUploadUtente.new {
            it.filename = file.name
            it.offset = 0
            it.completed = false
            it.comments = ""
            //it.dataLength = 0
        }.call(doRequest = {
            it.defaultRequest(it)
            HttpRequestDebug.post(it.url, "").await()
        }) {
            nextChunk(file, it)
        }

    }

    fun logClear() {
        _content.innerHTML = ""
    }

    fun nextChunk(file: File, res: UploadUtente.Result) {
        if (!sending) return
        val offset = res.serverFileLength
        if (offset >= file.size) {
            uploadCompleted(file)
            return
        }

        val progress = if (res.serverFileLength == 0) 0 else ((offset.toDouble() / file.size.toDouble()) * 100).roundToInt()
        setProgress(progress)

        val reader = FileReader()
        reader.onload = {
            Api.apiUploadUtente.new {
                it.filename = file.name
                it.offset = offset
                it.completed = false
                it.comments = ""
            }.call(doRequest = {
                HttpRequestDebug.post(it.url, reader.result).await()
            }) {
                nextChunk(file, it)
            }
        }

        val end = kotlin.math.min(offset + chunkSize, file.size)
        val slice = file.slice(offset, end)
        log("sending ${file.name} $offset/${file.size} $progress")
        reader.readAsArrayBuffer(slice)
    }

    fun uploadCompleted(file: File) {
        log("Upload completed")
        setProgress(100)
        Api.apiUploadUtente.new {
            it.filename = file.name
            it.offset = file.size
            it.completed = true
            it.comments = comments.value
        }.call {}

        app.alert("Upload", "Upload completato").onDismiss = {
            async {
                comments.value = ""
                delay(1000)
                sending = false
                _fileInput.value = ""
            }
        }
    }

    private fun log(line: String) {
        L.debug(line)
        //_content.innerHTML += "<br>$line"
    }

}


}
