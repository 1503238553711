unit f_editbutton;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, js ,web;

type

  { TEditButton }

  TEditButton = class(TWidget)
    taSql: TJSHTMLInputElement;
    procedure AfterRender; override;
    procedure Btn1Click;
    procedure Btn2Click;
  private
    procedure Btn3Click;
  end;

implementation

{$R *.html}

procedure TEditButton.Btn3Click;
begin
  taSql.value:=  taSql.value + format('ciao %d'#13,[Random(100000)]);
end;

{ TEditButton }
procedure TEditButton.AfterRender;
begin
  Bind('btn1','click',@Btn1Click);
  Bind('btn2','click',@Btn2Click);
  Bind('btn3','click',@Btn3Click);
end;

procedure TEditButton.Btn1Click;
begin
  taSql.value:=  taSql.value + format('ciao'#13,[]);
end;

procedure TEditButton.Btn2Click;
begin
  document.body.append( TEditButton.Create.ElementInstance);
end;


initialization
  RegisterWeb(TEditButton);

end.
