unit f_rimborsi;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, un_formatsettings,
  un_user, f_selezione, un_servermodule, un_generator, DateUtils, DB;

type

  { TFrmRimborsi }

  TFrmRimborsi = class(TWidget)
    dt1, dt2: TDateTime;
    procedure AfterRender;
    procedure salva_click(Event: TJSEvent); async;

  published

    procedure btnTFrmSeleziona_click; async;
    procedure btnCercaMovimenti_click; async;
    procedure btnAnnullaModificaMovimento_click; async;
    procedure btnModificaMovimento_click; async;
    procedure btnAnnullaMovimento_click; async;
    procedure idmovimento_click; async;
    procedure btnChiudiCerca_click; async;
    procedure btnVediMovimenti_click; async;
    procedure btnChiudiAnnulla_click; async;
    titolo: TJSHTMLElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    dtdata : TJSHTMLInputElement;
    dscollaboratore : TJSHTMLInputElement;
    motivo : TJSHTMLInputElement;
    percorso : TJSHTMLInputElement;
    km : TJSHTMLInputElement;
    idmovimento : TJSHTMLInputElement;
    btnannullamodificamovimento : TJSHTMLInputElement;
    btnannullamovimento : TJSHTMLInputElement;
    btnmodificamovimento : TJSHTMLInputElement;
    btnCercaMovimenti : TJSHTMLInputElement;
    btnVediMovimentiRimborsi : TJSHTMLInputElement;
    salva:TJSHTMLButtonElement;
    taSql: TJSHTMLTextAreaElement;
    QryCollaboratori: TQuery;
    QryRimborsoTestata: TQuery;
    qryrimborsodettaglio: TQuery;
    QryControlloDataDuplicata: TQuery;
    QryUtenti: TQuery;
    QryDeleteMovimento: TQuery;
    pnlDati:TJSHTMLElement;
    pnlCerca:TJSHTMLElement;
    pnlAnnulla:TJSHTMLElement;
    pnlSalva:TJSHTMLElement;
    pnlSpazio:TJSHTMLElement;
  end;

implementation

{$R *.html}

{ TFrmRimborsi }

procedure TFrmRimborsi.AfterRender;
begin

  pnlDati.Visible := true;
  pnlCerca.Visible := false;
  pnlAnnulla.Visible := false;

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

end;

procedure TFrmRimborsi.salva_click(Event: TJSEvent);
begin

  Console.log('salva_click x2');

   // controllo se i campi sono compilati
  if dtdata.value = '' then ShowMessage('Devi inserire la data');
  if motivo.value = '' then ShowMessage('Devi inserire la il motivo');
  if percorso.value = '' then ShowMessage('Devi inserire il motivo');
  if km.value = '' then ShowMessage('Devi inserire i chilometri');

// inserisco la testata del rimborso
  QryRimborsoTestata.Close;
  QryRimborsoTestata.SQL.Text:='select first 0 * from rimb_testatarimborso ';
  AWait(QryRimborsoTestata.Open);

  QryRimborsoTestata.Append;
  QryRimborsoTestata.FieldByName('IDTESTATARIMBORSO').AsInteger := await(GetNextId('RIMB_TESTATARIMBORSO'));
  QryRimborsoTestata.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryRimborsoTestata.FieldByName('DTDATA').AsDateTime :=
  StrToDate(Copy(dtdata.value,9,2) +'/'+
           Copy(dtdata.value,6,2) +'/'+
           Copy(dtdata.value,1,4));

  // cerco la matricola del collaboratore
  QryCollaboratori.Close;
  QryCollaboratori.SQL.Text := 'select * from collaboratori '
  + ' where idsocieta = ''MEL001'' '
  + ' and idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString;
  AWait(QryCollaboratori.Open);

  QryRimborsoTestata.FieldByName('IDCOLLABORATORE').AsInteger := user.QryUtenti.FieldByName('idcollaboratore').AsInteger;
  QryRimborsoTestata.FieldByName('DSCOLLABORATORE').AsString :=
    QryCollaboratori.FieldByName('DSCOGNOME').AsString + ' ' + QryCollaboratori.FieldByName('DSNOME').AsString;

  QryRimborsoTestata.FieldByName('DSMOTIVO').AsString := motivo.value;
  QryRimborsoTestata.FieldByName('DSPERCORSO').AsString := percorso.value;
  QryRimborsoTestata.FieldByName('NMIMPORTORIMBORSOKM').AsFloat :=
    QryCollaboratori.FieldByName('NMIMPORTORIMBORSOKM').AsFloat;

  // controllo se il giorno è già stato inserito
  QryControlloDataDuplicata.Close;
  QryControlloDataDuplicata.SQL.Text:='select * from rimb_testatarimborso where idsocieta = ''MEL001'' '
  + ' and idcollaboratore =  ' + user.QryUtenti.FieldByName('idcollaboratore').AsString
  + ' and dtdata = ''' + dtdata.value + '''';
  AWait(QryControlloDataDuplicata.Open);

  if QryControlloDataDuplicata.RecordCount > 0 then
    begin
      ShowMessage('Il rimborso per questo giorno è già stato inserito...');
      exit;
   end;

  QryRimborsoTestata.Post;
  QryRimborsoTestata.ApplyUpdates;

  // inserisco il dettaglio del rimborso
  QryRimborsoDettaglio.Close;
  QryRimborsoDettaglio.SQL.Text:='select first 0 * from rimb_dettagliorimborso ';
  AWait(QryRimborsoDettaglio.Open);

  QryRimborsoDettaglio.Append;
  QryRimborsoDettaglio.FieldByName('IDDETTAGLIORIMBORSO').AsInteger := await(GetNextId('RIMB_DETTAGLIORIMBORSO'));
  QryRimborsoDettaglio.FieldByName('IDTESTATARIMBORSO').AsInteger := QryRimborsoTestata.FieldByName('IDTESTATARIMBORSO').AsInteger;
  QryRimborsoDettaglio.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryRimborsoDettaglio.FieldByName('DTDATA').AsDateTime :=
  StrToDate(Copy(dtdata.value,9,2) +'/'+
           Copy(dtdata.value,6,2) +'/'+
           Copy(dtdata.value,1,4));
  QryRimborsoDettaglio.FieldByName('DSMOTIVO').AsString := motivo.value;
  QryRimborsoDettaglio.FieldByName('DSPERCORSO').AsString := percorso.value;
  QryRimborsoDettaglio.FieldByName('NMQUANTITA').AsInteger := StrToInt(km.value);
  QryRimborsoDettaglio.FieldByName('NMIMPORTO').AsFloat :=
    QryCollaboratori.FieldByName('NMIMPORTORIMBORSOKM').AsFloat;
  QryRimborsoDettaglio.FieldByName('NMTOTALE').AsFloat :=
  StrToInt(km.value) * QryCollaboratori.FieldByName('NMIMPORTORIMBORSOKM').AsFloat;

  QryRimborsoDettaglio.Post;
  QryRimborsoDettaglio.ApplyUpdates;

  ShowMessage('Inserimento eseguito con successo');

end;

procedure TFrmRimborsi.btnModificaMovimento_click;
begin

// modifico la testata del rimborso
 QryRimborsoTestata.Close;
 QryRimborsoTestata.SQL.Text:='select * from rimb_testatarimborso ';
 AWait(QryRimborsoTestata.Open);

  if (idmovimento.value = '') or (not QryRimborsoTestata.Locate('idtestatarimborso', idmovimento.value, [])) then
    begin
      ShowMessage('Devi inserire un l''id corretto');
      Exit;
    end;

   QryRimborsoTestata.edit;
   if dtdata.value <> '' then QryRimborsoTestata.FieldByName('DTDATA').AsDateTime :=
     StrToDate(Copy(dtdata.value,9,2) +'/'+
            Copy(dtdata.value,6,2) +'/'+
            Copy(dtdata.value,1,4));

   // cerco la matricola del collaboratore
   QryCollaboratori.Close;
   QryCollaboratori.SQL.Text := 'select * from collaboratori '
   + ' where idsocieta = ''MEL001'' '
   + ' and idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString;
   AWait(QryCollaboratori.Open);

   QryRimborsoTestata.FieldByName('IDCOLLABORATORE').AsInteger := user.QryUtenti.FieldByName('idcollaboratore').AsInteger;
   QryRimborsoTestata.FieldByName('DSCOLLABORATORE').AsString :=
     QryCollaboratori.FieldByName('DSCOGNOME').AsString + ' ' + QryCollaboratori.FieldByName('DSNOME').AsString;

   if motivo.value <> '' then QryRimborsoTestata.FieldByName('DSMOTIVO').AsString := motivo.value;
   if percorso.value <> '' then QryRimborsoTestata.FieldByName('DSPERCORSO').AsString := percorso.value;

   QryRimborsoTestata.Post;
   QryRimborsoTestata.ApplyUpdates;

   // inserisco il dettaglio del rimborso
   QryRimborsoDettaglio.Close;
   QryRimborsoDettaglio.SQL.Text:='select * from rimb_dettagliorimborso ';
   AWait(QryRimborsoDettaglio.Open);

   QryRimborsoDettaglio.Edit;
   if dtdata.value <> '' then QryRimborsoDettaglio.FieldByName('DTDATA').AsDateTime :=
     StrToDate(Copy(dtdata.value,9,2) +'/'+
            Copy(dtdata.value,6,2) +'/'+
            Copy(dtdata.value,1,4));
   if motivo.value <> '' then QryRimborsoDettaglio.FieldByName('DSMOTIVO').AsString := motivo.value;
   if percorso.value <> '' then QryRimborsoDettaglio.FieldByName('DSPERCORSO').AsString := percorso.value;
   if km.value <> '' then QryRimborsoDettaglio.FieldByName('NMQUANTITA').AsInteger := StrToInt(km.value);
   QryRimborsoDettaglio.FieldByName('NMIMPORTO').AsFloat :=
     QryCollaboratori.FieldByName('NMIMPORTORIMBORSOKM').AsFloat;
   QryRimborsoDettaglio.FieldByName('NMTOTALE').AsFloat :=
   StrToInt(km.value) * QryCollaboratori.FieldByName('NMIMPORTORIMBORSOKM').AsFloat;

   QryRimborsoDettaglio.Post;
   QryRimborsoDettaglio.ApplyUpdates;

   idmovimento.value := '';

   ShowMessage('Modifica eseguita con successo');

end;

procedure TFrmRimborsi.btnAnnullaMovimento_click;
begin

 // annullo la testata del rimborso
  QryRimborsoTestata.Close;
  QryRimborsoTestata.SQL.Text:='select * from rimb_testatarimborso ';
  AWait(QryRimborsoTestata.Open);

  if (idmovimento.value = '') or (not QryRimborsoTestata.Locate('idtestatarimborso', idmovimento.value, [])) then
    begin
      ShowMessage('Devi inserire un l''id corretto');
      Exit;
    end;

  QryRimborsoTestata.Edit;
  QryRimborsoTestata.FieldByName('dsannullato').AsString := 'A';
  QryRimborsoTestata.Post;
  QryRimborsoTestata.ApplyUpdates;

  // annullo il dettaglio del rimborso
   QryRimborsoDettaglio.Close;
   QryRimborsoDettaglio.SQL.Text:=' select * from rimb_dettagliorimborso '
   + ' where idtestatarimborso = ' + QryRimborsoTestata.FieldByName('idtestatarimborso').AsString;
   AWait(QryRimborsoDettaglio.Open);

   QryRimborsoDettaglio.Edit;
   QryRimborsoDettaglio.FieldByName('dsannullato').AsString := 'A';
   QryRimborsoDettaglio.Post;
   QryRimborsoDettaglio.ApplyUpdates;

  idmovimento.value := '';

  ShowMessage('Annullamento eseguito con successo');

end;

procedure TFrmRimborsi.btnTFrmSeleziona_click;
var
  datamin, datamax :String;
begin
  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  // controllose i campi sono compilati
  if data1.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data1.focus;
    exit;
  end;
  if data2.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data2.focus;
    exit;
  end;

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := False;

      datamin := Copy(data1.value,9,2) +'.'+
        Copy(data1.value,6,2) +'.'+
        Copy(data1.value,1,4);
      datamax := Copy(data2.value,9,2) +'.'+
        Copy(data2.value,6,2) +'.'+
        Copy(data2.value,1,4);

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= ' select d.dtdata as data, d.dsmotivo as motivo, '
      + ' d.dspercorso as percorso, d.nmquantita as km '
      + ' from rimb_testatarimborso t '
      + ' join rimb_dettagliorimborso d on d.idtestatarimborso = t.idtestatarimborso '
      + ' where t.idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString
      + ' and d.dtdata >= ' + '''' + datamin + ''''
      + ' and d.dtdata <= ' + '''' + datamax + ''''
      + ' and (d.dsannullato is null or d.dsannullato = '''') '
      + ' order by d.dtdata desc ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
//      self.taSql.value := 'OK! selezionato IDARTICOLO=' + QrySeleziona.FieldByName('IDARTICOLO').AsString
//      + ' DSARTICOLO=' + QrySeleziona.FieldByName('DSARTICOLO').AsString;
    end;
  end;
end;

procedure TFrmRimborsi.idmovimento_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select d.idtestatarimborso as id, d.dtdata as data, '
      + ' d.dsmotivo as motivo, d.dspercorso as percorso, d.nmquantita as km '
      + ' from rimb_testatarimborso t'
      + ' join rimb_dettagliorimborso d on d.idtestatarimborso = t.idtestatarimborso '
      + ' where t.idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString
      + ' and (d.dsannullato is null or d.dsannullato = '''') '
      + ' order by d.dtdata desc ';
      AWait(QrySeleziona.Open);
      //QrySeleziona.FieldByName('IDMEMORANDUM').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      idmovimento.value := QrySeleziona.FieldByName('id').AsString;
      dtdata.value := Copy(QrySeleziona.FieldByName('data').AsString,7,4) +'-'+
        Copy(QrySeleziona.FieldByName('data').AsString,4,2) +'-'+
        Copy(QrySeleziona.FieldByName('data').AsString,1,2);
      motivo.value := QrySeleziona.FieldByName('motivo').AsString;
      percorso.value := QrySeleziona.FieldByName('percorso').AsString;
      km.value := FloatToStr(QrySeleziona.FieldByName('km').AsFloat);
    end;
  end;

end;

procedure TFrmRimborsi.btnCercaMovimenti_click;
begin

  Console.log('cerca va');
  pnlCerca.Visible := True;
  pnlDati.Visible := False;
  pnlAnnulla.Visible := False;
  btnannullamodificamovimento.Visible := False;

end;

procedure TFrmRimborsi.btnAnnullaModificaMovimento_click;
begin

  PnlAnnulla.Visible:=True;
  pnlCerca.Visible := False;
  btncercamovimenti.Visible := False;
  PnlSalva.Visible:=False;
  btnannullamodificamovimento.Visible:=False;
  pnlSpazio.Visible:=False;

end;

procedure TFrmRimborsi.btnChiudiCerca_click;
begin

  pnlDati.Visible := True;
  pnlCerca.Visible := False;
  btnannullamodificamovimento.Visible := True;
  btnCercaMovimenti.innerHTML:='movimenti di cassa';

end;

procedure TFrmRimborsi.btnVediMovimenti_click;
begin

    btnTFrmSeleziona_click;

end;

procedure TFrmRimborsi.btnChiudiAnnulla_click;
begin

  pnlDati.Visible:=True;
  btnCercaMovimenti.Visible := True;
  btnannullamodificamovimento.Visible:=True;
  PnlSalva.Visible:=True;
  pnlSpazio.Visible:=True;
  PnlAnnulla.Visible:=False;

end;

initialization
  RegisterWeb(TFrmRimborsi);

end.
