unit f_produzioneinsert;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmProduzioneInsert }

  TFrmProduzioneInsert = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idproduzionedettaglio:Integer;
    idproduzione:integer;
    procedure idproduzionedettaglio_click; async;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private

  published
    procedure idresponsabile_click; async;
    procedure dsresponsabile_click; async;
    procedure idstato_click; async;
    QrySeleziona: TQuery;
    QryProduzioneDettaglio: TQuery;
    qryproduzione: TQuery;
    QryControlloDataDuplicata: TQuery;
    dsstep : TJSHTMLInputElement;
    dsnextstep : TJSHTMLInputElement;
    dtscadenza : TJSHTMLInputElement;
    idresponsabile : TJSHTMLInputElement;
    dsresponsabile : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    idstato : TJSHTMLInputElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneInsert }

procedure TFrmProduzioneInsert.AfterRender;
begin

  idresponsabile.Visible:=false;

end;

procedure TFrmProduzioneInsert.AfterDomAppend;
begin

  idproduzionedettaglio_click;

end;

procedure TFrmProduzioneInsert.idproduzionedettaglio_click;
begin

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select * '
      + ' from pro_produzionedettaglio '
      + 'where idproduzione = ' + IntToStr(idproduzione);
      QrySeleziona.SQL.Text := QrySeleziona.SQL.Text + ' order by idproduzionedettaglio  desc ';
      AWait(QrySeleziona.Open);

      QrySeleziona.FieldByName('IDPRODUZIONEDETTAGLIO').Visible:= false;
      QrySeleziona.FieldByName('IDPRODUZIONE').Visible:= false;
      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;
      QrySeleziona.FieldByName('DSSTEP').Visible:= false;
      QrySeleziona.FieldByName('DSNEXTSTEP').Visible:= false;
      QrySeleziona.FieldByName('IDRESPONSABILE').Visible:= false;
      QrySeleziona.FieldByName('DSRESPONSABILE').Visible:= false;
      QrySeleziona.FieldByName('DTSCADENZA').Visible:= false;
      QrySeleziona.FieldByName('DSNOTE').Visible:= false;
      QrySeleziona.FieldByName('DSSTATO').Visible:= false;

      if dsstep.value = '' then
      begin
        dsstep.value := QrySeleziona.FieldByName('dsnextstep').AsString;
        idstato.value := QrySeleziona.FieldByName('dsstato').AsString;
        dtscadenza.value := Copy(QrySeleziona.FieldByName('dtscadenza').AsString,7,4) +'-'+
         Copy(QrySeleziona.FieldByName('dtscadenza').AsString,4,2) +'-'+
         Copy(QrySeleziona.FieldByName('dtscadenza').AsString,1,2);
        idresponsabile.value := QrySeleziona.FieldByName('idresponsabile').AsString;
        dsresponsabile.value := QrySeleziona.FieldByName('dsresponsabile').AsString;
      end;

end;

procedure TFrmProduzioneInsert.salva_click(Event: TJSEvent);
var
  scad :String;
begin

  QryProduzioneDettaglio.Close;
  QryProduzioneDettaglio.SQL.Text := 'select * from pro_produzionedettaglio';
  AWait(QryProduzioneDettaglio.Open);

//   controllo se i campi sono compilati
    scad := DateToStr(date);
    scad := Copy(scad,7,4) +'-'+ Copy(scad,4,2) +'-'+ Copy(scad,1,2);
    if dtscadenza.value <= scad then
      begin
        ShowMessage('La scadenza non può essere inferiore o uguale ad oggi');
        Exit;
      end;
    if dtscadenza.value = '' then
      begin
        ShowMessage('Devi inserire la scadenza');
        Exit;
      end;
    if dsstep.value = '' then
      begin
        ShowMessage('Devi inserire lo step');
        Exit;
      end;
    if dsnextstep.value = '' then
      begin
        ShowMessage('Devi inserire il next step');
        Exit;
      end;
    if idresponsabile.value = '' then
      begin
        ShowMessage('Devi inserire l''id del responsabile');
        Exit;
      end;
    if dsresponsabile.value = '' then
      begin
        ShowMessage('Devi inserire il reponsabile');
        Exit;
      end;
    if dsnote.value = '' then
      begin
        ShowMessage('Devi inserire le note');
        Exit;
      end;
    if idstato.value = '' then
      begin
        ShowMessage('Devi inserire lo stato');
        Exit;
      end;

    // controllo se il giorno è già stato inserito
      QryControlloDataDuplicata.Close;
      QryControlloDataDuplicata.SQL.Text:='select * from pro_produzionedettaglio '
      + ' where idsocieta = ''MEL001'' '
      + ' and idproduzione =  ' + QryProduzioneDettaglio.FieldByName('idproduzione').AsString
      + ' and dtscadenza = ''' + dtscadenza.value + '''';
      AWait(QryControlloDataDuplicata.Open);

      if QryControlloDataDuplicata.RecordCount > 0 then
        begin
          ShowMessage('Lo step per questo giorno è già stato inserito...');
          exit;
       end;

// inserisco lo step
      QryProduzioneDettaglio.Append;
      QryProduzioneDettaglio.FieldByName('IDPRODUZIONEDETTAGLIO').AsInteger := await(GetNextId('PRODUZIONEDETTAGLIO'));
      console.log('leggo il campo id: ' +  IntToStr(idproduzionedettaglio) );
      QryProduzioneDettaglio.FieldByName('IDPRODUZIONE').AsString := QrySeleziona.FieldByName('IDPRODUZIONE').AsString;
      QryProduzioneDettaglio.FieldByName('IDSOCIETA').AsString := 'MEL001';
      QryProduzioneDettaglio.FieldByName('DSSTEP').AsString := dsstep.value;
      QryProduzioneDettaglio.FieldByName('DSNEXTSTEP').AsString := dsnextstep.value;
      QryProduzioneDettaglio.FieldByName('IDRESPONSABILE').AsString := idresponsabile.value;
      QryProduzioneDettaglio.FieldByName('DSRESPONSABILE').AsString := dsresponsabile.value;
      QryProduzioneDettaglio.FieldByName('DSNOTE').AsString := dsnote.value;
      QryProduzioneDettaglio.FieldByName('DSSTATO').AsString := idstato.value;
      QryProduzioneDettaglio.FieldByName('DTSCADENZA').AsDateTime := StrToDate(Copy(dtscadenza.value,9,2) +'/'+
        Copy(dtscadenza.value,6,2) +'/'+ Copy(dtscadenza.value,1,4));
    QryProduzioneDettaglio.Post;
    await(QryProduzioneDettaglio.ApplyUpdates);

// aggiorno il campo stato nella tabella produzione
    QryProduzione.Close;
    QryProduzione.SQL.Text := 'select * from pro_produzione '
    + ' where idproduzione = ' +  QrySeleziona.FieldByName('IDPRODUZIONE').AsString;
    AWait(QryProduzione.Open);

    QryProduzione.Edit;
    QryProduzione.FieldByName('dsstato').AsString:=idstato.value;
    QryProduzione.FieldByName('dtnextscadenza').AsString:=QryProduzioneDettaglio.FieldByName('dtscadenza').AsString;
    QryProduzione.Post;
    await(QryProduzione.ApplyUpdates);

    ShowMessage('Inserimento eseguito con successo');

    Close();

end;

procedure TFrmProduzioneInsert.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

procedure TFrmProduzioneInsert.idresponsabile_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcollaboratore as id, dscognome ||'' ''|| dsnome as dscollaboratore '
      + ' from collaboratori ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idresponsabile.value := QrySeleziona.FieldByName('id').AsString;
      dsresponsabile.value := QrySeleziona.FieldByName('dscollaboratore').AsString;
    end;
  end;

end;

procedure TFrmProduzioneInsert.dsresponsabile_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcollaboratore as id, dscognome ||'' ''|| dsnome as dscollaboratore '
      + ' from collaboratori ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idresponsabile.value := QrySeleziona.FieldByName('id').AsString;
      dsresponsabile.value := QrySeleziona.FieldByName('dscollaboratore').AsString;
    end;

  end;
end;

procedure TFrmProduzioneInsert.idstato_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dsstato as stato '
      + ' from pro_stati ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      idstato.value := QrySeleziona.FieldByName('stato').AsString;
    end;
  end;

end;

initialization
  RegisterWeb(TFrmProduzioneInsert);

end.

