unit f_qrcode;

{$mode objfpc}

interface

uses
  Classes, SysUtils, w_qrcode, un_widget, js, web;

type

  { TFrmQRCode }

  TFrmQRCode = class(TWidget)
    FCounter:Integer;
  public
    procedure AfterRender; override;
    procedure AfterClose; override;
    procedure OnQRCode;
    procedure AfterDomAppend; async; override;
  published
    qrcode1:TWQRCode;
    divLog:TJSHTMLElement;
    btnStartScanning:TJSHTMLButtonElement;
    btnStopScanning:TJSHTMLButtonElement;
    autoStart:TJSHTMLInputElement;
    autoStop:TJSHTMLInputElement;
    procedure btnStartScanning_click;
    procedure btnStopScanning_click;
  end;

implementation

{$R *.html}


{ TFrmQRCode }

procedure TFrmQRCode.AfterRender;
begin
  qrcode1.OnQRCodeAvailable := @OnQRCode;
end;

procedure TFrmQRCode.AfterClose;
begin
  qrcode1.StopScanning;
end;

procedure TFrmQRCode.OnQRCode;
begin
  Inc(FCounter);
  divLog.innerHTML:= format('Lettura %d; qrcode=[%s]',[FCounter, qrcode1.Data]);
  if autoStop.checked then
      btnStopScanning_click;
end;

procedure TFrmQRCode.AfterDomAppend;
begin
  if autoStart.checked then
    btnStartScanning_click;
end;

procedure TFrmQRCode.btnStartScanning_click;
begin
  console.log('btnStartScanning_click');
  FCounter := 0;
  qrcode1.StartScanning;
  btnStartScanning.hidden := true;
  btnStopScanning.hidden := false;
end;

procedure TFrmQRCode.btnStopScanning_click;
begin
  console.log('btnStopScanning_click');
  qrcode1.StopScanning;
  btnStartScanning.hidden := false;
  btnStopScanning.hidden := true;
end;

initialization
  RegisterWeb(TFrmQRCode);
end.
