unit w_table;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_hotkey, js, web, DB;

const
     attr_rec_no ='rec-no';
     attr_field_name = 'field-name';
     attr_original_value = 'original-value';
type


  { TWTable }

  TWTable = class(TWidget)
  private
    FHeader: boolean;
    procedure Change_row_focus(Event: TJSEvent; delta: integer);
    function td_input_event(Event: TJSEvent): boolean;
    procedure Keypress_FTab_ArrowDown(Event: TJSEvent);
    procedure Keypress_FTab_ArrowUp(Event: TJSEvent);
    procedure SetHeader(AValue: boolean);
    procedure SetRecno(td: TJSHTMLTableCellElement);
    procedure Keypress_FTab_Enter;
    function GetField(event:TJSEvent):TField;
  public
    FDataset: TDataset;
    constructor Create; reintroduce;
    Writable: boolean;
    procedure RenderDataSet(ds: TDataSet);
    procedure AfterRender; override;
  published
    FTab: TJSHTMLTableElement;
    FDataHead: TJSHTMLTableSectionElement;
    FTrHead: TJSHTMLTableRowElement;
    FDataBody: TJSHTMLTableSectionElement;
    FOnClick: TRefProc;
    procedure FTab_focusin(Event: TEventListenerEvent);
    procedure FTab_focusout(Event: TEventListenerEvent);
    property Header: boolean read FHeader write SetHeader;
  end;

implementation

{$R *.html}

procedure TWTable.SetHeader(AValue: boolean);
begin
  if FHeader = AValue then
    Exit;
  FHeader := AValue;
  console.log(format('FHeader=%s', [BoolToStr(FHeader, True)]));
  if FHeader then
    FDataHead.style.removeProperty('display')
  else
    FDataHead.style.setProperty('display', 'none');

end;

function TWTable.td_input_event(Event: TJSEvent): boolean;
var
  field: TField;
  td: TJSHTMLTableCellElement;
  ev: TJSKeyboardEvent;
  fieldSize, tdLen: Integer;
begin
  field := GetField(event);
  if not Assigned(field) then
    exit;
  ev := event as TJSKeyboardEvent;
  if ev.key = 'Backspace' then
    exit;
  td := Event.targetElement as TJSHTMLTableCellElement;

  fieldSize := field.Size;
  tdLen := length(td.innerHTML);
  console.log(format('fieldName=%s size=%d',[field.FieldName,fieldSize]));
  if (field.DataType in [ftString,ftBlob, ftMemo,ftFixedChar]) and (fieldSize<= tdLen) then
  begin
    Event.preventDefault;
    exit;
  end;
  Result := true;
end;

procedure TWTable.Keypress_FTab_Enter;
begin
  //enter remove focus
  (document.activeElement as TJSHTMLElement).blur;
end;

function TWTable.GetField(event: TJSEvent): TField;
var
  td: TJSHTMLTableCellElement;
  th: TJSHTMLElement;
  fieldName: String;
begin
  Result := nil;
  td := event.targetElement as TJSHTMLTableCellElement;
  if isUndefined(td) or isNull(td) then
    exit;

  th := FTrHead.cells[td.cellIndex] as TJSHTMLElement;
  fieldName := th.getAttribute(attr_field_name);
  Result := FDataset.FindField(fieldName);
end;


constructor TWTable.Create;
begin
  inherited Create;
end;

procedure TWTable.RenderDataSet(ds: TDataSet);
var
  Fields: array of TField;

  function CreateDataRow(aRowNo: integer): TJSHTMLTableRowElement;
  var
    I: integer;
    td: TJSHTMLTableCellElement;
    tr: TJSHTMLTableRowElement;
  begin
    tr := document.createElement('tr') as TJSHTMLTableRowElement;
    tr.setAttribute(attr_rec_no, IntToStr(ds.RecNo));
    for I := 0 to length(Fields) - 1 do
    begin
      td := tr.insertCell(tr.cells.length);
      td.innerHTML := Fields[i].DisplayText;
      if Fields[i].Alignment = taRightJustify then
        td.classList.add('text-right');
      if Writable then
      begin
        td.setAttribute('contentEditable', 'true');
        td.addEventListener('keydown',@td_input_event);
//        td.oninput:=@FtabOnChange;
      end;
    end;
    Result := tr;
  end;

var

  I: integer;
  cell: TJSHTMLElement;
begin
  FDataset := ds;
  FDataBody.innerHTML := '';
  FTrHead.innerHTML:= '';
  if ds = nil then
  begin
    FTrHead.innerHTML := '<th>nessun record da visualizzare</th>';
    exit;
  end;
  for i := 0 to ds.FieldCount - 1 do
  begin
    if ds.Fields[i].Visible then
    begin
      SetLength(Fields, length(fields) + 1);
      Fields[length(fields) - 1] := ds.Fields[i];
    end;
  end;

  for I := 0 to length(Fields) - 1 do
  begin
    cell := document.createElement('th') as TJSHTMLElement ;
    FTrHead.append(cell);
    cell.innerHTML:=Fields[i].FieldName ;
    cell.setAttribute(attr_field_name,Fields[i].FieldName );
  end;


  I := 0;
  while not ds.EOF do
  begin
    Inc(i);
    FDataBody.append(CreateDataRow(i));
    ds.Next;
  end;

  FTab.onclick := function (aEvent: TJSMouseEvent): boolean
    begin
      SetRecno(aEvent.targetElement as TJSHTMLTableCellElement);
      ElementInstance.dispatchEvent(TJSEvent.new('rowclick'));
      if Assigned(FOnClick) then
        FOnClick();
    end;

end;

procedure TWTable.SetRecno(td:TJSHTMLTableCellElement);
var
  recno: String;
begin
  if isNull(td) or isUndefined(td) then
    exit;
  recno := td.parentElement.getAttribute(attr_rec_no);
  if not isNull(recno) then //null if header click
    FDataset.RecNo := StrToInt(recno);
end;


procedure TWTable.Change_row_focus(Event: TJSEvent;delta:integer);
var
  td: TJSHTMLTableCellElement;
  newIndex: Integer;
  newTd: TJSHTMLElement;
begin
  td := event.targetElement as TJSHTMLTableCellElement;
  if isUndefined(td) or isNull(td) then
    exit;
  newIndex := (td.parentElement as TJSHTMLTableRowElement).rowIndex + delta;


  if (newIndex < 0) or (newIndex >= FDataBody.rows.length) then
    exit;

  newTd := (FDataBody.rows[newIndex] as TJSHTMLTableRowElement).cells[td.cellIndex] as TJSHTMLElement;
  newTd.focus;
end;

procedure TWTable.Keypress_FTab_ArrowUp(Event: TJSEvent);
begin
  Change_row_focus(event,-2);
end;

procedure TWTable.Keypress_FTab_ArrowDown(Event: TJSEvent);
begin
  Change_row_focus(event,0);
end;


procedure TWTable.AfterRender;
begin
  Header := True;
  THotkey.Create(FTab).Add('Enter', @Keypress_FTab_Enter);
  THotkey.Create(FTab).Add('ArrowUp', @Keypress_FTab_ArrowUp);
  THotkey.Create(FTab).Add('ArrowDown', @Keypress_FTab_ArrowDown);
end;

procedure TWTable.FTab_focusin(Event: TEventListenerEvent);
begin
//  console.log('focusin' + Event.targetElement.innerHTML);
  Event.targetElement.setAttribute(attr_original_value,Event.targetElement.innerHTML);
  SetRecno(Event.targetElement as TJSHTMLTableCellElement);
end;

procedure TWTable.FTab_focusout(Event: TEventListenerEvent);
var
  td:TJSHTMLTableCellElement;
  field: TField;
begin
  td := Event.targetElement as TJSHTMLTableCellElement;
  if  td.getAttribute(attr_original_value) <> td.innerHTML then
  begin
    field := GetField(event);
    console.log(format('%s Is dirty is true this value [%s] should be saved',[field.FieldName,Event.targetElement.innerHTML]));
    FDataset.Edit;
    field.AsString:=td.innerHTML;
    FDataset.Post;
  end;
end;

initialization
  RegisterWeb(TWTable);
end.
