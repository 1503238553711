unit f_header;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, un_hotkey, un_manager, js,
  web, DB;

type

  { TFrmMenu }

  { TFrmHeader }

  TFrmHeader = class(TWidget)
    procedure AfterRender; override;
  published
    title_add: TJSHTMLElement;
    menu: TJSHTMLElement;
  end;

function FrmHeader: TFrmHeader;

implementation

{$R *.html}

var
  _FrmHeader: TFrmHeader;

function FrmHeader: TFrmHeader;
begin
  if not Assigned(_FrmHeader) then
    _FrmHeader := TFrmHeader.Create;
  Result := _FrmHeader;
end;

{ TFrmHeader }

procedure TFrmHeader.AfterRender;
var
  li: TJSHTMLElement;
  a: TJSHTMLAnchorElement;
begin
{ val li = document.createElement("li") as HTMLLIElement
        val a = document.createElement("a") as HTMLAnchorElement

        init {
            a.setAttribute("data-toggle", "collapse")
            a.setAttribute("data-target", ".navbar-collapse.show") //close after click
            a.href = "#"
            a.className = "nav-link"
            a.innerHTML = title
            a.onclick = { it.preventDefault(); function() }
            li.appendChild(a)
            menu.appendChild(li)
            this.visible = visible
        }
        }
  li := document.createElement('li') as TJSHTMLElement;
  a := document.createElement('a') as TJSHTMLAnchorElement;
  a.setAttribute('data-toggle', 'collapse');
  a.setAttribute('data-target', '.navbar-collapse.show');
  a.href := '#';
  a.ClassName := 'nav-link';
  a.innerHTML := 'Chiudi';  // <---------
  a.onclick := function (aEvent: TJSMouseEvent): boolean
    begin
      aEvent.preventDefault();
      manager.closeCurrent; // <---------
    end;
  li.appendChild(a);
  menu.appendChild(li);
end;

initialization
  RegisterWeb(TFrmHeader);
end.
