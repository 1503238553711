unit f_ore;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, un_formatsettings,
  un_user, f_selezione, un_servermodule, un_generator, DateUtils, DB;

type

  { TFrmOre }

  TFrmOre = class(TWidget)
    dt1, dt2: TDateTime;
    procedure AfterRender;
    procedure salva_click(Event: TJSEvent); async;

  published
    procedure btnTFrmSeleziona_click; async;
    procedure btnCercaMovimenti_click; async;
    procedure btnAnnullaModificaMovimento_click; async;
    procedure btnModificaMovimento_click; async;
    procedure btnAnnullaMovimento_click; async;
    procedure idmovimento_click; async;
    procedure btnChiudiCerca_click; async;
    procedure btnVediMovimenti_click; async;
    procedure btnChiudiAnnulla_click; async;
    procedure idcausale_click; async;
    procedure CalcolaTotali; async;
    function OreMeseProgressive:Double; async;
    function OreMensilizzazione:Double; async;
    procedure CalcoloOreStraordinarie; async;
    titolo: TJSHTMLElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    dtdata : TJSHTMLInputElement;
    dscollaboratore : TJSHTMLInputElement;
    nmore : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    totaleore : TJSHTMLInputElement;
    importo : TJSHTMLInputElement;
    idmovimento : TJSHTMLInputElement;
    idcausale : TJSHTMLInputElement;
    btnannullamodificamovimento : TJSHTMLInputElement;
    btnannullamovimento : TJSHTMLInputElement;
    btnmodificamovimento : TJSHTMLInputElement;
    btnCercaMovimenti : TJSHTMLInputElement;
    btnVediMovimentiRimborsi : TJSHTMLInputElement;
    salva:TJSHTMLButtonElement;
    qry1: TQuery;
    taSql: TJSHTMLTextAreaElement;
    qryoremese: TQuery;
    QryOreGiorno: TQuery;
    QryCollaboratori: TQuery;
    QryMaxId: TQuery;
    QryOre: TQuery;
    QryTotaleOre: TQuery;
    QryUtenti: TQuery;
    QryDeleteMovimento: TQuery;
    QryControlloDataDuplicata: TQuery;
    QryOreMeseProgressive: TQuery;
    QryGGMensilizzazione: TQuery;
    QryCausali: TQuery;
    pnlDati:TJSHTMLElement;
    pnlCerca:TJSHTMLElement;
    pnlAnnulla:TJSHTMLElement;
    pnlSalva:TJSHTMLElement;
    pnlSpazio:TJSHTMLElement;
  end;

implementation

{$R *.html}

{ TFrmOre }

procedure TFrmOre.AfterRender;
begin
//  BtnCercaOre.innerHTML:='pippo';

  pnlDati.Visible := true;
  pnlCerca.Visible := false;
  pnlAnnulla.Visible := false;

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

end;

procedure TFrmOre.salva_click(Event: TJSEvent);
var
  data :TDateTime;
  gg, mese :Integer;
  oremese, oregiorno :String;
  ore, minuti, costoore, costominuti:Currency;
begin

// controllo se i campi sono compilati
  if dtdata.value = '' then ShowMessage('Devi inserire la data');
  if nmore.value = '' then ShowMessage('Devi inserire le ore');
  if idcausale.value = '' then ShowMessage('Devi inserire la causale');

// inserisco le ore
  QryOre.Close;
  QryOre.SQL.Text:='select first 0 * from ore ';
  AWait(QryOre.Open);

  QryOre.Append;
  QryOre.FieldByName('IDORE').AsInteger := await(GetNextId('ORE'));
  QryOre.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryOre.FieldByName('DSTIPO').AsString := 'C';
  QryOre.FieldByName('DSGRUPPO').AsString := 'MELIDE';

// cerco la matricola del collaboratore
  QryCollaboratori.Close;
  QryCollaboratori.SQL.Text := 'select * from collaboratori '
  + ' where idsocieta = ''MEL001'' '
  + ' and idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString;
  AWait(QryCollaboratori.Open);

// cerco la descrizione della causale
    QryCausali.Close;
    QryCausali.SQL.Text := 'select * from ore_causali '
    + ' where idsocieta = ''MEL001'' '
    + ' and idcausale = ' + idcausale.value;
    AWait(QryCausali.Open);

  QryOre.FieldByName('IDCOLLABORATORE').AsInteger := user.QryUtenti.FieldByName('idcollaboratore').AsInteger;
  QryOre.FieldByName('DSCOLLABORATORE').AsString :=
    QryCollaboratori.FieldByName('DSCOGNOME').AsString + ' ' + QryCollaboratori.FieldByName('DSNOME').AsString;
  QryOre.FieldByName('NMCOSTOORA').AsFloat := QryCollaboratori.FieldByName('NMCOSTO').AsFloat;
  QryOre.FieldByName('DTDATAINSERIMENTO').AsDateTime := date;
  QryOre.FieldByName('DTDATA').AsDateTime :=
  StrToDate(Copy(dtdata.value,9,2) +'/'+
           Copy(dtdata.value,6,2) +'/'+
           Copy(dtdata.value,1,4));
  QryOre.FieldByName('IDCAUSALE').AsString := idcausale.value;;
  QryOre.FieldByName('DSCAUSALE').AsString := QryCausali.FieldByName('DSCAUSALE').AsString;

// controllo se il giorno è già stato inserito
  QryControlloDataDuplicata.Close;
  QryControlloDataDuplicata.SQL.Text:='select * from ore where idsocieta = ''MEL001'' '
  + ' and idcollaboratore =  ' + user.QryUtenti.FieldByName('idcollaboratore').AsString
  + ' and dtdata = ''' + dtdata.value + '''';
  AWait(QryControlloDataDuplicata.Open);

  if QryControlloDataDuplicata.RecordCount > 0 then
    begin
      ShowMessage('Le ore per questo giorno sono già state inserite...');
      exit;
   end;

  QryOre.FieldByName('NMORE').AsFloat :=
   StrToFloat(stringReplace(nmore.value , '.',  ',' ,[rfReplaceAll, rfIgnoreCase]));

// calcolo il costo le ore dei minuti
  ore := Trunc(QryOre.FieldByName('NMORE').AsFloat);
  minuti := Frac(QryOre.FieldByName('NMORE').AsFloat);
// divido le ore dai minuti
  QryOre.FieldByName('NMORE_1').AsFloat := ore;
  QryOre.FieldByName('NMMINUTI_1').AsFloat := minuti*100;

  QryOre.FieldByName('oremese').AsFloat:=AWait(OreMeseProgressive);
  QryOre.FieldByName('oremensilizzazione').AsFloat := AWait(OreMensilizzazione);

//calcolo le ore straordinarie se le ore lavoratte sono superiori delle ore di mensilizzazione
  if QryOre.FieldByName('oremese').AsFloat > QryOre.FieldByName('oremensilizzazione').AsFloat then
    begin
      QryOre.FieldByName('orestraordinarie').AsFloat:=
        QryOre.FieldByName('oremese').AsFloat - QryOre.FieldByName('oremensilizzazione').AsFloat;
    end
  else
    begin
      QryOre.FieldByName('orestraordinarie').AsFloat:=0;
    end;

// se le ore straordinarie sono maggiori di zero calcolo la maggiorazione
// se è sabato o domenica
  CalcoloOreStraordinarie;

  costoore := ore * QryOre.FieldByName('NMORAMAGGIORATO').AsFloat;
  costominuti := QryOre.FieldByName('NMORAMAGGIORATO').AsFloat / 60 * 100 * minuti;

  QryOre.FieldByName('NMCALCOLORIGA').AsFloat := costoore + costominuti;

  QryOre.FieldByName('DSNOTE').AsString := dsnote.value;

  QryOre.Post;
  QryOre.ApplyUpdates;

  ShowMessage('Inserimento eseguito con successo');

end;


procedure TFrmOre.btnModificaMovimento_click;
var
  data :TDateTime;
  mese :Integer;
  oremese, oregiorno :String;
  ore, minuti, costoore, costominuti:Currency;
begin

  Console.log('salva_click x3');

// modifico le ore
  QryOre.Close;
  QryOre.SQL.Text:=' select * from ore where (dsannullato is null or dsannullato = '''') ';
  AWait(QryOre.Open);

  if (idmovimento.value = '') or (not QryOre.Locate('idore', idmovimento.value, [])) then
    begin
      ShowMessage('Devi inserire un l''id corretto');
      console.log('id= ' + idmovimento.value);
      Exit;
    end;

  QryOre.Edit;
  QryOre.FieldByName('DTDATA').AsDateTime :=
    StrToDate(Copy(dtdata.value,9,2) +'/'+
           Copy(dtdata.value,6,2) +'/'+
           Copy(dtdata.value,1,4));

// cerco la descrizione della causale
    QryCausali.Close;
    QryCausali.SQL.Text := 'select * from ore_causali '
    + ' where idsocieta = ''MEL001'' '
    + ' and idcausale = ' + idcausale.value;
    AWait(QryCausali.Open);

  QryOre.FieldByName('IDCAUSALE').AsString := idcausale.value;;
  QryOre.FieldByName('DSCAUSALE').AsString := QryCausali.FieldByName('DSCAUSALE').AsString;

  QryOre.FieldByName('NMORE').AsFloat :=
   StrToFloat(stringReplace(nmore.value , '.',  ',' ,[rfReplaceAll, rfIgnoreCase]));

// calcolo il costo le ore dei minuti
  ore := Trunc(QryOre.FieldByName('NMORE').AsFloat);
  costoore := ore * QryOre.FieldByName('NMORAMAGGIORATO').AsFloat;
  minuti := Frac(QryOre.FieldByName('NMORE').AsFloat);
  costominuti := QryOre.FieldByName('NMORAMAGGIORATO').AsFloat / 60 * 100 * minuti;
// divido le ore dai minuti
  QryOre.FieldByName('NMORE_1').AsFloat := ore;
  QryOre.FieldByName('NMMINUTI_1').AsFloat := minuti*100;

  QryOre.FieldByName('oremese').AsFloat:=AWait(OreMeseProgressive);
  QryOre.FieldByName('oremensilizzazione').AsFloat := AWait(OreMensilizzazione);

//calcolo le ore straordinarie se le ore lavoratte sono superiori delle ore di mensilizzazione
  if QryOre.FieldByName('oremese').AsFloat > QryOre.FieldByName('oremensilizzazione').AsFloat then
    begin
      QryOre.FieldByName('orestraordinarie').AsFloat:=
        QryOre.FieldByName('oremese').AsFloat - QryOre.FieldByName('oremensilizzazione').AsFloat;
    end
  else
    begin
      QryOre.FieldByName('orestraordinarie').AsFloat:=0;
    end;

// se le ore straordinarie sono maggiori di zero calcolo la maggiorazione
// se è sabato o domenica
  CalcoloOreStraordinarie;

  costoore := ore * QryOre.FieldByName('NMORAMAGGIORATO').AsFloat;
  costominuti := QryOre.FieldByName('NMORAMAGGIORATO').AsFloat / 60 * 100 * minuti;

  QryOre.FieldByName('NMCALCOLORIGA').AsFloat := costoore + costominuti;

  QryOre.FieldByName('DSNOTE').AsString := dsnote.value;

  QryOre.Post;
  QryOre.ApplyUpdates;

  idmovimento.value := '';

  ShowMessage('Modifica eseguita con successo');

end;

procedure TFrmOre.btnAnnullaMovimento_click;
var
  data :TDateTime;
  mese :Integer;
  oremese, oregiorno :String;
  ore, minuti, costoore, costominuti:Currency;
begin

  Console.log('salva_click x2');

// inserisco le ore
  QryOre.Close;
  QryOre.SQL.Text:='select * from ore where (dsannullato is null or dsannullato = '''') ';
  AWait(QryOre.Open);

  if (idmovimento.value = '') or (not QryOre.Locate('idore', idmovimento.value, [])) then
    begin
      ShowMessage('Devi inserire un l''id corretto');
      Exit;
    end;

  QryOre.Edit;
  QryOre.FieldByName('dsannullato').AsString := 'A';

  QryOre.Post;
  QryOre.ApplyUpdates;

  idmovimento.value := '';

  ShowMessage('Annullamento eseguito con successo');

end;

procedure TFrmOre.btnTFrmSeleziona_click;
var
  datamin, datamax :String;
begin
  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  CalcolaTotali;

  // controllose i campi sono compilati
  if data1.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data1.focus;
    exit;
  end;
  if data2.value = '' then
  begin
    ShowMessage('Devi inserire la data');
    data2.focus;
    exit;
  end;

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := False;

      datamin := Copy(data1.value,9,2) +'.'+
        Copy(data1.value,6,2) +'.'+
        Copy(data1.value,1,4);
      datamax := Copy(data2.value,9,2) +'.'+
        Copy(data2.value,6,2) +'.'+
        Copy(data2.value,1,4);

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dtdata as data, nmore as ore, nmcalcoloriga as importo, dsnote as note '
      + ' from ore'
      + ' where idsocieta = ''MEL001'' '
      + ' and idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString
      + ' and (dsannullato is null or dsannullato = '''') '
      + ' and dtdata >= ' + '''' + datamin + ''''
      + ' and dtdata <= ' + '''' + datamax + ''''
      + ' order by dtdata desc ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDARTICOLO').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
//      self.taSql.value := 'OK! selezionato IDARTICOLO=' + QrySeleziona.FieldByName('IDARTICOLO').AsString
//      + ' DSARTICOLO=' + QrySeleziona.FieldByName('DSARTICOLO').AsString;
    end;
  end;
end;

procedure TFrmOre.idmovimento_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idore as id, dtdata as data, idcausale, dscausale, '
      + ' nmore as ore, dsnote as note '
      + ' from ore '
      + ' where idsocieta = ''MEL001'' '
      + ' and (dsannullato is null or dsannullato = '''') '
      + ' and idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString
      + ' and (dsannullato is null or dsannullato = '''') '
      + ' order by data desc ';
      AWait(QrySeleziona.Open);
      QrySeleziona.FieldByName('id').Visible:= false;
      QrySeleziona.FieldByName('idcausale').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      idmovimento.value := QrySeleziona.FieldByName('id').AsString;
      dtdata.value := Copy(QrySeleziona.FieldByName('data').AsString,7,4) +'-'+
        Copy(QrySeleziona.FieldByName('data').AsString,4,2) +'-'+
        Copy(QrySeleziona.FieldByName('data').AsString,1,2);
      idcausale.value := QrySeleziona.FieldByName('idcausale').AsString;
      nmore.value := QrySeleziona.FieldByName('ore').AsString;
      dsnote.value := QrySeleziona.FieldByName('note').AsString;
    end;
  end;

end;

procedure TFrmOre.btnCercaMovimenti_click;
begin

  Console.log('cerca va');
  pnlCerca.Visible := True;
  pnlDati.Visible := False;
  pnlAnnulla.Visible := False;
  btnannullamodificamovimento.Visible := False;

  CalcolaTotali;

end;

procedure TFrmOre.btnAnnullaModificaMovimento_click;
begin

  PnlAnnulla.Visible:=True;
  pnlCerca.Visible := False;
  btncercamovimenti.Visible := False;
  PnlSalva.Visible:=False;
  btnannullamodificamovimento.Visible:=False;
  pnlSpazio.Visible:=False;

  CalcolaTotali;

end;

procedure TFrmOre.btnChiudiCerca_click;
begin

  pnlDati.Visible := True;
  pnlCerca.Visible := False;
  btnannullamodificamovimento.Visible := True;

end;

procedure TFrmOre.btnVediMovimenti_click;
begin

  btnTFrmSeleziona_click;

end;

procedure TFrmOre.btnChiudiAnnulla_click;
begin

  pnlDati.Visible:=True;
  btnCercaMovimenti.Visible := True;
  btnannullamodificamovimento.Visible:=True;
  PnlSalva.Visible:=True;
  pnlSpazio.Visible:=True;
  PnlAnnulla.Visible:=False;

end;

procedure TFrmOre.idcausale_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select idcausale, dscausale from ore_causali '
       + ' order by dscausale ';
      AWait(QrySeleziona.Open);
//      QrySeleziona.FieldByName('IDDATICOMMESSA').Visible:= false;
//      QrySeleziona.FieldByName('IDSOCIETA').Visible:= false;
//      QrySeleziona.FieldByName('FLELABORATA').Visible:= false;

    end;

    if AWait(Show) = mrOK then
    begin
      idcausale.value := QrySeleziona.FieldByName('idcausale').AsString;
    end;
  end;
end;

procedure TFrmOre.CalcolaTotali;
var
  datamin, datamax :String;
begin

  datamin := Copy(data1.value,9,2) +'.'+
        Copy(data1.value,6,2) +'.'+
        Copy(data1.value,1,4);
  datamax := Copy(data2.value,9,2) +'.'+
        Copy(data2.value,6,2) +'.'+
        Copy(data2.value,1,4);

      QryTotaleOre.Close;
      QryTotaleOre.SQL.Text:= 'select sum(nmore) as totaleore, sum(nmcalcoloriga) as importo '
      + ' from ore'
      + ' where idcollaboratore = ' + user.QryUtenti.FieldByName('idcollaboratore').AsString
      + ' and (dsannullato is null or dsannullato = '''') '
      + ' and dtdata >= ' + '''' + datamin + ''''
      + ' and dtdata <= ' + '''' + datamax + '''';
      AWait(QryTotaleOre.Open);

      totaleore.value := QryTotaleOre.FieldByName('totaleore').AsString;
      importo.value := QryTotaleOre.FieldByName('importo').AsString;

      window.localStorage[ClassName+'_data1'] := data1.value;
      window.localStorage[ClassName+'_data2'] := data2.value;

end;

//procedure TFrmOre.OreMeseProgressive;
function TFrmOre.OreMeseProgressive: Double; async;
var
  dtdata :TDateTime;
  datainizio,datafine :String;
  oremese :String;
  minuti, ore :Double;
begin

// calcolo le ore lavorate da inizio mese
  dtdata := QryOre.FieldByName('dtdata').AsDateTime;

  datainizio := DateToStr(StartOfTheMonth(dtdata));
  datainizio := Copy(datainizio,1,2) +'.'+
        Copy(datainizio,4,2) +'.' +
        Copy(datainizio,7,4);

//  datafine := DateToStr(EndOfTheMonth(dtdata));
  datafine := DateToStr(QryOre.FieldByName('dtdata').AsDateTime);
  datafine :=Copy(datafine,1,2) +'.'+
        Copy(datafine,4,2) +'.'+
        Copy(datafine,7,4);

  QryOreMeseProgressive.Close;
  QryOreMeseProgressive.SQL.Text := ' SELECT SUM(NMORE_1) AS ORE, SUM(NMMINUTI_1) AS MINUTI, ';
  QryOreMeseProgressive.SQL.Add(' trunc(SUM(NMMINUTI_1/60)) AS oredaminuti, ');
  QryOreMeseProgressive.SQL.Add(' (trunc(SUM(NMMINUTI_1/60)) + SUM(NMORE_1)) as oremese, ');
  QryOreMeseProgressive.SQL.Add(' (SUM(NMMINUTI_1) - (trunc(SUM(NMMINUTI_1/60)) * 60)) as minutiresto ' );
  QryOreMeseProgressive.SQL.Add(' FROM ORE WHERE IDSOCIETA = ''MEL001'' ');
  QryOreMeseProgressive.SQL.Add(' and (dsannullato is null or dsannullato = '''') ');
  QryOreMeseProgressive.SQL.Add(' and dtdata >= '  + '''' + datainizio + '''' );
  QryOreMeseProgressive.SQL.Add(' and dtdata <= '  + '''' + datafine + '''' );
  QryOreMeseProgressive.SQL.Add(' and idcollaboratore = ' + QryOre.FieldByName('idcollaboratore').AsString );
  AWait(QryOreMeseProgressive.Open);

  ore:=QryOreMeseProgressive.FieldByName('oremese').AsFloat;// + QryOre.FieldByName('nmore_1').AsFloat;
  minuti:=Round(QryOreMeseProgressive.FieldByName('minutiresto').AsFloat);// + QryOre.FieldByName('nmminuti_1').AsFloat;
  oremese := FloatToStr(ore) + ',' + FloatToStr(minuti);

  Result := StrToFloat(oremese);

end;

function TFrmOre.OreMensilizzazione: Double; async;
var
  dtdata :TDateTime;
  datainizio,datafine :String;
  gg :Integer;
begin

// calcolo le ore di mensilizzazione da inizio mese
  dtdata := QryOre.FieldByName('dtdata').AsDateTime;

  datainizio := DateToStr(StartOfTheMonth(dtdata));
  datainizio := Copy(datainizio,1,2) +'.'+
        Copy(datainizio,4,2) +'.' +
        Copy(datainizio,7,4);

  datafine := DateToStr(dtdata);
  datafine :=Copy(datafine,1,2) +'.'+
        Copy(datafine,4,2) +'.'+
        Copy(datafine,7,4);

  QryGGMensilizzazione.Close;
  QryGGMensilizzazione.SQL.Add(' select count(dtdata) as giorni from tempo ');
  QryGGMensilizzazione.SQL.Add(' where flferiale = ''SI'' ' );
  QryGGMensilizzazione.SQL.Add(' and dtdata >= '  + '''' + datainizio + '''' );
  QryGGMensilizzazione.SQL.Add(' and dtdata <= '  + '''' + datafine + '''' );
  AWait(QryGGMensilizzazione.Open);

  QryGGMensilizzazione.SQL.Text:='';

  QryCollaboratori.Close;
  QryCollaboratori.SQL.Text := ' SELECT IDCOLLABORATORE, DSCOGNOME, DSNOME, OREGGMENSILIZZAZIONE '
  + ' FROM COLLABORATORI WHERE IDSOCIETA = ''MEL001'' '
  + ' AND IDCOLLABORATORE = ' + QryOre.FieldByName('idcollaboratore').AsString;
  AWait(QryCollaboratori.Open);

  Result := QryGGMensilizzazione.FieldByName('giorni').AsInteger
   * QryCollaboratori.FieldByName('oreggmensilizzazione').AsFloat;

end;

procedure TFrmOre.CalcoloOreStraordinarie;
var
  gg :Integer;
begin

// aggiungo la maggiorazione se è sabato o domenica
//  if QryOre.FieldByName('orestraordinarie').AsFloat > 0 then
//    begin
      gg := DayOfWeek(QryOre.FieldByName('dtdata').AsDateTime);

      if gg = 7 then
         begin
           QryOre.FieldByName('nmmaggiorazione').AsFloat := 30;
           QryOre.FieldByName('nmoramaggiorato').AsFloat := QryOre.FieldByName('nmcostoora').AsFloat +
             (QryOre.FieldByName('nmcostoora').AsFloat * QryOre.FieldByName('nmmaggiorazione').AsFloat / 100);
         end
       else
       if gg = 1 then
         begin
           QryOre.FieldByName('nmmaggiorazione').AsFloat := 50;
           QryOre.FieldByName('nmoramaggiorato').AsFloat := QryOre.FieldByName('nmcostoora').AsFloat +
             (QryOre.FieldByName('nmcostoora').AsFloat * QryOre.FieldByName('nmmaggiorazione').AsFloat / 100);
         end
       else
       if ((gg <> 1) or (gg <> 7)) then
         begin
           QryOre.FieldByName('nmmaggiorazione').AsFloat := 0;
           QryOre.FieldByName('nmoramaggiorato').AsFloat := QryOre.FieldByName('nmcostoora').AsFloat;
         end;
//    end
//    else
//      begin
//        QryOre.FieldByName('nmmaggiorazione').AsFloat := 0;
//        QryOre.FieldByName('nmoramaggiorato').AsFloat := QryOre.FieldByName('nmcostoora').AsFloat;
//      end;

end;

initialization
  RegisterWeb(TFrmOre);

end.
