unit f_notemodifica;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmNoteModifica }

  TFrmNoteModifica = class(TWidget)
    nuovorecord :String;
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idnota:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private
    primaVolta :boolean;
  published
    procedure dscategoria_click; async;
    procedure CompilaCampi; async;
    procedure ControlloCompilazioneCampi; async;
    QrySeleziona: TQuery;
    QryNote: TQuery;
    qryproduzione: TQuery;
    dscategoria: TJSHTMLSelectElement;
    dstitolo: TJSHTMLSelectElement;
    dsnota: TJSHTMLSelectElement;
    flstato: TJSHTMLSelectElement;
    pnlstato: TJSHTMLSelectElement;

  end;

implementation

{$R *.html}

{ TFrmNoteModifica }

procedure TFrmNoteModifica.AfterRender;
begin

  primaVolta := true;

end;

procedure TFrmNoteModifica.AfterDomAppend;
begin

  if primaVolta then
    begin
      Await(CompilaCampi);
    end;

  primaVolta := false;

end;

procedure TFrmNoteModifica.salva_click(Event: TJSEvent);
begin

  ControlloCompilazioneCampi;

  QryNote.edit;
  QryNote.FieldByName('DSCATEGORIA').AsString := dscategoria.value;
  QryNote.FieldByName('DSTITOLO').AsString := dstitolo.value;
  QryNote.FieldByName('DSNOTA').AsString := dsnota.value;
  QryNote.FieldByName('FLSTATO').AsString := flstato.value;
  QryNote.Post;
  QryNote.ApplyUpdates;

  ShowMessage('Modifica eseguita con successo');

  Close();

end;

procedure TFrmNoteModifica.chiudi_click(Event: TJSEvent);
begin

  Close;

end;

procedure TFrmNoteModifica.CompilaCampi;
begin

  console.log('idnota prima = ' + IntToStr(idnota));

  QryNote.Close;
  QryNote.SQL.Text:= ' SELECT * '
                        + ' from no_note  '
                        + ' where idnota = ' + IntToStr(idnota);
  AWait(QryNote.Open);

  console.log('idnota dopo = ' + IntToStr(idnota));

  dscategoria.value := qrynote.FieldByName('dscategoria').AsString;
  dstitolo.value := qrynote.FieldByName('dstitolo').AsString;
  dsnota.value := qrynote.FieldByName('dsnota').AsString;
  flstato.value := qrynote.FieldByName('flstato').AsString;

  console.log('arrivo qui? ');

end;

procedure TFrmNoteModifica.ControlloCompilazioneCampi;
begin

  //   controllo se i campi sono compilati
    if dscategoria.value = '' then
      begin
        ShowMessage('Devi inserire la categoria');
        Exit;
      end;
    if dstitolo.value = '' then
      begin
        ShowMessage('Devi inserire il titolo');
        Exit;
      end;
    if dsnota.value = '' then
      begin
        ShowMessage('Devi inserire la nota');
        Exit;
      end;

end;

procedure TFrmNoteModifica.dscategoria_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dscategoria as categoria '
      + ' from no_categorie ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      dscategoria.value := QrySeleziona.FieldByName('categoria').AsString;
    end;
  end;

end;

initialization
  RegisterWeb(TFrmNoteModifica);

end.

