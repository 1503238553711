unit f_widgets;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, f_sqleditor, un_hotkey, un_generic, js,
  web;

type

  { TFrmWidgets }

  TFrmWidgets = class(TWidget)
    procedure AfterRender; override;
  published
    tab:TJSHTMLTableElement;
  end;

implementation

{$R *.html}

{ TFrmWidgets }

procedure TFrmWidgets.AfterRender;
var
  meta: TWidgetMeta;
  function AddRow(m:TWidgetMeta):TJSHTMLAnchorElement;
  var
      a: TJSHTMLAnchorElement;
  begin
    a := document.createElement('a') as TJSHTMLAnchorElement;
    a.href:= '#';
    a.innerHTML:= m.FClass.ClassName;

    a.addEventListener('click',TJSRawEventHandler(procedure(event:TJSEvent) begin
      console.log(m.FClass.ClassName);

            TWidget(TWidgetClass(m.FClass).Create).Show; // potrei fare await
            event.preventDefault;
        end));
    Result := a;
  end;

begin
  while tab.rows.length> 0 do
    tab.deleteRow(0);

  for meta in WidgetMetaMap.Values.Items do
    tab.insertRow(tab.rows.length).insertCell(0).append(AddRow(meta));
end;

initialization
  RegisterWeb(TFrmWidgets);
end.
