unit f_ordinedettaglio;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table;

type

  { TFrmOrdineDettaglio }

  TFrmOrdineDettaglio = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idordine:Integer;


  private
    procedure RefreshOrdineDettaglio; async;

  published
    QryOrdineDettaglio: TQuery;
    tab1:TWTable;

  end;

implementation

{$R *.html}


{ TFrmOrdineDettaglio }

procedure TFrmOrdineDettaglio.AfterRender;
begin

  console.log('TFrmOrdineDettaglio.AfterRender ' + IntToStr(idOrdine));

end;

procedure TFrmOrdineDettaglio.AfterDomAppend;
begin

  console.log('TFrmOrdineDettaglio.AfterDomAppend ' + IntToStr(idOrdine));

  RefreshOrdineDettaglio;

end;

procedure TFrmOrdineDettaglio.RefreshOrdineDettaglio;
begin

  QryOrdineDettaglio.Close;
  QryOrdineDettaglio.SQL.Text:= 'select * from ord_ordinimarketing '
  + ' where mel_idsocieta = ''MEL001'' '
  + ' and id_ordine = ' + IntToStr(idordine);
  AWait(QryOrdineDettaglio.Open);
  tab1.RenderDataSet(QryOrdineDettaglio);



end;

initialization
  RegisterWeb(TFrmOrdineDettaglio);

end.

