unit un_telemetria;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_servermodule, un_user,js,web;

procedure TmRegistraEvento(dsTipo: string;dsParametro:String='');

implementation

procedure TmRegistraEvento(dsTipo: string; dsParametro: String);
begin
  with ServerModule('TWebTelemetria') do
  begin
    Params['IDUSER'] := IntToStr(user.idUser);
    Params['DSTIPO'] := dsTipo;
    Params['DSPARAMETRO'] := dsParametro;
    Call('WebRegistraEvento');
  end;
end;

end.

