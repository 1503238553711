unit un_error;

{$mode objfpc}

interface

uses
  Classes, SysUtils,web;

procedure RaiseException(msg:string); inline;

implementation

procedure RaiseException(msg: string);
begin
  window.alert(msg);
  raise Exception.Create(msg);
end;


end.

