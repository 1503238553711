unit un_tquery;

{$mode objfpc}

interface

uses
  Classes, SysUtils, DB,  jsondataset,  sqldbrestdataset, js, web,un_error;


{ TQuery }
type
  TQuery = class(TSQLDBRestDataset)
  private
    FResolve: TJSPromiseResolver;
    FAfterOpenOriginal: TDataSetNotifyEvent;
    FAfterApplyUpdatesOriginal: TApplyUpdatesEvent;
    FOpening: Boolean;
    function OpenInternal: String; async;
    function OwnerName:String;
    procedure AfterOpenIntercept(DataSet: TDataSet);
    procedure LoadFailIntercept(DataSet: TDataSet; ID : Integer; Const ErrorMsg : String);
    procedure AfterApplyUpdatesIntercept(Sender : TDataset; info : TResolveResults);
    procedure PostErrorIntercept(DataSet: TDataSet; E: EDatabaseError;
      var DataAction: TDataAction);
    procedure _Do_Hack_on_FResourceName;

    function MyURL: String; override;
  public
    constructor Create(aOwner: TComponent); override;
    function Open:String; reintroduce; async;
    function ApplyUpdates: string; reintroduce; async;
    function ExecuteSql:Integer; async;
  end;


implementation

uses un_executesql;

//{$R *.lfm}

function GetTableName(sql:String):String;
var
  lines : TStringList;
  i:integer;
begin
  lines := TStringList.Create;
  lines.Delimiter:= ' ';
  lines.StrictDelimiter := true;
  lines.DelimitedText := sql.Replace(#10,' ');
  i := lines.Count - 1;
  while i>= 0 do
  begin
    if trim(lines[i]) = '' then
      lines.Delete(i);
    Dec(i);
  end;
  Result := trim( lines[ lines.IndexOf('FROM') + 1] );
  lines.free();
end;


{ TQuery }

function TQuery.OwnerName: String;
begin
  if Assigned(Owner) then
    Result := Owner.Name
  else
    Result := '[Owner is nil]';
end;

procedure TQuery.AfterOpenIntercept(DataSet: TDataSet);
var
  AResolver: TJSPromiseResolver;
begin
  AfterOpen := FAfterOpenOriginal;
  FAfterOpenOriginal := nil;

  AResolver := FResolve;
  Assert(AResolver <> nil, 'FResolver should not be nil');
  if AfterOpen <> nil then
    AfterOpen(DataSet);

  // set primary key
  DataSet.Fields[0].ProviderFlags := DataSet.Fields[0].ProviderFlags + [pfInKey];
  AResolver('');
end;

procedure TQuery.LoadFailIntercept(DataSet: TDataSet; ID: Integer;
  const ErrorMsg: String);
begin
  console.log(format('LoadFailed query=[%s.%s] err=[%s] id=[%d] '#13'sql: [%s]',[OwnerName,Name ,ErrorMsg,id,SQL.Text]) );
  FResolve(IntToStr(ID) +'-'+  ErrorMsg);
end;
procedure TQuery.PostErrorIntercept(DataSet: TDataSet; E: EDatabaseError;
  var DataAction: TDataAction);
begin
  RaiseException( format('PostError query=[%s.%s] err=[%s.%s] '#13'sql: [%s]',[OwnerName,Name ,e.ClassName, e.Message,SQL.Text]) );
end;

procedure TQuery.AfterApplyUpdatesIntercept(Sender : TDataset; info : TResolveResults);
var
  AResolver: TJSPromiseResolver;
begin
//  if info.Records;
  AfterApplyUpdates:=FAfterApplyUpdatesOriginal;
  FAfterApplyUpdatesOriginal := nil;
  AResolver := FResolve;
  Assert(AResolver <> nil, 'FResolver should not be nil');
  if AfterApplyUpdates <> nil then
    AfterApplyUpdates(sender,info);
  AResolver('');
end;

constructor TQuery.Create(aOwner: TComponent);
begin
  inherited Create(aOwner);
  FAfterOpenOriginal := nil;
  FAfterApplyUpdatesOriginal := nil;
  FOpening := false;
end;
function TQuery.Open: String; async;
var
  res:String;
begin
  if FOpening then raise Exception.Create('Query gia in apertura ' + Name);
  FOpening := true;
  res := await(OpenInternal);
  FOpening := false;
  if res <> '' then
    raise Exception.Create(res)
  else
    Result := '';
end;

function TQuery.OpenInternal: String; async;
var
  promise: TJSPromise;
begin
  FAfterOpenOriginal := AfterOpen;
  AfterOpen := @AfterOpenIntercept;
  Assert(OnLoadFail=nil);
  OnLoadFail:= @LoadFailIntercept;
  OnPostError:=@PostErrorIntercept;

  FResolve := nil;
  promise := TJSPromise.new(procedure (resolve, reject: TJSPromiseResolver)
    begin
      FResolve := resolve;
      Load([], nil);
    end);

  exit( promise );

end;

procedure TQuery._Do_Hack_on_FResourceName;
var
  s:TQuery;
  tableName:String;
begin
  // this will avoid all the code in SetResourceName
  s := Self;
  tableName := GetTableName(SQL.Text);
  asm
    s.FResourceName = tableName;
  end;
end;
function TQuery.ApplyUpdates: string; async;
begin
  if length(GetPendingUpdates) = 0 then
    exit;
  FAfterApplyUpdatesOriginal:= AfterApplyUpdates;

  AfterApplyUpdates := @AfterApplyUpdatesIntercept;
  exit( TJSPromise.new(procedure (resolve, reject: TJSPromiseResolver)
     begin
       FResolve := resolve;
       _Do_Hack_on_FResourceName;
       inherited ApplyUpdates;
     end));
end;

function TQuery.ExecuteSql: Integer; async;
var
  u:String;
  db:String;
begin
  u := 'http://example.com/' + CustomViewResourceName;
  asm
    let u1 = new URL(u);
    db = u1.searchParams.get('DB');
    if (db==null) db = '';
  end;

  un_executesql.ExecuteSql(SQL.CommaText,db);
end;

function TQuery.MyURL: String;
var
  sep:string;
begin
  sep := '?';
  Result:=DatabaseConnection;
  if (Result<>'') and (Result[Length(Result)]<>'/') then
    Result:=Result+'/';
  Result:=Result+ResourceName;
  if pos('?',Result)>= 1 then
    sep := '&';
  if SameText(ResourceName,CustomViewResourceName) then
    Result:=Result+sep +'SQL='+ EncodeURIComponent(CleanSQL);
end;

end.
