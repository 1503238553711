unit un_user;

{$mode objfpc}

interface

uses
  Classes, SysUtils, web, js, un_bindquery, un_error, db;

type

  { TUser }

  TUser = class(TComponent)
      FQryUtenti: TDataSet;
    public
      function AuthOk:Boolean;
      function QryUtenti: TDataSet;
      function IdUtente:String;
      function IdUser:Integer;
      username:String;
      password:String;
      function CredentialsOk:Boolean; async;
      constructor Create(AOwner: TComponent); reintroduce;
  end;


var
  user: TUser;


implementation

uses un_tquery;

{ TUser }

function TUser.AuthOk: Boolean;
begin
  Result := Assigned(FQryUtenti);
end;

function TUser.QryUtenti: TDataSet;
begin
  if not Assigned(FQryUtenti) then
    RaiseException('Utente non disponibile. Autenticazione ancora non effettuata');
  Result := FQryUtenti;
end;

function TUser.IdUtente: String;
begin
  Result := QryUtenti.FieldByName('IDUTENTE').AsString;
end;

function TUser.IdUser: Integer;
begin
  Result := QryUtenti.FieldByName('IDUSER').AsInteger;
end;

function TUser.CredentialsOk: Boolean; async;
var
  qry: TQuery;
begin
  //per verificare se le credenziali sono correte, apriamo una query
  qry := NewTQuery(nil);
  qry.Close;
  qry.SQL.Text:= format('SELECT * from UTENTI WHERE DSUSERNAME = ''%s'' ',[username]);
  try
    await(qry.Open);
    FQryUtenti := qry;
  except on ex:Exception do
    begin
       FQryUtenti := nil;
       console.log(format('Errore auth query.open [%s]',[ex.message]));
    end;
  end;
  Result := AuthOk;
end;

constructor TUser.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
end;

initialization
  user := TUser.Create(nil);
end.

