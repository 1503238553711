unit un_commandline;

{$mode objfpc}

interface

uses
  Classes, SysUtils, web, js;

type
  TCommandLineSwitches = record
    Username: string;
    Password: string;
    InvokeClass: string;
    InvokeProc: string;
    Manager:String;
    Params: TStringList;
  end;

procedure Parse;

var
  CommandLineSwitches: TCommandLineSwitches;


implementation


procedure Parse;
var
  line: string;
  params: TStringList;
  procedure Decode(name:string;var p);
  begin
    p := params.Values[name];
  end;
begin
  line := window.location.hash.Substring(1);
  params := CommandLineSwitches.Params;
  params.Delimiter := '&';
  params.DelimitedText := line;
  console.log('hash=' + line);
  Decode('username',CommandLineSwitches.Username);
  Decode('password',CommandLineSwitches.password);
  Decode('class',CommandLineSwitches.InvokeClass);
  Decode('proc',CommandLineSwitches.InvokeProc);
  Decode('manager',CommandLineSwitches.manager);
end;

initialization
  CommandLineSwitches.Params := TStringList.Create;
  Parse;

end.
