unit f_produzionemodificaimporti;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator,
  f_selezione, un_executesql;

type

  { TFrmProduzioneModificaImporti }

  TFrmProduzioneModificaImporti = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idproduzione:integer;
    idvalore:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure elimina_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private
    primaVolta :boolean;
  published
    procedure CompilaCampi; async;
    procedure ModificaDtVendita; async;
    QryProduzioneModificaImporti: TQuery;
    QryDtVendita: TQuery;
    QryControlloDataDuplicata: TQuery;
    QryAggiornaProduzione: TQuery;
    dtvendita : TJSHTMLInputElement;
    nmquantita: TJSHTMLSelectElement;
    nmprezzoacquisto: TJSHTMLSelectElement;
    nmprezzovendita: TJSHTMLSelectElement;
    idvalido: TJSHTMLSelectElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneModificaImporti }

procedure TFrmProduzioneModificaImporti.AfterRender;
begin

  primaVolta := true;

end;

procedure TFrmProduzioneModificaImporti.AfterDomAppend;
begin

  if (idproduzione > 0) and primaVolta then
  begin
    Await(CompilaCampi);
  end;

  primaVolta := false;

end;

procedure TFrmProduzioneModificaImporti.CompilaCampi;
var
  dt1 :Tdate;
  dt2 :String;
begin

// precompilo il dati per la modifica dello step
  QryProduzioneModificaImporti.Close;
  QryProduzioneModificaImporti.SQL.Text:= ' select * from pro_valori '
                        + ' where idproduzione = ' + IntTostr(idproduzione )
                        + ' and idvalore = ' + IntToStr(idvalore);
  AWait(QryProduzioneModificaImporti.Open);

  dt1 := QryProduzioneModificaImporti.FieldByName('dtvendita').AsDateTime;
  dt2 := dateToStr(dt1);

  dtvendita.value := Copy(dt2,7,4) +'-'+ Copy(dt2,4,2) +'-'+ Copy(dt2,1,2);
  nmquantita.value := QryProduzioneModificaImporti.FieldByName('nmquantita').AsString;
  nmprezzoacquisto.value := QryProduzioneModificaImporti.FieldByName('nmprezzoacquisto').AsString;
  nmprezzovendita.value := QryProduzioneModificaImporti.FieldByName('nmprezzovendita').AsString;
  idvalido.value := QryProduzioneModificaImporti.FieldByName('FLVALIDO').AsString;

end;

procedure TFrmProduzioneModificaImporti.ModificaDtVendita;
begin

// cerco la data di vendita più vicina netta tabella prezzi
    QryDtVendita.Close;
    QryDtVendita.SQL.Text:= ' select min(dtvendita) as dtvendita from pro_valori '
    + ' WHERE idproduzione = ' + IntToStr(idproduzione)
    + ' AND flvalido = ''SI'' ';
    AWait(QryDtVendita.Open);

// aggiorno la data di vendita nella tabella produzione
    QryAggiornaProduzione.Close;
    QryAggiornaProduzione.SQL.Text:= ' select * from pro_produzione '
    + ' WHERE idproduzione = ' + IntToStr(idproduzione);
    AWait(QryAggiornaProduzione.Open);

    QryAggiornaProduzione.Edit;
    QryAggiornaProduzione.FieldByName('dtscadenza').AsString:=QryDtVendita.FieldByName('dtvendita').AsString;
    QryAggiornaProduzione.Post;
    await(QryAggiornaProduzione.ApplyUpdates);

end;

procedure TFrmProduzioneModificaImporti.salva_click(Event: TJSEvent);
var
  vend :String;
begin

//   controllo se i campi sono compilati
    vend := DateToStr(date);
    vend := Copy(vend,7,4) +'-'+ Copy(vend,4,2) +'-'+ Copy(vend,1,2);

//   controllo se i campi sono compilati
   if dtvendita.value <= vend then
    begin
      ShowMessage('La scadenza non può essere inferiore o uguale ad oggi');
      Exit;
    end;
  if dtvendita.value = '' then
  begin
    ShowMessage('Devi inserire la scadenza');
    Exit;
  end;
  if nmquantita.value = '' then
    begin
      ShowMessage('Devi inserire la quantità');
      Exit;
    end;
  if nmprezzoacquisto.value = '' then
    begin
      ShowMessage('Devi inserire il prezzo di acquisto');
      Exit;
    end;
  if nmprezzovendita.value = '' then
    begin
      ShowMessage('Devi inserire le note per il processo');
      Exit;
    end;

  // modifico il processo
  QryProduzioneModificaImporti.edit;
  QryProduzioneModificaImporti.FieldByName('dtvendita').AsDateTime := StrToDate(Copy(dtvendita.value,9,2) +'/'+
      Copy(dtvendita.value,6,2) +'/'+ Copy(dtvendita.value,1,4));
  QryProduzioneModificaImporti.FieldByName('nmquantita').AsString := nmquantita.value;
  QryProduzioneModificaImporti.FieldByName('nmprezzoacquisto').AsString := nmprezzoacquisto.value;
  QryProduzioneModificaImporti.FieldByName('nmprezzovendita').AsString := nmprezzovendita.value;
  if ((nmquantita.value <> '') and (nmprezzoacquisto.value <> '')
     and (nmprezzovendita.value <> '')) then
    begin
      QryProduzioneModificaImporti.FieldByName('nmtotalecosto').AsString := FloatToStr(StrToFloat(nmquantita.value)
         * StrToFloat(nmprezzoacquisto.value));
      QryProduzioneModificaImporti.FieldByName('nmtotalericavo').AsString := FloatToStr(StrToFloat(nmquantita.value)
        * StrToFloat(nmprezzovendita.value));
      QryProduzioneModificaImporti.FieldByName('nmtotaleutile').AsString := FloatToStr(QryProduzioneModificaImporti.FieldByName('nmtotalericavo').AsFloat -
        QryProduzioneModificaImporti.FieldByName('nmtotalecosto').AsFloat);
    end;
  QryProduzioneModificaImporti.FieldByName('FLVALIDO').AsString := idvalido.value;

// controllo se il giorno è già stato inserito
  if idvalido.value = 'SI' then
    begin
      QryControlloDataDuplicata.Close;
      QryControlloDataDuplicata.SQL.Text:=' select * from pro_valori '
      + ' WHERE idproduzione = ' + IntToStr(idproduzione)
      + ' and dtvendita = ''' + dtvendita.value + '''';
      AWait(QryControlloDataDuplicata.Open);

      if QryControlloDataDuplicata.RecordCount > 0 then
        begin
          ShowMessage('I prezzi per questo giorno sono già stati inseriti...');
          exit;
       end;
    end;

  QryProduzioneModificaImporti.Post;
  await(QryProduzioneModificaImporti.ApplyUpdates);

  ModificaDtVendita;

  ShowMessage('Inserimento eseguito con successo');

  Close();

end;

procedure TFrmProduzioneModificaImporti.elimina_click(Event: TJSEvent);
begin

  QryProduzioneModificaImporti.Close;
  QryProduzioneModificaImporti.SQL.Text:= ' delete from pro_valori '
                        + ' where idproduzione = ' + IntTostr(idproduzione)
                        + ' and idvalore = ' + IntToStr(idvalore);
  AWait(QryProduzioneModificaImporti.ExecuteSql);

  ModificaDtVendita;

  Close();

end;

procedure TFrmProduzioneModificaImporti.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

initialization
  RegisterWeb(TFrmProduzioneModificaImporti);

end.

