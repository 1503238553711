unit un_widget;

{$mode objfpc}

interface

uses
  Classes, SysUtils, JS, web, un_httprequest, un_generic, typinfo, un_tquery,
  un_bindquery, un_error, un_web;

const
  // Used for ModalResult
  mrNone = 0;
  mrOK = mrNone + 1;
  mrCancel = mrNone + 2;
  mrAbort = mrNone + 3;
  mrRetry = mrNone + 4;
  mrIgnore = mrNone + 5;
  mrYes = mrNone + 6;
  mrNo = mrNone + 7;
  mrAll = mrNone + 8;
  mrNoToAll = mrNone + 9;
  mrYesToAll = mrNone + 10;
  mrClose = mrNone + 11;
  mrLast = mrClose;

  procedure LoadAllResources; async;
  procedure ShowMessage(msg:String);

type
  TJSHTMLElementArray = Array of TJSHTMLElement;
  TWidget = class;
  TWidgetList = specialize TList<TWidget>;

  { TWidget }

  type TRefProc = reference to procedure ;

  type TWidget = class(TComponent)
  private
    FWaitClose:TJSPromiseResolver;
    FElementInstance: TJSHtmlElement;
    FElementsMap: TStringList;
    FParent: TWidget;
    FChildren: TWidgetList;
    procedure CreateCommon;
    function ElementExists(id: string): boolean;
    function GetParent: TWidget;
    procedure Expand;
    function GetVisible: boolean;
    procedure SetVisible(AValue: boolean);
    class function TagName(AClass: TClass): string;
    function TagName: string;
    procedure Render; virtual;
  protected
    property Parent:TWidget read GetParent ;
    function GetElement(const id: string): TJSHTMLElement;
    function GetWidget(const id: string): TWidget;
    function ResourceContent:String;
    procedure Acquire(ANodes:TJSHTMLElementArray);
    function NeedRename(id:string):Boolean;
    procedure Rename(originalId:string;element:TJSHTMLElement);
    function Rename(originalId:string):String;
    procedure BindAll;
    procedure Close(widget: TWidget); virtual; async;
    procedure Show(widget: TWidget); virtual; async;
    procedure AfterDomAppendInternal; async; virtual;
  public
    constructor Create; reintroduce;
    constructor Create(existingElement:TJSHTMLElement); reintroduce;
    property Visible: boolean read GetVisible write SetVisible;
    function Show:Integer; async;
    procedure AfterDomAppend; async; virtual;
    procedure Close(modalResult:Integer=mrNone); async;
    procedure Bind(const id:string;var el); overload;
    procedure Bind(const id, event: String; handler: TRefProc); overload;
    property ElementInstance: TJSHtmlElement read FElementInstance;
    procedure AfterRender; virtual;
    procedure AfterClose; virtual;
  end;


  type TWidgetClass = class of TWidget;

  Procedure RegisterWeb(AClass : TWidgetClass);

  type

    { TWidgetMeta }

  TWidgetMeta = class
    constructor Create;
   FClass: TWidgetClass;
   FCounter: integer;
   FResourceContent:String;
   function tic:TTypeInfoClass;
   procedure LoadResource;async;
  end;

 TJSHTMLElementList = specialize TList<TJSHTMLElement>;
 TWidgetMetaMap = specialize TSMap<TWidgetMeta>;



  { TJSHTMLButtonElementHelper }

  TJSHTMLButtonElementHelper = class helper for TJSHTMLButtonElement
  private
    function GetDisabled: Boolean;
    procedure SetDisabled(AValue: Boolean);
  public
     property Disabled: Boolean read GetDisabled write SetDisabled;
  end;

    { TJSHTMLElementHelper }

  TJSHTMLElementHelper = class helper for TJSHTMLElement
  private
    function GetVisible: Boolean;
    procedure SetVisible(AValue: Boolean);
  public
     property Visible: Boolean read GetVisible write SetVisible;
  end;
  { TJSElementHelper }

  TJSElementHelper = class helper for TJSElement
    public
      procedure widgetInstanceSet(AWidget: TWidget);
      function WidgetInstanceGet:TWidget;
  end;

  { TJSHTMLCollectionHelper }

  TJSHTMLCollectionHelper = class helper for TJSHTMLCollection
    function AsList: TJSHTMLElementArray;
  end;

  function GetWidgetMeta(className:String):TWidgetMeta;
var
  WidgetMetaMap :TWidgetMetaMap;
  DefaultManager:TWidget = nil;

implementation

function GetWidgetMeta(className:String):TWidgetMeta;
begin
  Result := WidgetMetaMap.get(className);
  if Result = nil then
    RaiseException(format('GetWidgetMeta className not found [%s]. No RegisterWeb(%s) was done ',[className,className]));
end;

procedure RegisterWeb(AClass: TWidgetClass);
var
  meta : TWidgetMeta;
begin
  meta := TWidgetMeta.Create;
  meta.FClass := AClass;
  WidgetMetaMap.put(AClass.ClassName,meta);
end;


procedure LoadAllResources;
var
  meta:TWidgetMeta;
  ele: TJSHTMLObjectElement;
  link: TJSElement;
  id, content: String;
begin
  ele := document.getElementById('objresources') as TJSHTMLObjectElement;

  for meta in WidgetMetaMap.Values.Items do
  begin
    id := 'resource-'+ meta.tic.Module.Name;
    link :=  ele.contentDocument.getElementById(id) ;
    if link = NULL then
    begin
      RaiseException(format('Risorsa non trovata per [%s]'+
      #13'Hai forse dimenticato {$R *.html} appena dopo implementation?'
      ,[meta.tic.Module.Name]));
    end else begin
      content := link.getAttribute('href');
      content := content.Substring(content.IndexOf(',')+1);
      content := atob(content);
      meta.FResourceContent:= content;
    end;
  end;
  document.body.removeChild(ele);
end;

procedure ShowMessage(msg: String);
begin
  window.alert(msg);
end;

function NextInstance(AClass: TClass):string;
var
  name: String;
  meta: TWidgetMeta;
begin
  name := AClass.ClassName;
  meta := WidgetMetaMap.get(AClass.ClassName);
  inc(meta.FCounter);
  Result := format('%s%d',[name,meta.FCounter]);
end;

function IsWidgetTagName(element:TJSElement):Boolean;
begin
  Result := element.tagName.ToUpper.StartsWith('W-');
end;

{ TJSHTMLButtonElementHelper }

function TJSHTMLButtonElementHelper.GetDisabled: Boolean;
begin
   RaiseException('TJSHTMLButtonElementHelper.GetDisabled');
end;

procedure TJSHTMLButtonElementHelper.SetDisabled(AValue: Boolean);
begin
  if AValue then
  begin
    setAttribute('disabled','');
    innerHTML += ' ...';
  end else
  begin
    removeAttribute('disabled');
    innerHTML:= innerHTML.Remove(length(innerHTML)-4);
  end;
end;

{ TJSHTMLElementHelper }

function TJSHTMLElementHelper.GetVisible: Boolean;
begin
  Result := not( style.getPropertyValue('display') = 'none' );
end;

procedure TJSHTMLElementHelper.SetVisible(AValue: Boolean);
begin
  if AValue then
    style.removeProperty('display')
  else
    style.setProperty('display','none');
end;

{ TWidgetMeta }

constructor TWidgetMeta.Create;
begin
  FCounter := 0;
end;

function TWidgetMeta.tic: TTypeInfoClass;
begin
  Result := TTypeInfoClass(FClass.ClassInfo);
end;

procedure TWidgetMeta.LoadResource;
var
  name: String;
  datatemp:string;
begin
  name := TTypeInfoClass(FClass.ClassInfo).Module.Name;
  console.log('Loading resource ' +  name);
  datatemp := await(fetch(name));
  if datatemp <> NULL then
    FResourceContent := datatemp
  else
    RaiseException('fetching ' + name);
end;
function TJSHTMLCollectionHelper.AsList: TJSHTMLElementArray;
var
  i: Integer;
begin
  SetLength(result,self.length);
  for i:= 0 to self.length-1 do
    Result[i] := TJSHTMLElement(self.Items[i]);
end;

procedure TWidget.Acquire(ANodes: TJSHTMLElementArray);
var
  toExpand:TJSHTMLElementList;

  procedure AcquireElement(element: TJSHTMLElement);
  var
    originalId :String;
  begin
    originalId := element.id;
    if originalId = '' then
      originalId:=element.getAttribute('w-id');
     if needRename(originalId) then
     begin
        FElementsMap.AddObject(originalId, TObject(element) );
        Rename(originalId,element);
     end;
  end;

  procedure AcquireRecursive(const nodes: TJSHTMLElementArray);
  var
    n: TJSHTMLElement;
  begin
     for n in nodes do
     begin
        AcquireElement(n);
        if not IsWidgetTagName(n) then
          AcquireRecursive(n.children.AsList)
        else
          toExpand.Add(n);
     end;
  end;
var
  n: TJSHTMLElement;
begin
  toExpand := TJSHTMLElementList.Create;
  AcquireRecursive(ANodes);
  for n in toExpand.Items do
    FChildren.add( n.WidgetInstanceGet() );
  //toExpand.foreach { it.widgetInstance }

end;

function TWidget.NeedRename(id: string): Boolean;
begin
  Result := (id <> NULL) and (id <> '') and (not id.StartsWith(Name+'_'));
end;

procedure TWidget.Rename(originalId: string; element: TJSHTMLElement);
begin
  if NeedRename(originalId) then
  begin
    element.id := Rename( originalId );
    element.setAttribute('w-id',originalId);
  end;
end;

function TWidget.Rename(originalId: string): String;
begin
  Result := Name + '_' + originalId;
end;

function TWidget.ElementExists(id:string):boolean;
begin
  Result :=  FElementsMap.IndexOf(id) >= 0 ;
end;

procedure TWidget.BindAll;
var
  parts:TStringList;
  ic: TTypeInfoClass;
  field: TTypeMemberField;
  i: Integer;
  fieldName, fieldType, tqName: String;
  qry: TQuery;
  method: TTypeMemberMethod;
  mName, eleId, eventName:string;
  addr: Pointer;
  methodCallback: TJSRawEventHandler;
  ele: TJSHTMLElement;
  myself: TWidget;
  procedure bindJs2Pas(p:TWidget;jsInstance:TJSHTMLElement);
  var
    obj:TObject;
  begin
    obj := TOBject( jsInstance);
    if jsInstance.tagName.ToUpper.StartsWith('W-') then
    begin
      obj := jsInstance.WidgetInstanceGet;
    end;

    asm
       p[fieldName] = obj;
    end;
  end;
begin
  parts := TStringList.Create;
  ic := TTypeInfoClass(ClassInfo);
  //bind fields
  for i:= 0 to ic.FieldCount-1 do
  begin
    field := ic.GetField(i);
    fieldName := field.Name;
    fieldType := field.TypeInfo.Name;
    tqName := TQuery.ClassName;
    if ElementExists(fieldName) then
    begin
      bindJs2Pas(self,GetElement(fieldName));
    end else if fieldType = tqName then
    begin
       qry := NewTQuery(self);
       qry.Name:= fieldName;
        asm
          this[fieldName] = qry;
       end;
    end;
  end;

  //bind methods
  parts.Delimiter:= '_';
  for i:=0 to ic.MethodCount -1 do
  begin
    method := ic.GetMethod(i);
    mName := method.Name;
    parts.DelimitedText:= mName;
    if parts.Count = 2 then
    begin
      eleId := parts[0];
      eventName := parts[1];
      if ElementExists(eleId) then
      begin
        console.log(format('Event binding for %s.%s.%s',[name,eleId,eventName]));
        addr := self.MethodAddress(mName);
        ele := GetElement(eleId);
        myself := self;
        asm
          let cb = rtl.createCallback(myself,addr);
          ele.addEventListener(eventName, cb);
        end;
      end else
        console.warn('Attenzione! L''elemento ['+eleId + '] non trovato per il metodo '+ mName);
    end;
  end;
end;

procedure TWidget.Close(widget: TWidget);
begin

end;

procedure TWidget.Show(widget: TWidget);
begin

end;

procedure TWidget.AfterDomAppendInternal;
var
  c:TWidget;
begin
  Await(AfterDomAppend);
  for c in FChildren.Items do
      Await(c.AfterDomAppendInternal);
end;

function TWidget.Show: Integer; async;
var
  prom: TJSPromise;
begin
  await(Parent.Show(self));
  if Assigned(FWaitClose) then
    RaiseException(format('FWaitClose non dovrebbe essere assegnato. Significa che e'' stato chiamato lo show 2 volte senza che venisse chiamato il close',[]));
  prom:=  TJSPromise.new(procedure (resolve, reject: TJSPromiseResolver)
    begin
      FWaitClose := resolve;
    end);
  exit(prom);
end;

procedure TWidget.AfterDomAppend;
begin

end;

procedure TWidget.Close(modalResult: Integer);
begin
  if not Assigned(FWaitClose) then
    RaiseException(format('Close without show?',[]));
  Await(Parent.Close(self));
  FWaitClose(modalResult);
  FWaitClose := nil;
  AfterClose;
end;

function TWidget.GetElement(const id: string): TJSHTMLElement;
var
  idx: Integer;
  obj: TObject;
begin
  idx := FElementsMap.IndexOf(id);
  if idx< 0 then
    RaiseException(format('Bind failed: no element with name [%s] found in html in widget [%s]',[id,name]));
  obj := FElementsMap.Objects[idx];
  Result := TJSHTMLElement(obj);
end;

function TWidget.GetWidget(const id: string): TWidget;
begin
  Result := GetElement(id).WidgetInstanceGet;
end;

procedure TWidget.Bind(const id: string; var el);
begin
  el := GetElement(id);
end;

procedure TWidget.Bind(const id, event: String; handler: TRefProc);
var
  e:TJSHTMLElement;
begin
  e:= GetElement(id);
  e.AddEventListener(event,handler);
end;


{ TJSElementHelper }

procedure TJSElementHelper.widgetInstanceSet(AWidget: TWidget);
begin
  asm
  this['widget_instance'] = AWidget;
  end;
end;

function TJSElementHelper.WidgetInstanceGet: TWidget;
var
  instance:TWidget;
  meta: TWidgetMeta;
begin
  asm  Result = this['widget_instance']; end;
  if Result = NULL then
  begin
    meta := GetWidgetMeta(tagName.Substring(2));
    instance := meta.FClass.Create(self as TJSHTMLElement);
    widgetInstanceSet(instance);
  end;
  asm  Result = this['widget_instance']; end;
end;

{ TWidget }

function TWidget.GetParent: TWidget;
var
  cur: TJSElement;
begin
  if not Assigned(FParent) then
  begin
    cur := ElementInstance.parentElement;
    while Assigned(cur) do
    begin
      if IsWidgetTagName(cur) then
         FParent := cur.WidgetInstanceGet;
      cur := cur.parentElement;
    end;
  end;
  if not Assigned(FParent) then
    FParent := DefaultManager;
  Result := FParent;
end;

procedure TWidget.Expand;
begin
  {
    backingElement = element
    params = Params(elementInstance)

    Acquire(params.children)  //if I would want to include params ids
    render()
    renderDone = true
    Acquire(elementInstance.children.asList()) //this comes after, so no name clash can injure the integrity of this widget
    afterRenderFun.forEach { it.invoke() }
  }
  Render;
  Acquire( ElementInstance.children.asList );
  BindAll;
  AfterRender;
end;

function TWidget.GetVisible: boolean;
begin
  Result := ElementInstance.Visible;
end;

procedure TWidget.SetVisible(AValue: boolean);
begin
  ElementInstance.Visible := AValue;
end;

class function TWidget.TagName(AClass: TClass): string;
begin
  Result := Format('W-%s', [AClass.ClassName]).ToUpperInvariant;
end;

function TWidget.TagName: string;
begin
  Result := TagName(ClassType);
end;

procedure TWidget.Render;
var
  content: String;
begin
  content := ResourceContent;
  FElementInstance.innerHTML:= content;
end;

function TWidget.ResourceContent: String;
var
  meta: TWidgetMeta;
begin
  meta := WidgetMetaMap.get(ClassName);
  Result := meta.FResourceContent;
end;

procedure TWidget.CreateCommon;
begin
  FWaitClose := nil;
  FElementsMap := TStringList.Create;
  FChildren := TWidgetList.Create;
  FElementsMap.CaseSensitive:=false;
  Name := NextInstance(ClassType);
end;

constructor TWidget.Create;
begin
  inherited Create(nil);
  CreateCommon;
  FElementInstance := TJSHTMLElement(document.createElement(TagName));
  FElementInstance.widgetInstanceSet(self);
  Expand;
  console.log(format('created widget [%s]',[Name]));
end;

constructor TWidget.Create(existingElement: TJSHTMLElement);
begin
  inherited Create(nil);
  CreateCommon;
  FElementInstance := existingElement;
  FElementInstance.widgetInstanceSet(self);
  Expand;
end;

procedure TWidget.AfterRender;
begin
end;

procedure TWidget.AfterClose;
begin

end;

initialization
 // instances := TStringList.Create;
  //WebList := TJSObject.create(nil);
  WidgetMetaMap := TWidgetMetaMap.Create;
  WidgetMetaMap.items.CaseSensitive:=false;
end.


