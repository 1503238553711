unit f_stampe;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_servermodule, f_selezione;

type

  { TFrmStampe }

  TFrmStampe = class(TWidget)
    procedure AfterRender; override;
  private
    procedure RefreshLink;
  published
    link1: TJSHTMLAnchorElement;
    link2: TJSHTMLAnchorElement;
    idStampaTag: TJSHTMLInputElement;
    procedure cancella1_click(event: TJSEvent); async;
    procedure seleziona1_click(event: TJSEvent); async;
    procedure IdStampaTag_input(event: TJSEvent); async;
    procedure link2_click(event: TJSEvent); async;
  end;

implementation

{$R *.html}


{ TFrmStampe }

procedure TFrmStampe.AfterRender;
begin
end;
procedure TFrmStampe.RefreshLink;
begin
  with ServerModule('TWebStampe') do
  begin
    Proc := 'WebProcessa';
    Params['idStampa'] := idStampaTag.value;
    link1.innerHTML:= 'Clicca qui per aprire il pdf della stampa ' +  Params['idStampa'];
    link1.href := Url;
    link1.target := '_blank';
  end;
end;

procedure TFrmStampe.cancella1_click(event: TJSEvent);
begin
 idStampaTag.value:='';
end;

procedure TFrmStampe.seleziona1_click(event: TJSEvent);
begin
    with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select * from STAMPE where dsfilereport is not null order by IDSOCIETA, IDSTAMPA ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      idStampaTag.value := QrySeleziona.FieldByName('IDSTAMPA').AsString;
      RefreshLink;
    end;
  end;
end;

procedure TFrmStampe.IdStampaTag_input(event: TJSEvent);
begin
  RefreshLink;
end;

procedure TFrmStampe.link2_click(event: TJSEvent);
begin
  with ServerModule('TWebStampe') do
  begin
    Proc := 'WebProcessa';
    Params['idStampa'] := '34';
    Params['sql'] := 'SELECT * FROM CLIENTI WHERE DSRAGSOCIALE LIKE ''%SRL%%'' ';

    link2.href := Url;
    link2.target := '_blank';
  end;
end;

initialization
  RegisterWeb(TFrmStampe);

end.

