unit f_gestioneordine;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, un_hotkey, js,
  web, f_selezione, un_servermodule, DateUtils;

type

  { TFrmGestioneOrdine }

  TFrmGestioneOrdine = class(TWidget)
  private
    idstampa :String;
    procedure RefreshOrdini; async;
  published
    procedure AfterRender; override;
    procedure btnEsegui_click(Event: TJSEvent);
    procedure btnTFrmSeleziona_click; async;
    procedure cancella1_click(event: TJSEvent); async;
    procedure seleziona1_click(event: TJSEvent); async;
    procedure link2_click(event: TJSEvent); async;
    procedure link3_click(event: TJSEvent); async;
    function DateOk:Boolean;
    whereDate :String;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    idStato: TJSHTMLInputElement;
    btnstampa: TJSHTMLInputElement;
    link2: TJSHTMLAnchorElement;
    link3: TJSHTMLAnchorElement;
    QryOrdini: TQuery;
    tab1:TWTable;
  end;

implementation

{$R *.html}

{ TFrmGestioneOrdine }

procedure TFrmGestioneOrdine.AfterRender;
begin

  tab1.RenderDataSet(nil);
  idStato.value := 'INCASSATO';

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

end;

procedure TFrmGestioneOrdine.RefreshOrdini;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;
  window.localStorage[ClassName+'_data2'] := data2.value;

  if not DateOk then
    exit;

  QryOrdini.Close;
  QryOrdini.SQL.Text:= 'select data, numero, cliente, mel_dsstato as stato from ord_ordinimarketing '
  + ' where mel_idsocieta = ''MEL001'' '
  + ' and ' + whereDate;
  if idstato.value <> '' then
    begin
      QryOrdini.SQL.Text := QryOrdini.SQL.Text + ' and mel_dsstato = ' + '''' + idstato.value + ''''
    end;
  QryOrdini.SQL.Text := QryOrdini.SQL.Text + ' order by data desc';
  AWait(QryOrdini.Open);
  tab1.RenderDataSet(QryOrdini);

end;

procedure TFrmGestioneOrdine.btnEsegui_click(Event: TJSEvent);
begin

  RefreshOrdini;
  Event.preventDefault;

end;

procedure TFrmGestioneOrdine.btnTFrmSeleziona_click;
begin

  if QryOrdini.RecordCount < 1 then
  begin
    ShowMessage('prima di stampare devi eseguire la query...');
    exit;
  end;

  with TFrmSelezione.Create do
    begin
        OpenQrySeleziona := procedure async
        begin
          CloseForm := True;

          QrySeleziona.Close;
          QrySeleziona.SQL.Text:= 'select * from ord_ordinimarketing '
          + ' where numero = ''OIN2003046'' ';
          AWait(QrySeleziona.Open);
        end;

  if AWait(Show) = mrOK then
  begin

  end;
    end;

end;

procedure TFrmGestioneOrdine.cancella1_click(event: TJSEvent);
begin

 idStato.value:='';

end;

procedure TFrmGestioneOrdine.seleziona1_click(event: TJSEvent);
begin
    with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dsstato from ord_stati ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      idStato.value := QrySeleziona.FieldByName('DSSTATO').AsString;
    end;
  end;

end;

procedure TFrmGestioneOrdine.link2_click(event: TJSEvent);
begin

 if QryOrdini.RecordCount < 1 then
  begin
    ShowMessage('prima di stampare devi eseguire la query...');
    exit;
  end;

 with ServerModule('TWebStampe') do
  begin
    Params['idStampa'] := '81';
    Params['sql'] := ' select * from ord_ordinimarketing '
     + ' where mel_idsocieta = ''MEL001'' '
     + ' and ' + whereDate;
  if idstato.value <> '' then
    begin
     Params['sql'] := Params['sql'] + ' and mel_dsstato = ' + '''' + idstato.value + ''''
    end;
    Params['sql'] := Params['sql'] + ' order by data ';
    link2.href := Url;
    link2.target := '_blank';
  end;

end;

procedure TFrmGestioneOrdine.link3_click(event: TJSEvent);
begin

 if QryOrdini.RecordCount < 1 then
  begin
    ShowMessage('prima di stampare devi eseguire la query...');
    exit;
  end;

 with ServerModule('TWebStampe') do
  begin
    Params['idStampa'] := '89';
    Params['sql'] := ' select * from ord_ordinimarketing '
     + ' where mel_idsocieta = ''MEL001'' '
     + ' and ' + whereDate;
  if idstato.value <> '' then
    begin
     Params['sql'] := Params['sql'] + ' and mel_dsstato = ' + '''' + idstato.value + ''''
    end;
    Params['sql'] := Params['sql'] + ' order by data ';
    link3.href := Url;
    link3.target := '_blank';
  end;

end;

function TFrmGestioneOrdine.DateOk: Boolean;
var
  dt1, dt2: TDateTime;
  dt1str, dt2str :String;
begin
  Result :=  False;
  if data1.value = '' then
  begin
    ShowMessage('Compila la data da');
    data1.focus;
    exit;
  end;

  if data2.value = '' then
  begin
    ShowMessage('Compila la data a');
    data2.focus;
    exit;
  end;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value) ;
  dt2 := IncDay(dt2);
  dt1str := FormatDateTime('dd.MM.yyyy',dt1);
  dt2str := FormatDateTime('dd.MM.yyyy',dt2);

  whereDate := ' data >= ''' + dt1str + ''''
    + ' and data < ''' + dt2str + '''';


  Result := True;

end;

initialization
  RegisterWeb(TFrmGestioneOrdine);
end.
