unit f_previsionedettaglio;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table;

type

  { TFrmPrevisioneDettaglio }

  TFrmPrevisioneDettaglio = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idprevisione:Integer;


  private
    procedure RefreshPrevisioneDettaglio; async;

  published
    QryPrevisioneDettaglio: TQuery;
    tab1:TWTable;

  end;

implementation

{$R *.html}


{ TFrmPrevisioneDettaglio }

procedure TFrmPrevisioneDettaglio.AfterRender;
begin

  console.log('TFrmPrevisioneDettaglio.AfterRender ' + IntToStr(idprevisione));

end;

procedure TFrmPrevisioneDettaglio.AfterDomAppend;
begin

  console.log('TFrmPrevisioneDettaglio.AfterDomAppend ' + IntToStr(idprevisione));

  RefreshPrevisioneDettaglio;

end;

procedure TFrmPrevisioneDettaglio.RefreshPrevisioneDettaglio;
begin

//  QryPrevisioneDettaglio.SQL.Text := 'SELECT * FROM PRE_PREVISIONEDETTAGLIO WHERE ' + Societa.ClausolaSqlWhere;
//  QryPrevisioneDettaglio.SQL.Add(' AND IDCATEGORIA = :IDCATEGORIA ');
//  QryPrevisioneDettaglio.SQL.Add(' AND DSMESE = :DSMESE ');

  QryPrevisioneDettaglio.Close;
  QryPrevisioneDettaglio.SQL.Text:= 'select idprevisione, dsprodotto as art, pre_quantita as qta, '
  + ' pre_prezzounitario as pzzo, pre_importo as imp '
  + ' from pre_previsionedettaglio '
  + ' where idprevisione = ' + IntToStr(idprevisione);
  AWait(QryPrevisioneDettaglio.Open);
  QryPrevisioneDettaglio.FieldByName('idprevisione').Visible:= false;
  tab1.RenderDataSet(QryPrevisioneDettaglio);



end;

initialization
  RegisterWeb(TFrmPrevisioneDettaglio);

end.

