unit f_selezione;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, un_hotkey, js, web,DB;

  { TFrmSelezione }
type
  TFrmSelezione = class(TWidget)
  private
    procedure RefreshGrid;
    procedure QrySelezioneOnFilterRecord(DataSet: TDataSet; var Accept: Boolean);
  published
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    procedure tab1_rowclick;
    OpenQrySeleziona:TRefProc;
    messaggio:TJSHTMLElement;
    QrySeleziona: TQuery;
    tab1:TWTable;
    CloseForm:Boolean;
    cerca: TJSHTMLInputElement;
    procedure cerca_input;
    procedure pulisci_click;
  end;

implementation

{$R *.html}

{ TFrmSelezione }

procedure TFrmSelezione.AfterRender;
begin
  tab1.Header := false;
  QrySeleziona.OnFilterRecord := @QrySelezioneOnFilterRecord;
end;

procedure TFrmSelezione.AfterDomAppend;
var
  i: Integer;
begin
  messaggio.Visible := true;
  tab1.RenderDataSet(nil);


  AWait(OpenQrySeleziona());

  RefreshGrid;
  tab1.Header := true;
  messaggio.Visible := false;
end;

procedure TFrmSelezione.tab1_rowclick;
begin
  if CloseForm then
    Close(mrOK);
end;

procedure TFrmSelezione.RefreshGrid;
begin
  QrySeleziona.Filtered:= cerca.value.Trim <> '';
  QrySeleziona.First;
  tab1.RenderDataSet(QrySeleziona);
end;

procedure TFrmSelezione.QrySelezioneOnFilterRecord(DataSet: TDataSet;
  var Accept: Boolean);
var
  str:String;
  i: Integer;
begin
  Accept:= true;
  str := cerca.value.ToLowerInvariant;
  for i:= 0 to QrySeleziona.FieldCount-1 do
  begin
    if QrySeleziona.Fields[i].Visible and
       QrySeleziona.Fields[i].DisplayText.ToLowerInvariant.Contains(str) then
         exit;
  end;
  //nessun campo matcha la stringa cercata
  Accept := false;
end;

procedure TFrmSelezione.cerca_input;
begin
  RefreshGrid;
end;

procedure TFrmSelezione.pulisci_click;
begin
  cerca.value := '';
  RefreshGrid;
end;

initialization
  RegisterWeb(TFrmSelezione);
end.
