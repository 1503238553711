unit f_utiliti;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_servermodule;

type

  { TFrmUtiliti }

  TFrmUtiliti = class(TWidget)
    procedure AfterRender; override;
    procedure btnEseguiImporta_click; async;
  private

  published
    btnEseguiImporta: TJSHTMLButtonElement;
    giorni:TJSHTMLInputElement;
  end;

implementation

{$R *.html}


{ TFrmUtiliti }

procedure TFrmUtiliti.AfterRender;
begin
  //
end;

procedure TFrmUtiliti.btnEseguiImporta_click;
begin

  with ServerModule('TWebImportOrdiniMarketing') do
  begin

    if giorni.value = '' then
      begin
        ShowMessage('Devi indicare i giorni da importare...');
        exit;
      end;
    Params['giorni'] := giorni.value;
    Call('WebDownloadEImporta');
  end;

  console.log('TFrmUtiliti.btnEseguiImporta_click: Importazione terminata');

end;

initialization
  RegisterWeb(TFrmUtiliti);

end.

