unit f_noteinserisci;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione, un_user;

type

  { TFrmNoteInserisci }

  TFrmNoteInserisci = class(TWidget)
    nuovorecord :String;
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private

  published
    procedure dscategoria_click; async;
    QrySeleziona: TQuery;
    QryNote: TQuery;
    dscategoria: TJSHTMLSelectElement;
    dstitolo: TJSHTMLSelectElement;
    dsnota: TJSHTMLSelectElement;
    flstato: TJSHTMLSelectElement;
    pnlstato: TJSHTMLSelectElement;

  end;

implementation

{$R *.html}

{ TFrmNoteInserisci }

procedure TFrmNoteInserisci.AfterRender;
begin

  flstato.Visible:=false;

end;

procedure TFrmNoteInserisci.AfterDomAppend;
begin

//

end;

procedure TFrmNoteInserisci.salva_click(Event: TJSEvent);
begin

//controllo se i campi sono compilati
     if dscategoria.value = '' then
       begin
         ShowMessage('Devi inserire la categoria');
         Exit;
       end;
     if dstitolo.value = '' then
       begin
         ShowMessage('Devi inserire il titolo');
         Exit;
       end;
     if dsnota.value = '' then
       begin
         ShowMessage('Devi inserire la nota');
         Exit;
       end;

  QryNote.Close;
  QryNote.SQL.Text:= ' select * from no_note  ';
  AWait(QryNote.Open);

  QryNote.Append;
  QryNote.FieldByName('IDNOTA').AsInteger := await(GetNextId('NO_NOTE'));
  QryNote.FieldByName('IDSOCIETA').AsString := 'MEL001';
  QryNote.FieldByName('DTDATA').AsDateTime := StrToDate('21/10/2021');
  QryNote.FieldByName('DSCATEGORIA').AsString := dscategoria.value;
  QryNote.FieldByName('DSTITOLO').AsString := dstitolo.value;
  QryNote.FieldByName('DSNOTA').AsString := dsnota.value;
  QryNote.FieldByName('FLSTATO').AsString := 'APERTA';
  Console.log('utente = ' + user.QryUtenti.FieldByName('idutente').AsString);
  QryNote.FieldByName('IDUTENTE').AsString := user.QryUtenti.FieldByName('idutente').AsString;
  Console.log('utente = ' + QryNote.FieldByName('IDUTENTE').AsString);
  QryNote.Post;
  QryNote.ApplyUpdates;

  ShowMessage('Inserimento eseguito con successo');

  Close();

end;

procedure TFrmNoteInserisci.chiudi_click(Event: TJSEvent);
begin

  Close;

end;

procedure TFrmNoteInserisci.dscategoria_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dscategoria as categoria '
      + ' from no_categorie ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      dscategoria.value := QrySeleziona.FieldByName('categoria').AsString;
    end;
  end;

end;

initialization
  RegisterWeb(TFrmNoteInserisci);

end.

