unit f_test2;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget;

type

  { TFrmTest2 }

  TFrmTest2 = class(TWidget)
    procedure AfterRender; override;
  private
  published

  end;

implementation

{$R *.html}


{ TFrmTest2 }

procedure TFrmTest2.AfterRender;
begin

end;

initialization
  RegisterWeb(TFrmTest2);

end.

