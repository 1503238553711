unit w_max1;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget;

type

  { TWMax1 }

  TWMax1 = class(TWidget)
    procedure AfterRender; override;
  private
  published
    link1: TJSHTMLAnchorElement;
    idedit: TJSHTMLInputElement;
    seleziona1: TJSHTMLElement;
    id1: TJSHTMLElement;
    procedure cancella1_click(event: TJSEvent);
  end;

implementation

{$R *.html}


{ TWMax1 }

procedure TWMax1.AfterRender;
begin
  seleziona1.addEventListener('click',TJSEventHandler(function(e:TJSEvent):Boolean
     begin ElementInstance.dispatchEvent(TJSEvent.new('seleziona')) end) );
end;

procedure TWMax1.cancella1_click(event: TJSEvent);
begin
  if ElementInstance.dispatchEvent(TJSEvent.new('elimina')) then
    idedit.Value := '';
end;

initialization
  RegisterWeb(TWMax1);

end.

