unit f_hotkey;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, f_sqleditor, un_hotkey, js,
  web;

type

  { TFrmHotkey }

  TFrmHotkey = class(TWidget)
    procedure AfterRender; override;
    procedure btnFetchClick; async;
  published
    taLog: TJSHTMLTextAreaElement;
  end;

implementation

{$R *.html}

{ TFrmHotkey }

procedure TFrmHotkey.AfterRender;
begin
  HotkeyWindow.Add('F2',@btnFetchClick);
  HotkeyWindow.EnableLog := True;
  taLog.value:= 'Il codice dei tasti e'' visualizzato nella console (premi F12 per vederla)'#13;
end;

procedure TFrmHotkey.btnFetchClick;
begin
  taLog.value += DateTimeToStr(now) + #13;
end;

initialization
  RegisterWeb(TFrmHotkey);
end.
