unit f_produzioneimportiprodotti;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, f_produzioneinsertimporti, f_produzioneprodotti,
  f_produzionemodificaimporti;

type

  { TFrmProduzioneImportiProdotti }

  TFrmProduzioneImportiProdotti = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idproduzione:Integer;
    dsprodotto:String;

  private
    procedure RefreshProduzioneImporti; async;

  published
    procedure tab1_rowclick;
    procedure BtnInserisciImporti_click; async;
    procedure BtnChiudi_click; async;
    procedure idvalido_click; async;
    QryProduzioneImporti: TQuery;
    QryProduzione: TQuery;
    tab1:TWTable;
    dsprodottomodello : TJSHTMLInputElement;
    nmquantita : TJSHTMLInputElement;
    nmprezzoacquisto : TJSHTMLInputElement;
    nmprezzovendita : TJSHTMLInputElement;
    idvalido:TJSHTMLInputElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneImportiProdotti }

procedure TFrmProduzioneImportiProdotti.AfterRender;
begin

  idvalido.checked:=true;

end;

procedure TFrmProduzioneImportiProdotti.AfterDomAppend;
begin

  dsprodottomodello.value:=dsprodotto;

  RefreshProduzioneImporti;

end;

procedure TFrmProduzioneImportiProdotti.RefreshProduzioneImporti;
begin

  QryProduzioneImporti.Close;
  QryProduzioneImporti.SQL.Text:= ' select flvalido as valido, idvalore, dtvendita, '
  + ' nmquantita as qta, nmprezzoacquisto as pr_acq, '
  + ' nmprezzovendita as pr_vend, nmtotalecosto as costo, nmtotalericavo as ricavo, nmtotaleutile as utile '
  + ' from pro_valori '
  + ' where idproduzione = ' + IntToStr(idproduzione);
  if idvalido.checked then
    begin
      QryProduzioneImporti.SQL.Text := QryProduzioneImporti.SQL.Text + ' and flvalido <> ''NO'' '
    end;
  QryProduzioneImporti.SQL.Text := QryProduzioneImporti.SQL.Text + ' order by dtvendita asc';
  AWait(QryProduzioneImporti.Open);
  QryProduzioneImporti.FieldByName('idvalore').Visible:= false;
  tab1.RenderDataSet(QryProduzioneImporti);

end;

procedure TFrmProduzioneImportiProdotti.tab1_rowclick;
var
  instance: TFrmProduzioneModificaImporti;
begin

  instance := TFrmProduzioneModificaImporti.Create();
  instance.idproduzione := idproduzione;
  instance.idvalore := QryProduzioneImporti.FieldByName('idvalore').AsInteger;
  instance.show;

end;

procedure TFrmProduzioneImportiProdotti.BtnInserisciImporti_click;
var
  instance: TFrmProduzioneInsertImporti;
begin

  console.log('prodotto = ' + IntToStr(idproduzione));

  instance := TFrmProduzioneInsertImporti.Create();
  instance.idproduzione := idproduzione;
  instance.show;

end;

procedure TFrmProduzioneImportiProdotti.BtnChiudi_click;
begin

  Close;

end;

procedure TFrmProduzioneImportiProdotti.idvalido_click;
begin

  RefreshProduzioneImporti;

end;

initialization
  RegisterWeb(TFrmProduzioneImportiProdotti);

end.

