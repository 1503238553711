unit f_produzionemodificaprocesso;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator, f_selezione;

type

  { TFrmProduzioneModificaProcesso }

  TFrmProduzioneModificaProcesso = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idprocesso:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private
    primaVolta :boolean;
  published
    procedure CompilaCampi; async;
    QryProcessi: TQuery;
    dsprocesso : TJSHTMLInputElement;
    dsnote : TJSHTMLInputElement;
    idordine : TJSHTMLInputElement;

  end;

implementation

{$R *.html}


{ TFrmProduzioneModificaProcesso }

procedure TFrmProduzioneModificaProcesso.AfterRender;
begin

  primaVolta := true;

end;

procedure TFrmProduzioneModificaProcesso.AfterDomAppend;
begin

  if (idprocesso > 0) and primaVolta then
  begin
    Await(CompilaCampi);
  end;

  primaVolta := false;

end;

procedure TFrmProduzioneModificaProcesso.CompilaCampi;
begin

// precompilo il dati per la modifica dello step
  QryProcessi.Close;
  QryProcessi.SQL.Text:= ' select * from pro_processi '
                        + ' where idprocesso = ' + IntTostr(idprocesso );
  AWait(QryProcessi.Open);

  idordine.value := QryProcessi.FieldByName('idordine').AsString;
  dsprocesso.value := QryProcessi.FieldByName('dsprocesso').AsString;
  dsnote.value := QryProcessi.FieldByName('dsnote').AsString;

end;

procedure TFrmProduzioneModificaProcesso.salva_click(Event: TJSEvent);
begin

//   controllo se i campi sono compilati
  if idordine.value = '' then
    begin
      ShowMessage('Devi inserire l''ordine del processo');
      Exit;
    end;
  if dsprocesso.value = '' then
    begin
      ShowMessage('Devi inserire la descrizione del processo');
      Exit;
    end;
  if dsnote.value = '' then
    begin
      ShowMessage('Devi inserire le note per il processo');
      Exit;
    end;

  // modifico il processo
  QryProcessi.edit;
  QryProcessi.FieldByName('IDORDINE').AsString := idordine.value;
  QryProcessi.FieldByName('DSPROCESSO').AsString := dsprocesso.value;
  QryProcessi.FieldByName('DSNOTE').AsString := dsnote.value;
  QryProcessi.Post;
  await(QryProcessi.ApplyUpdates);

  ShowMessage('Inserimento eseguito con successo');

  Close();

end;

procedure TFrmProduzioneModificaProcesso.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

initialization
  RegisterWeb(TFrmProduzioneModificaProcesso);

end.

