unit un_generator;

{$mode objfpc}

interface

uses
  Classes, SysUtils, web, un_servermodule;

function GetNextId(tabella: string): Integer; async;

implementation

function GetNextId(tabella: string): Integer; async;
begin

  with ServerModule('TWebGenerator') do
  begin
    Params['tabella'] := tabella;
    await(Call('WebProcessa'));

    console.log('ecco l''id generetato: ' + Response.Strings['id']);

    result := StrToInt(Response.Strings['id']);

  end;

end;

end.

