unit f_produzionevalori;

{$mode objfpc}

interface

uses
  Classes, SysUtils, strutils, js, web, un_widget, un_tquery, w_table,
  f_produzionedettaglio, DateUtils, f_selezione, w_edit, un_servermodule, w_label,
  un_telemetria, UnArrayDef, DB, un_generator, f_ProduzioneAnalisiDettaglio;

type

  TVisibilita = (visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso);
const
  limiteRecord = 300;
  msgLimiteRecord = 'Sono riportati solo gli ultimi 300 prodotti' ;
  msgDateErrate = 'Correggi le date corrette per visualizzare gli prodotti';
  msgNessunRecord = 'Nessun record da visualizzare';
  msgCaricamentoInCorso = 'Caricamento prodotti in corso...';

type
  { TFrmProduzioneValori }

  TFrmProduzioneValori = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    procedure btnchiudi_click(Event: TJSEvent); async;
  private
    whereDate :String;
    whereDate2 :String;
    dt1str, dt2str :String;
    function DateOk:Boolean;
    procedure ShowMeseAnno; async;
    procedure ShowProdotto; async;
    procedure ShowStato; async;
    procedure qryproduzioneOnFilterRecord(DataSet: TDataSet; var Accept: Boolean);
    procedure RefreshFiltro;
    procedure SetLabel(lbl: TWLabel; value: String);
    procedure ImpostaVisibilita(visibilita:TVisibilita);
    procedure SetLabelStampa;
  published
    var paramqry1 :String;
    procedure tab1_rowclick;
    procedure filtro_change;
    procedure filtro_input;
    procedure data1_change;
    procedure data2_change;
    procedure idstato_change;
    procedure stampa1_click(event: TJSEvent); async;
    procedure Totali;
    procedure btnmeseanno_click; async;
    procedure btnprodotto_click; async;
    procedure btnstato_click; async;
    procedure idstato_click; async;
    stampa1 :TJSHTMLButtonElement;
    data1 : TJSHTMLInputElement;
    data2 : TJSHTMLInputElement;
    idstato: TJSHTMLSelectElement;
    filtro: TWEdit;
    pnltab1: TJSHTMLElement;
    qryproduzione: TQuery;
    qryproduzionetotali: TQuery;
    tab1:TWTable;
    tab2:TWTable;
    tab3:TWTable;
    labelTop:TWLabel;
    labelBottom:TWLabel;
    pnlTabella:TJSHTMLElement;
    pnlstampa:TJSHTMLElement;
    idcosti:TJSHTMLInputElement;
    idricavi:TJSHTMLInputElement;
    idutile:TJSHTMLInputElement;
    BtnMeseAnno : TJSHTMLInputElement;
    BtnProdotto : TJSHTMLInputElement;
    BtnStato : TJSHTMLInputElement;

  end;

implementation

{$R *.html}

{ TFrmProduzioneValori }

procedure TFrmProduzioneValori.AfterRender;
begin

  pnlstampa.Visible:=false;

  qryproduzione.OnFilterRecord := @qryproduzioneOnFilterRecord;

  filtro.placeholder := 'Ricerca prodotti...';
  filtro.seleziona1.Visible:= false;

  labelTop.header.innerHTML:= '<br><br>';
  labelTop.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 18px; font-weight: bold; border-style: solid;';
  labelTop.footer.innerHTML:= '<br><br>';

  labelBottom.labelbody.innerHTML:= 'bottom';
  labelBottom.header.innerHTML:= '<br>';
  labelBottom.labelbody.style.cssText := 'background-color: yellow; text-align: center; border-color: red; font-size: 22px; font-weight: bold; border-style: solid;';

  data1.value := window.localStorage[ClassName+'_data1'] ;
  data2.value := window.localStorage[ClassName+'_data2'] ;

  ShowmeseAnno;

end;

procedure TFrmProduzioneValori.AfterDomAppend;
begin

//  ShowmeseAnno;

end;

procedure TFrmProduzioneValori.btnchiudi_click(Event: TJSEvent);
begin

  Close();

end;

procedure TFrmProduzioneValori.ShowMeseAnno;
begin

  if not DateOk then
  begin
    ImpostaVisibilita(visDateErrate);
    exit;
  end;

  ImpostaVisibilita(visCaricamentoInCorso);
// calcolo i totali per mese/anno

  QryProduzione.Close;
  QryProduzione.SQL.Text:= ' select extract(month from val.dtvendita)||''/''|| extract(year from val.dtvendita) as periodo, '
                        + ' sum(val.nmquantita) as qta, sum(val.nmtotalecosto) as costi, '
                        + ' sum(val.nmtotalericavo) as ricavi, sum(val.nmtotaleutile) as utile  '
                        + ' from pro_valori val '
                        + ' join pro_produzione pro on pro.idproduzione = val.idproduzione '
                        + ' where ' + whereDate2
                        + ' and pro.dsstato <> ''CONCLUSO'' '
                        + ' and val.flvalido = ''SI'' ';
  if idstato.value <> '' then
  begin
    QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and pro.dsstato = ' + '''' + idstato.value + ''''
  end;
  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Group by extract(month from val.dtvendita), extract(year from val.dtvendita) ';
  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Order by extract(year from val.dtvendita), extract(month from val.dtvendita) ';
  AWait(QryProduzione.Open);
  tab1.RenderDataSet(qryproduzione);

  //QryProduzione.Close;
  //QryProduzione.SQL.Text:= ' select extract(month from dtscadenza)||''/''|| extract(year from dtscadenza) as periodo, '
  //                      + ' sum(nmquantita) as qta, sum(nmtotalecosto) as costi, '
  //                      + ' sum(nmtotalericavo) as ricavi, sum(nmutile) as utile '
  //                      + ' from pro_produzione '
  //                      + ' where ' + whereDate2;
  //if idstato.value <> '' then
  //begin
  //  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and dsstato = ' + '''' + idstato.value + ''''
  //end;
  //if flconclusi.checked then
  //  begin
  //    QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and dsstato <> ''CONCLUSO'' '
  //  end;
  //
  //QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Group by extract(month from dtscadenza), extract(year from dtscadenza) ';
  //QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Order by extract(year from dtscadenza), extract(month from dtscadenza) ';
  //AWait(QryProduzione.Open);
  //tab1.RenderDataSet(qryproduzione);

// calcolo i totali generali
  totali;

  RefreshFiltro;

  paramqry1:='meseanno';

end;

procedure TFrmProduzioneValori.ShowProdotto;
begin

  if not DateOk then
  begin
    ImpostaVisibilita(visDateErrate);
    exit;
  end;

  ImpostaVisibilita(visCaricamentoInCorso);


// calcolo i totali per prodotto
  QryProduzione.Close;
  QryProduzione.SQL.Text:= ' select extract(month from val.dtvendita)||''/''|| extract(year from val.dtvendita) as periodo, '
                        + ' pro.dsprodotto ||''-''|| pro.dsmodello as prodotto, '
                        + ' sum(val.nmquantita) as qta, sum(val.nmtotalecosto) as costi, '
                        + ' sum(val.nmtotalericavo) as ricavi, sum(val.nmtotaleutile) as utile '
                        + ' from pro_valori val '
                        + ' join pro_produzione pro on pro.idproduzione = val.idproduzione '
                        + ' where ' + whereDate2
                        + ' and pro.dsstato <> ''CONCLUSO'' '
                        + ' and val.flvalido = ''SI'' ';
  if idstato.value <> '' then
  begin
    QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and pro.dsstato = ' + '''' + idstato.value + ''''
  end;
  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Group by extract(month from val.dtvendita), extract(year from val.dtvendita), pro.dsprodotto, pro.dsmodello ';
  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Order by extract(year from val.dtvendita), extract(month from val.dtvendita), pro.dsprodotto, pro.dsmodello ';
  AWait(QryProduzione.Open);
  tab1.RenderDataSet(qryproduzione);

  console.log('qry = ' + QryProduzione.SQL.Text);

// calcolo i totali per prodotto
//  QryProduzione.Close;
//  QryProduzione.SQL.Text:= ' select extract(month from dtscadenza)||''/''|| extract(year from dtscadenza) as periodo, '
//                        + ' dsprodotto ||''-''|| dsmodello as prodotto, '
//                        + ' sum(nmquantita) as qta, sum(nmtotalecosto) as costi, '
//                        + ' sum(nmtotalericavo) as ricavi, sum(nmutile) as utile '
//                        + ' from pro_produzione '
//                        + ' where ' + whereDate2;
//  if idstato.value <> '' then
//  begin
//    QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and dsstato = ' + '''' + idstato.value + ''''
//  end;
//  if flconclusi.checked then
//    begin
//      QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and dsstato <> ''CONCLUSO'' '
//    end;
//
//  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Group by extract(month from dtscadenza), extract(year from dtscadenza), dsprodotto, dsmodello ';
//  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Order by extract(year from dtscadenza), extract(month from dtscadenza), dsprodotto, dsmodello ';
//  AWait(QryProduzione.Open);
//  tab1.RenderDataSet(qryproduzione);

// calcolo i totali generali
  totali;

  RefreshFiltro;

  paramqry1:='prodotto';

end;

procedure TFrmProduzioneValori.ShowStato;
begin

  if not DateOk then
  begin
    ImpostaVisibilita(visDateErrate);
    exit;
  end;

  ImpostaVisibilita(visCaricamentoInCorso);
// calcolo i totali per stato
  QryProduzione.Close;
  QryProduzione.SQL.Text:= ' select extract(month from val.dtvendita)||''/''|| extract(year from val.dtvendita) as periodo, pro.dsstato, '
                        + ' sum(val.nmquantita) as qta, sum(val.nmtotalecosto) as costi, '
                        + ' sum(val.nmtotalericavo) as ricavi, sum(val.nmtotaleutile) as utile '
                        + ' from pro_valori val '
                        + ' join pro_produzione pro on pro.idproduzione = val.idproduzione '
                        + ' where ' + whereDate2
                        + ' and pro.dsstato <> ''CONCLUSO'' '
                        + ' and val.flvalido = ''SI'' ';
  if idstato.value <> '' then
  begin
    QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and pro.dsstato = ' + '''' + idstato.value + ''''
  end;
  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Group by extract(month from val.dtvendita), extract(year from val.dtvendita), pro.dsstato ';
  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Order by extract(year from val.dtvendita), extract(month from val.dtvendita), pro.dsstato ';
  AWait(QryProduzione.Open);
  tab1.RenderDataSet(qryproduzione);

  //QryProduzione.Close;
  //QryProduzione.SQL.Text:= ' select extract(month from dtscadenza)||''/''|| extract(year from dtscadenza) as periodo, '
  //                      + ' dsstato, '
  //                      + ' sum(nmquantita) as qta, sum(nmtotalecosto) as costi, '
  //                      + ' sum(nmtotalericavo) as ricavi, sum(nmutile) as utile '
  //                      + ' from pro_produzione '
  //                      + ' where ' + whereDate2;
  //if idstato.value <> '' then
  //begin
  //  QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and dsstato = ' + '''' + idstato.value + ''''
  //end;
  //if flconclusi.checked then
  //  begin
  //    QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' and dsstato <> ''CONCLUSO'' '
  //  end;
  //
  //QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Group by extract(month from dtscadenza), extract(year from dtscadenza), dsstato ';
  //QryProduzione.SQL.Text := QryProduzione.SQL.Text + ' Order by extract(year from dtscadenza), extract(month from dtscadenza), dsstato ';
  //
  //AWait(QryProduzione.Open);
  //tab1.RenderDataSet(qryproduzione);

// calcolo i totali generali
  totali;

  RefreshFiltro;

  paramqry1 := 'stato';

end;

procedure TFrmProduzioneValori.Totali;
var
  totricavi, totcosti, totutile :Double;
begin

// calcolo i totali generali
  totcosti:= 0;
  totricavi:= 0;
  totutile:= 0;

  QryProduzione.First;
  while not QryProduzione.Eof do
    begin
      totcosti := totcosti + QryProduzione.FieldByName('costi').AsFloat;
      totricavi := totricavi + QryProduzione.FieldByName('ricavi').AsFloat;
      totutile := totutile + QryProduzione.FieldByName('utile').AsFloat;
      qryproduzione.Next;
    end;

  idcosti.value:=FloatToStr(totcosti);
  idricavi.value:=FloatToStr(totricavi);
  idutile.value:=FloatToStr(totutile);

end;

procedure TFrmProduzioneValori.btnmeseanno_click;
begin

  ShowmeseAnno;

end;

procedure TFrmProduzioneValori.btnprodotto_click;
begin

  ShowProdotto;

end;

procedure TFrmProduzioneValori.btnstato_click;
begin

  ShowStato;

end;

procedure TFrmProduzioneValori.idstato_click;
begin
  
  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= 'select dsstato as stato '
      + ' from pro_stati '
      + ' order by idordine ';
      AWait(QrySeleziona.Open);
    end;

    if AWait(Show) = mrOK then
    begin
      idstato.value := QrySeleziona.FieldByName('stato').AsString;
      if paramqry1 = 'meseanno' then ShowMeseAnno;
      if paramqry1 = 'prodotto' then ShowProdotto;
      if paramqry1 = 'stato' then ShowStato;
    end;

  end;

end;

procedure TFrmProduzioneValori.qryproduzioneOnFilterRecord(DataSet: TDataSet;
  var Accept: Boolean);
var
  str:String;
  i: Integer;
begin
  Accept:= true;
  str := filtro.value.ToLowerInvariant;
  for i:= 0 to DataSet.FieldCount-1 do
  begin
    if DataSet.Fields[i].Visible and
       DataSet.Fields[i].DisplayText.ToLowerInvariant.Contains(str) then
         exit;
  end;
  //nessun campo matcha la stringa cercata
  Accept := false;
end;

procedure TFrmProduzioneValori.SetLabel(lbl:TWLabel;value:String);
begin

  lbl.Visible :=   value <> '' ;
  lbl.labelbody.innerHTML := value;

end;

procedure TFrmProduzioneValori.SetLabelStampa;
var
  RecordCount: Integer;
begin

  if not qryproduzione.Active then
    exit;

  RecordCount := 0;
  qryproduzione.First;
  while not qryproduzione.EOF do
  begin
    Inc(RecordCount);
    qryproduzione.Next;
  end;
  stampa1.innerHTML:= format('Stampa (%d %s)'
      ,[RecordCount, IfThen(RecordCount = 1,'ordine','ordini') ]);

end;

procedure TFrmProduzioneValori.tab1_rowclick;
var
  instance: TFrmProduzioneAnalisiDettaglio;
begin

  instance := TFrmProduzioneAnalisiDettaglio.Create();
  console.log('leggo i parametri');

  if paramqry1='meseanno' then
    begin
      instance.parametro2 := 'meseanno';
      instance.parametro1 := qryproduzione.FieldByName('periodo').AsString;
      instance.idstato := idstato.value;
      instance.show;
    end;

  if paramqry1='stato' then
    begin
      instance.parametro2 := 'stato';
      instance.parametro1 := qryproduzione.FieldByName('dsstato').AsString;
      instance.parametro3 := qryproduzione.FieldByName('periodo').AsString;
      instance.idstato := idstato.value;
      instance.show;
    end;

  if paramqry1='prodotto' then
    begin

    end;

end;

procedure TFrmProduzioneValori.ImpostaVisibilita(visibilita: TVisibilita);
begin

  //visDateErrate,visNessunRecord,visLimiteRecord,visCiSonoRecord,visCaricamentoInCorso
  SetLabelStampa;
  SetLabel(labelTop,'');
  SetLabel(labelBottom,'');

  if visibilita = visDateErrate then SetLabel(labelTop,msgDateErrate);
  if visibilita = visNessunRecord then SetLabel(labelBottom,msgNessunRecord);
  if visibilita = visLimiteRecord then SetLabel(labelBottom,msgLimiteRecord);
  if visibilita = visCaricamentoInCorso then SetLabel(labelBottom,msgCaricamentoInCorso);

  filtro.Visible :=  visibilita <> visDateErrate;
//  console.log(format(' filtro.Visible := %s',[BoolToStr(filtro.Visible,true)]));
  pnltab1.Visible:= visibilita in [visLimiteRecord,visCiSonoRecord];

end;

procedure TFrmProduzioneValori.RefreshFiltro;
begin

  qryproduzione.Filtered:= filtro.value.Trim <> '';
  qryproduzione.First;
  tab1.RenderDataSet(qryproduzione);

  totali;

  if qryproduzione.IsEmpty then
    ImpostaVisibilita(visNessunRecord)
  else if qryproduzione.RecordCount = limiteRecord  then
    ImpostaVisibilita(visLimiteRecord)
  else
    ImpostaVisibilita(visCiSonoRecord);

end;

procedure TFrmProduzioneValori.filtro_change;
begin

  RefreshFiltro;

end;

procedure TFrmProduzioneValori.filtro_input;
begin

  RefreshFiltro;

end;

procedure TFrmProduzioneValori.data1_change;
begin

  window.localStorage[ClassName+'_data1'] := data1.value;

  ShowMeseAnno;
  ShowProdotto;
  ShowStato;

end;

procedure TFrmProduzioneValori.data2_change;
begin

  window.localStorage[ClassName+'_data2'] := data2.value;

  ShowMeseAnno;
  ShowProdotto;
  ShowStato;

end;

procedure TFrmProduzioneValori.idstato_change;
begin

  console.log('TFrmProduzione.idedit_change');

  ShowMeseAnno;
  ShowProdotto;
  ShowStato;

end;

procedure TFrmProduzioneValori.stampa1_click(event: TJSEvent);
var
  sql, clausola: String;
begin

  with ServerModule('TWebStampe') do
  begin
    Params['idStampa'] := '81';
    AWait(Call('WebParametri'));
    sql := Response.Strings['SQL'];
  end;

  clausola := format( 'AND O.ID_ORDINE IN (%s)',[ ToCommaText(qryproduzione,'ID_ORDINE',true) ]);
  sql := SysUtils.StringReplace(sql,'--AND1',clausola,[]);

  with ServerModule('TWebStampe') do
  begin
    Proc := 'WebProcessa';
    Params['idStampa'] := '81';
    Params['sql'] := sql;
    Params['DATADA'] := dt1str;
    Params['DATAA'] :=  dt2str;
    window.open(Url,'_blank');
  end;
  TmRegistraEvento('stampa_ordini',format('%s ~ %s|stato=%s|filtro=%s'
  ,[data1.value,data2.value,idstato.value,filtro.Value]));
end;

function TFrmProduzioneValori.DateOk: Boolean;
var
  dt1, dt2: TDateTime;
begin

  Result :=  false;

  if (data1.value = '') or (data2.value = '')
    or (data1.value > data2.value) then
  begin
    SetLabel(labelTop,msgDateErrate);
    SetLabel(labelBottom,'');
    exit;
  end;

  Result :=  true;

  dt1 := ScanDateTime('yyyy-MM-dd',data1.value);
  dt2 := ScanDateTime('yyyy-MM-dd',data2.value) ;
  dt2 := IncDay(dt2);

// questo formato date serve per la query
  dt1str := FormatDateTime('dd.MM.yyyy',dt1);
  dt2str := FormatDateTime('dd.MM.yyyy',dt2);

  whereDate := ' dtnextscadenza >= ''' + dt1str + ''''
    + ' and dtnextscadenza <= ''' + dt2str + '''';

  whereDate2 := ' val.dtvendita >= ''' + dt1str + ''''
  + ' and  val.dtvendita <= ''' + dt2str + '''';

// questo formato di date serve alla stampa
  dt1str := FormatDateTime('dd/MM/yyyy',dt1) + ' 00:00:00';
  dt2str := FormatDateTime('dd/MM/yyyy',dt2) + ' 00:00:00';

end;

initialization
  RegisterWeb(TFrmProduzioneValori);

end.

