unit un_start;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, f_statistiche, un_commandline, un_manager,
  f_sqleditor, un_hotkey, f_header, f_login, f_menu, un_formatsettings, f_menu2,
  web, js;

procedure start; async;

implementation

procedure EscapeKeyPress;
begin
  manager.closeCurrent;
end;

procedure HandleBack;
var
  push : TRefProc;
begin
  window.onpopstate:= function(aEvent : TJSPopStateEvent) : Boolean
        begin
          //window.onpopstate = { currentHolder().closeCurrent() }
          manager.closeCurrent;
        end;
  push := procedure begin
      window.history.pushState('','','');
  end;

  manager.NotifyNewPushState:= push;
  //modalManager.NotifyNewPushState:= push;
        //val push = { window.history.pushState("", "", "") }
        //rootWidget.notifyNewPushState = push
        //rootModalWidget.notifyNewPushState = push
end;

procedure SetupHeader;
var
  header: TJSHTMLElement;
begin
  header := document.getElementById('header') as TJSHTMLElement;
  header.innerHTML:='';
  header.appendChild(FrmHeader.elementInstance);
end;

procedure start;
var
  login: TFrmLogin;
begin
  SetupFormatSettings;
  await(LoadAllResources);
  manager := TWidgetManager.Create;
  DefaultManager := manager;
  login := TFrmLogin.Create;

  if CommandLineSwitches.InvokeClass <> ''  then
  begin
    login.OnLoginSuccess:= procedure
      var
        meta: TWidgetMeta;
        instance: TWidget;
      begin
        meta := WidgetMetaMap.get(CommandLineSwitches.InvokeClass);
        instance := TWidget(TWidgetClass(meta.FClass).Create());
        instance.Show;
    end;
  end else
  begin
    login.OnLoginSuccess:= procedure begin
      TFrmMenu2.Create.Show;
    end;
  end;
  SetupHeader;
  document.body.appendChild(manager.ElementInstance);

  HotkeyWindow.Add('Escape',@EscapeKeyPress);
  HandleBack;

  login.Show;
end;

end.
