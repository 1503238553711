unit w_qrcode;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, js, web;

type
    type TCallbackProc = reference to procedure(code:String) ;

  { TWQRCode }

  TWQRCode = class(TWidget)
  private
    FIsScanning :Boolean;
    FEnableDeferred: Boolean;
    procedure StartScanningInternal(inst: JSValue); assembler;
    procedure OnDataCallback(code:String);
  public
    procedure AfterRender; override;
    procedure StartScanning;
    procedure AfterDomAppend; async; override;
    procedure StopScanning;
    procedure HideImage;
    property IsScanning:Boolean read FIsScanning;
  published
    loadingMessage: TJSHTMLElement;
    errorMessage: TJSHTMLElement;
    canvas: TJSHTMLElement;
    video: TJSHTMLElement;
    OnQRCodeAvailable: TRefProc;
    Data:String;
  end;

var
  jsQRLoaded:boolean;

implementation

{$R *.html}


{ TWQRCode }

procedure TWQRCode.AfterDomAppend;
begin
  assert(Assigned( @OnDataCallback ));  //otherwise compiler optimization will remove the function
  assert(Assigned( @FIsScanning ));
end;

procedure TWQRCode.StartScanning;
begin
  FIsScanning := true;
  canvas.hidden := True; // it will be set by asm
  canvas.Visible:=True;
  if jsQRLoaded then
    StartScanningInternal(self)
  else
    FEnableDeferred := true;
end;

procedure TWQRCode.StopScanning;
begin
  FIsScanning:= false;
end;

procedure TWQRCode.HideImage;
begin
  canvas.hidden := True;
  canvas.Visible:= False;
end;


procedure TWQRCode.OnDataCallback(code: String);
begin
  Data := code;
  if Assigned(OnQRCodeAvailable) then
    OnQRCodeAvailable();
end;

procedure TWQRCode.AfterRender;
var
  s: TJSHTMLScriptElement;
  elId: String;
begin
  elId := 'jsQRid';
  if assigned( document.getElementById(elId) ) then
    exit; //already appended tag

  s := document.createElement('script') as TJSHTMLScriptElement;
  s.id:= elId;
  s.onload:= function (Event:TEventListenerEvent):Boolean
  begin
    jsQRLoaded := true;
    if FEnableDeferred then
      StartScanningInternal(self);
  end;
  document.head.appendChild(s);
  s.src:= 'jsQR.js';
end;


procedure TWQRCode.StartScanningInternal(inst: JSValue); assembler;
asm
var canvasElement = inst.canvas;
var video = inst.video;
var canvas = canvasElement.getContext("2d");
var loadingMessage = inst.loadingMessage;

function drawLine(begin2, end2, color) {
  canvas.beginPath();
  canvas.moveTo(begin2.x, begin2.y);
  canvas.lineTo(end2.x, end2.y);
  canvas.lineWidth = 4;
  canvas.strokeStyle = color;
  canvas.stroke();
}

// Use facingMode: environment to attemt to get the front camera on phones
navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
  video.srcObject = stream;
  video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
  video.play();
  requestAnimationFrame(tick);
}).catch(function (reason) {
    inst.errorMessage.hidden = false;
    inst.errorMessage.innerHTML = 'Impossibile accedere alla telecamera (verifica di averla attivata) ['+ reason +']';
});

function tick() {
  loadingMessage.innerText = "Caricamento video..."
  loadingMessage.hidden = false;
  if (video.readyState === video.HAVE_ENOUGH_DATA) {
    loadingMessage.hidden = true;
    canvasElement.hidden = false;

    canvasElement.height = video.videoHeight;
    canvasElement.width = video.videoWidth;
    //canvasElement.width = "auto";
    canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
    var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    var code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: "dontInvert",
    });
    if (code) {
      drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
      drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
      drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
      drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");

      inst.OnDataCallback(code.data);
    } else {
    }
  }

  if(inst.FIsScanning)
    requestAnimationFrame(tick);
  else{
    console.log('FIsScanning is false');
    if(video.srcObject)
     video.srcObject.getTracks().forEach(function(track) {
       track.stop();
    });
  }
}

end;

initialization
  RegisterWeb(TWQRCode);
end.
