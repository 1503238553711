unit UnArrayDef;

interface

uses
  SysUtils,Classes,StrUtils,DB;

type
  TStringArray = array of string;
  TIntegerArray = array of integer;

procedure ParseDelimited(const sl : TStrings; const value : string; const delimiter : string) ;
procedure ParseDelimitedTest;
function ToCommaText(cds:TDataset;fieldName:string;dsFirst:boolean=false):string;
function ToDelimitedText(cds:TDataset;fieldName:string;delimiter:char):string;
function IntegerArrayToCommaText(list:array of integer):string;
function StringReplace(src: string;
  oldNewPatternCouples: array of string;Flags: TReplaceFlags=[rfReplaceAll]): string;
function ToStringArray(src:string;delimiter:char): TStringArray;

implementation

procedure ParseDelimitedTest;
var
  s:TStringList;
begin
  s := TStringList.Create;
  try
     ParseDelimited(s,'uno',',');
     assert(s.count=1, format('count=%d',[s.count]));
     assert(s[0] = 'uno','s[0]=' + s[0]) ;

     ParseDelimited(s,'uno,due',',');
     assert(s.count=2);

     ParseDelimited(s,'uno//due','//');
     assert(s.count=2);

     ParseDelimited(s,'uno//due//','//');
     assert(s.count=3, format('count=%d',[s.count]));

     ParseDelimited(s,'uno con spazi// due = chiave valore','//');
     assert(s.count=2, format('count=%d',[s.count]));
     assert(s[0] = 'uno con spazi','s[0]=' + s[0]) ;
     assert(s.Values[' due '] = ' chiave valore',' due =' + s.Values[' due ']) ;

     ParseDelimited(s,'uno///due','//');
     assert(s.count=2);
     assert(s[0] = 'uno','s[0]=' + s[0]) ;
     assert(s[1] = '/due','s[1]=' + s[1]) ;

     ParseDelimited(s,'/////','//');
     assert(s.count=3);
     assert(s[0] = '','s[0]=' + s[0]) ;
     assert(s[1] = '','s[1]=' + s[1]) ;
     assert(s[2] = '/','s[2]=' + s[2]) ;

  finally
    s.Free;
  end;

end;

procedure ParseDelimited(const sl : TStrings; const value : string; const delimiter : string) ;
var
  sx : integer;
  delLen:integer;
  delPos:integer;
begin
  delLen := Length(delimiter) ;
  sl.BeginUpdate;
  sl.Clear;
  sx := 1;
  try
    while true do
    begin
      delPos := PosEx(delimiter,value,sx);
      if delPos >= 1 then
      begin
        sl.Add(Copy(value,sx,delPos-sx) );
        sx := delPos + delLen;
      end else
      begin
        sl.Add(Copy(value,sx,Length(value)-sx + 1));
        break;
      end;
    end;
  finally
    sl.EndUpdate;
  end;
end;

procedure ParseDelimited2(const sl : TStrings; const value : string; const delimiter : string) ;
var
   dx : integer;
   ns : string;
   txt : string;
   delta : integer;
begin
   delta := Length(delimiter) ;
   txt := value + delimiter;
   sl.BeginUpdate;
   sl.Clear;
   try
     while Length(txt) > 0 do
     begin
       dx := Pos(delimiter, txt) ;
       ns := Copy(txt,0,dx-1) ;
       sl.Add(ns) ;
       txt := Copy(txt,dx+delta,MaxInt) ;
     end;
   finally
     sl.EndUpdate;
   end;
end;

function ToCommaText(cds:TDataset;fieldName:string;dsFirst:boolean=false):string;
var
  res:TStringList;
begin
  if dsFirst then cds.First;
  res := TStringList.Create;
  try
    while not cds.Eof do
    begin
      res.Add(cds.FieldByName(fieldName).AsString);
      cds.Next;
    end;

    Result := res.CommaText;
  finally
    FreeAndNil(res);
  end;
end;
function ToDelimitedText(cds:TDataset;fieldName:string;delimiter:char):string;
var
  res:TStringList;
begin
  res := TStringList.Create;

  while not cds.Eof do
  begin
    res.Add(cds.FieldByName(fieldName).AsString);
    cds.Next;
  end;
  res.Delimiter := delimiter;
  Result := res.DelimitedText;
  FreeAndNil(res);

end;
function IntegerArrayToCommaText(list:array of integer):string;
var
  l:TStringList;
begin
  l:= TStringList.Create;
  try
    while l.Count<=High(list) do
      l.Add( IntToStr( list[l.Count] ) );
    Result := l.CommaText;
  finally
    FreeAndNil(l);
  end;

end;
function StringReplace(src: string;
  oldNewPatternCouples: array of string;Flags: TReplaceFlags): string;
var
  index: integer;
  count:integer;
  old,new:string;
begin
  count := (High(oldNewPatternCouples)+1) div 2 ;
  if (Count*2)<> (High(oldNewPatternCouples)+1) then
     raise Exception.Create('StringReplace needs even elements in searchReplace');

  Result := src;

  for index := 0 to count-1 do
  begin
    old := oldNewPatternCouples[index*2];
    new :=    oldNewPatternCouples[index*2+1];
    Result := SysUtils.StringReplace(Result,old,new,Flags);
  end;

end;

function ToStringArray(src:string;delimiter:char): TStringArray;
var
  sl:TStringList;
  idx:integer;
begin
  sl := TStringList.Create;
  sl.Delimiter := delimiter;
  sl.DelimitedText := src;
  SetLength(Result, sl.Count);
  for idx := 0 to sl.Count -1 do
    Result[idx] := sl[idx];
  FreeAndNil(sl);
end;
end.
