unit f_previsione;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, w_table, web, js, un_tquery, un_formatsettings,
  un_user, f_selezione, un_servermodule, un_generator, DateUtils, DB, f_previsionedettaglio;

type

  { TFrmPrevisione }

  TFrmPrevisione = class(TWidget)
    procedure AfterRender;

  published
    procedure idmese_click; async;
    procedure RefresPrevisione; async;
    procedure Totale; async;
    procedure tab1_rowclick;
    dsnote : TJSHTMLInputElement;
    idmese : TJSHTMLInputElement;
    idpreventivo : TJSHTMLInputElement;
    idconsuntivo : TJSHTMLInputElement      ;
    qryprevisione: TQuery;
    qrytotale: TQuery;
    tab1:TWTable;
  end;

implementation

{$R *.html}

{ TFrmPrevisione }

procedure TFrmPrevisione.AfterRender;
begin

//  RefresPrevisione;

end;

procedure TFrmPrevisione.idmese_click;
begin

  with TFrmSelezione.Create do
  begin
    OpenQrySeleziona := procedure async
    begin
      CloseForm := True;

      QrySeleziona.Close;
      QrySeleziona.SQL.Text:= ' select distinct dsmese from pre_previsione '
       + ' order by dsmese ';
      AWait(QrySeleziona.Open);

    end;

    if AWait(Show) = mrOK then
    begin
      idmese.value := QrySeleziona.FieldByName('dsmese').AsString;
    end;
  end;

  AWait(RefresPrevisione);
  AWait(Totale);

end;

procedure TFrmPrevisione.RefresPrevisione;
begin

  QryPrevisione.Close;
  QryPrevisione.SQL.Text:=  'select idprevisione, dscategoria as categoria, '
                      + ' pre_importo as preven, con_importo as consun '
                      + ' from pre_previsione '
                      + ' where idsocieta = ''MEL001'' '
                      + ' and dsmese = ' + '''' +idmese.value + '''';
  AWait(QryPrevisione.Open);
  QryPrevisione.FieldByName('idprevisione').Visible:= false;
  tab1.RenderDataSet(QryPrevisione);

end;

procedure TFrmPrevisione.Totale;
begin

  qrytotale.Close;
  qrytotale.SQL.Text:=  'select sum(pre_importo) as preventivo, sum(con_importo) as consuntivo '
                      + ' from pre_previsione '
                      + ' where idsocieta = ''MEL001'' '
                      + ' and dsmese = ' + '''' +idmese.value + '''';
  AWait(qrytotale.Open);

 idpreventivo.value:=FormatFloat('#,##0,00', qrytotale.FieldByName('preventivo').AsFloat);
 idconsuntivo.value:=FormatFloat('#,##0,00', qrytotale.FieldByName('consuntivo').AsFloat);

end;

procedure TFrmPrevisione.tab1_rowclick;
var
  instance: TFrmPrevisioneDettaglio;
begin

  instance := TFrmPrevisioneDettaglio.Create();
  instance.idprevisione := QryPrevisione.FieldByName('idprevisione').AsInteger ;
  instance.show;

end;

initialization
  RegisterWeb(TFrmPrevisione);

end.
