unit com_url;

{$mode objfpc}{$H+}

interface

uses
  Classes, SysUtils;

type TComUrl = record
  Base:String;
  Services:String;
end;

var ComUrl:TComUrl;

implementation
initialization
  With ComUrl do
  begin
    Base := '';  { Base := 'laz/2/html/'; }
    Services := 'services/';
  end;
end.

