unit f_produzionemodificacategoria;

{$mode objfpc}

interface

uses
  Classes, SysUtils, js, web, un_widget, un_tquery, w_table, un_generator,
  f_selezione, un_executesql;

type

  { TFrmProduzioneModificaCategoria }

  TFrmProduzioneModificaCategoria = class(TWidget)
    procedure AfterRender; override;
    procedure AfterDomAppend; async; override;
    idcategoria:integer;
    procedure salva_click(Event: TJSEvent); async;
    procedure elimina_click(Event: TJSEvent); async;
    procedure chiudi_click(Event: TJSEvent); async;

  private
    primaVolta :boolean;
  published
    procedure CompilaCampi; async;
    QryProduzioneModificaCategoria: TQuery;
    QryControllaElimina: TQuery;
    dscategoria : TJSHTMLInputElement;
  end;

implementation

{$R *.html}


{ TFrmProduzioneModificaCategoria }

procedure TFrmProduzioneModificaCategoria.AfterRender;
begin

  primaVolta := true;

end;

procedure TFrmProduzioneModificaCategoria.AfterDomAppend;
begin

  if (idcategoria > 0) and primaVolta then
  begin
    Await(CompilaCampi);
  end;

  primaVolta := false;

end;

procedure TFrmProduzioneModificaCategoria.CompilaCampi;
begin

// precompilo il dati per la modifica dello step
  QryProduzioneModificaCategoria.Close;
  QryProduzioneModificaCategoria.SQL.Text:= ' select * from pre_categorie '
                        + ' where idcategoria = ' + IntTostr(idcategoria);
  AWait(QryProduzioneModificaCategoria.Open);

  dscategoria.value := QryProduzioneModificaCategoria.FieldByName('dscategoria').AsString;

end;

procedure TFrmProduzioneModificaCategoria.salva_click(Event: TJSEvent);
begin

  if dscategoria.value = '' then
    begin
      ShowMessage('Devi inserire la categoria');
      Exit;
    end;

  // modifico il processo
  QryProduzioneModificaCategoria.edit;
  QryProduzioneModificaCategoria.FieldByName('dscategoria').AsString := dscategoria.value;
  QryProduzioneModificaCategoria.Post;
  await(QryProduzioneModificaCategoria.ApplyUpdates);

  ShowMessage('Inserimento eseguito con successo');

  Close();

end;

procedure TFrmProduzioneModificaCategoria.elimina_click(Event: TJSEvent);
begin

// verifico se il record da cancellare è usato
  QryControllaElimina.Close;
  QryControllaElimina.SQL.Text:= ' select * from pro_produzione '
                        + ' where idprodotto = ' + IntTostr(idcategoria);
  AWait(QryControllaElimina.Open);

  if QryControllaElimina.IsEmpty then
    begin
      QryProduzioneModificaCategoria.Close;
      QryProduzioneModificaCategoria.SQL.Text:= ' delete from pre_categorie '
                        + ' where idcategoria = ' + IntTostr(idcategoria);
      AWait(QryProduzioneModificaCategoria.ExecuteSql);
      Close();
    end
  else
    begin
      ShowMessage('Non puoi eliminare una categoria usata');
      Close();
      Exit;
    end;

end;

procedure TFrmProduzioneModificaCategoria.chiudi_click(Event: TJSEvent);
begin

  Close();

end;

initialization
  RegisterWeb(TFrmProduzioneModificaCategoria);

end.

