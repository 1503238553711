unit f_login;

{$mode objfpc}

interface

uses
  Classes, SysUtils, un_widget, un_tquery, w_table, un_hotkey, un_user,
  un_telemetria, js, web;

type

  { TFrmLogin }

  TFrmLogin = class(TWidget)
  private
    procedure SetInteractive(interactive: Boolean);
    procedure RegistraEventoLogin(param:String);
  published
    procedure DoOnLoginSuccess;
    procedure AfterDomAppend; async; override;
    procedure AfterRender; override;
    procedure btn_click(Event: TJSEvent); async;
    username:TJSHTMLInputElement;
    password:TJSHTMLInputElement;
    authenticating:TJSHTMLInputElement;
    root:TJSHTMLInputElement;
    OnLoginSuccess:TRefProc;
  end;

implementation

const
  ls_user = 'TFrmLogin.username';
  ls_pass = 'TFrmLogin.password';

{$R *.html}

{ TFrmLogin }

procedure TFrmLogin.AfterRender;
begin
  THotkey.Create(username).Add('Enter', procedure begin password.focus; end);
  THotkey.Create(password).Add('Enter', procedure begin btn_click(nil); end);
  SetInteractive(false); //startup default
end;

procedure TFrmLogin.AfterDomAppend;
begin
  if user.AuthOk then
  begin
    //an user was already authenticated and we are back at login
    user.QryUtenti.Close;
    SetInteractive(True);
    exit;
  end;

  if isDefined( window.localStorage[ls_user] ) and isDefined(window.localStorage[ls_pass]) then
  begin
    user.username := window.localStorage[ls_user];
    user.password := window.localStorage[ls_pass];
    if await(user.CredentialsOk) then
    begin
      RegistraEventoLogin('auto');
      DoOnLoginSuccess; //credenziali salvate ok
    end
    else
    begin
      //qualcosa e' andato storto
      SetInteractive(true);
      ShowMessage('Credenziali salvata non valide');
    end;
  end else
    SetInteractive(true);

end;

procedure TFrmLogin.SetInteractive(interactive: Boolean);
begin
  root.Visible:= interactive;
  authenticating.Visible:= not interactive;
end;

procedure TFrmLogin.RegistraEventoLogin(param: String);
var
  p: String;
begin
  p := format('%s|screen=%d,%d|platform=%s|userAgent=%s'
   ,[param
    ,window.screen.width
    ,window.screen.height
    ,window.navigator.platform
    ,window.navigator.userAgent
     ]);
  TmRegistraEvento('f_login',p);
end;

procedure TFrmLogin.DoOnLoginSuccess;
begin
  if Assigned(OnLoginSuccess) then
    OnLoginSuccess();
end;

procedure TFrmLogin.btn_click(Event: TJSEvent);
begin
  if username.value = '' then begin
    ShowMessage('Devi inserire lo username');
    username.focus;
    exit;
  end;
  if password.value = '' then begin
    ShowMessage('Devi inserire la password');
    password.focus;
    exit;
  end;

  user.username := username.value;
  user.password := password.value;
  if not await(user.CredentialsOk) then
  begin
    //qualcosa e' andato storto
    ShowMessage('Username o password errati');
    exit;
  end;

  window.localStorage[ls_user] := user.username;
  window.localStorage[ls_pass] := user.password;

  RegistraEventoLogin('interactive');

  //qui l'autenticazione e' ok
  DoOnLoginSuccess;

end;

initialization
  RegisterWeb(TFrmLogin);
end.
